import classNames from 'classnames';
import React from 'react';

import ChevronUpIcon from '../../../../../icons/ChevronUpIcon';
import Checkbox from '../../../../shared/forms/Checkbox/Checkbox';
import useGetMapParams from '../../../../shared/hooks/useGetMapParams';
import Modal from '../../../../shared/layout/Modal/Modal';
import useGetFilters from '../../selectors/useGetFilters';
import './FilterDrawer.scss';

const FilterDrawer = () => {
	const { eventId, roundId, holeNumber } = useGetMapParams();

	const {
		data: { isModalOpen, filterData, filterCount },
		handlers: { setFilterData, toggleModal, cancelModal, handleSubmit },
	} = useGetFilters({
		eventId,
		roundId,
		holeNum: holeNumber,
	});

	return (
		<>
			<button
				type='button'
				className={classNames('bottom-drawer-action__button', {
					'bottom-drawer-filter-count__span': filterCount > 0,
				})}
				onClick={toggleModal}
			>
				{filterCount > 0 && (
					<div className='filter-count__div'>{filterCount}</div>
				)}
				<span className='bottom-drawer-action__span'>Filters</span>
				<div className='bottom-drawer-action__arrow inverted'>
					<ChevronUpIcon />
				</div>
			</button>
			<Modal
				isVisible={isModalOpen}
				toggleModal={cancelModal}
				handleSubmit={handleSubmit}
				customStyles={{ padding: '1.25rem' }}
				showBottomButtons
			>
				<h2>Filters</h2>
				<div className='bottom-drawer-filters__section'>
					{filterData.map((filter, index) => (
						<div
							key={`${filter.label}`}
							style={{
								borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
							}}
						>
							<button
								type='button'
								onClick={() => {
									const newFilterData = [...filterData];
									newFilterData[index].isSectionOpen =
										!newFilterData[index].isSectionOpen;
									setFilterData(newFilterData);
								}}
							>
								<Checkbox
									label={filter.label}
									value={filter.options.every(x => x.selected)}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										const newFilterData = [...filterData];
										newFilterData[index].options = newFilterData[
											index
										].options.map(x => ({ ...x, selected: e.target.checked }));
									}}
									customStyles={{ fontSize: '16px' }}
								/>
								<div className='icon-background'>
									<div className={!filter.isSectionOpen ? 'inverted' : ''}>
										<ChevronUpIcon />
									</div>
								</div>
							</button>
							<ul
								style={{
									overflow: filter.isSectionOpen ? 'auto' : 'hidden',
									transition: filter.isSectionOpen
										? 'max-height 1s ease-in-out'
										: 'max-height 500ms cubic-bezier(0, 1, 0, 1)',
									maxHeight: filter.isSectionOpen ? '200px' : '0',
									animation: filter.isSectionOpen
										? 'hide-scroll 1s backwards'
										: '',
									marginLeft: '1.2rem',
								}}
							>
								{filter.options.map((option, optionIndex) => (
									<li key={option.value} style={{ padding: '10px 0' }}>
										<Checkbox
											label={option.value}
											value={option.selected}
											onChange={e => {
												const newFilterData = [...filterData];
												newFilterData[index].options[optionIndex].selected =
													!newFilterData[index].options[optionIndex].selected;
												setFilterData(newFilterData);
											}}
										/>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			</Modal>
		</>
	);
};

export default FilterDrawer;
