import { DateTime } from 'luxon';
import { OrgLinkType } from '../types/enum-types';
import OrgLink from './org-link';

export default class Event {
	private _startDate: DateTime;

	private _endDate: DateTime;

	// This will come from DB in a later iteration
	public imageUrl?: string;

	constructor(
		public id: number,
		public name: string,
		public venueName: string,
		public orgId: number,
		public address: string,
		public city: string,
		public state: string,
		public zipCode: string,
		public description: string,
		startDate: string,
		endDate: string,
		public links: OrgLink[],
	) {
		this._startDate = DateTime.fromISO(startDate);
		this._endDate = DateTime.fromISO(endDate);
	}

	get endDate() {
		return this._endDate;
	}

	get startDate() {
		return this._startDate;
	}

	get location() {
		return `${this.address} ${this.city ? `${this.city}, ` : ''}${this.state} ${
			this.zipCode
		}`;
	}

	get aboutUrl() {
		return this.links.find(x => x.type === OrgLinkType.about)?.link;
	}

	get contactUrl() {
		return this.links.find(x => x.type === OrgLinkType.contact)?.link;
	}

	get linkedinUrl() {
		return this.links.find(x => x.type === OrgLinkType.linkedin)?.link;
	}

	get twitterUrl() {
		return this.links.find(x => x.type === OrgLinkType.twitter)?.link;
	}

	get youtubeUrl() {
		return this.links.find(x => x.type === OrgLinkType.youtube)?.link;
	}
}
