import React, { ChangeEventHandler } from 'react';

import './RadioButton.scss';

interface RadioButtonProps {
	onChange: ChangeEventHandler;
	label: string;
	value: boolean;
}
const RadioButton = ({ label, value, onChange }: RadioButtonProps) => (
	<label htmlFor={label} className='radio-container'>
		<input type='radio' checked={value} onChange={onChange} id={label} />{' '}
		<span className='radio-button' />
		{label}
	</label>
);

export default RadioButton;
