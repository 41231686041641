/* eslint-disable react-hooks/rules-of-hooks */
import ChallengeType from '../../../../domain/types/challenge-type';
import { MapType } from '../../../../domain/types/enum-types';
import useWindowResize from '../../hooks/useWindowResize';

enum StrokeViewType {
	normal = 'normal',
	selected = 'selected',
	hover = 'hover',
	transparent = 'transparent',
}

export interface SvgType {
	radius: number;
	svg: string;
	fontSize: number;
	fontColor: string;
	fontWeight: string;
}

interface IconObject {
	normalIcon: SvgType;
	selectedIcon: SvgType;
	hoverIcon: SvgType;
	transparentIcon: SvgType;
}

export const mapColors = () => ({
	blue: '#003865',
	white: 'white',
	hover: '#BDCBD7',
	selected: '#CCDB2A',
	lightGrey: '#DADADA',
	grey: '#BDBCBC',
	darkGrey: '#737373',
	black: 'black',
	greenOverlay: 'rgba(82, 89, 9, 0.5)',
});

const getRadius = (
	mapType: MapType,
	strokeViewType: StrokeViewType,
	shots: number | null,
	challengeType: ChallengeType | undefined,
	scaleLevel: number
): [number, number] => {
	let radius = 0;
	let strokeWidth = 0;

	if (challengeType !== ChallengeType.FullRound) {
		if (strokeViewType === StrokeViewType.selected) {
			radius = 35;
			strokeWidth = 14;
		} else {
			radius = 18;
			strokeWidth = 3;
		}
	} else {
		radius = 25;
		strokeWidth = 4;
	}

	const { isMobileScreen } = useWindowResize();
	if (isMobileScreen) {
		radius *= 0.9;
	}

	if (mapType === MapType.bing) {
		radius /= 2;
		strokeWidth /= 2;
	}

	if (shots) {
		if (shots >= 20) radius += 40;
		else radius += shots * 2;
	}

	radius /= scaleLevel;
	strokeWidth /= scaleLevel;

	return [radius, strokeWidth];
};

const getSvg = (
	mapType: MapType,
	strokeViewType: StrokeViewType,
	label: string | undefined,
	challengeType: ChallengeType | undefined,
	scaleLevel: number
): SvgType => {
	const shots = label && !Number.isNaN(+label) ? +label : 1;

	const { isMobileScreen } = useWindowResize();

	// eslint-disable-next-line prefer-const
	let [radius, strokeWidth] = getRadius(
		mapType,
		strokeViewType,
		shots,
		challengeType,
		scaleLevel
	);

	if (challengeType === ChallengeType.FullRound) {
		const normalMultiplier = 1.15;
		if (strokeViewType === StrokeViewType.selected) {
			if (label) {
				radius *= 1.4;
			} else {
				radius *= normalMultiplier;
			}
		} else if (label) {
			radius *= normalMultiplier;
		} else {
			radius *= 0.9;
		}
	}

	let borderColor: string;
	let fillColor: string;

	const { blue, white, hover, selected, lightGrey, grey, darkGrey, black } =
		mapColors();

	switch (strokeViewType) {
		case StrokeViewType.selected:
			if (challengeType === ChallengeType.FullRound) {
				borderColor = white;
				fillColor = label ? blue : white;
			} else {
				borderColor = 'rgb(204, 219, 42)';
				fillColor = selected;
			}
			break;
		case StrokeViewType.hover:
			borderColor = hover;
			fillColor = hover;
			break;
		case StrokeViewType.normal:
		default:
			borderColor = grey;
			fillColor =
				challengeType === ChallengeType.FullRound && !label ? lightGrey : white;
			break;
	}

	const fontSize = (isMobileScreen ? 12 : 14) / scaleLevel;
	const fontOffsetY = 5 / scaleLevel;

	const fontWeight = 'bold';
	let fontColor = '';
	let fillOpacity = 1;
	let borderOpacity = 1;
	if (strokeViewType === StrokeViewType.selected) {
		if (challengeType === ChallengeType.FullRound) {
			fontColor = white;
		} else {
			fontColor = blue;
			borderOpacity = 0.3;
		}
	} else if (
		challengeType === ChallengeType.FullRound &&
		strokeViewType === StrokeViewType.transparent
	) {
		fontColor = darkGrey;
		fillOpacity = 0.5;
		borderOpacity = 1;
	} else {
		fontColor = black;
	}

	const bingLabel = `<text x="${radius}" y="${
		radius + fontOffsetY
	}" fill="${fontColor}" font-size="${fontSize}px" font-weight="${fontWeight}" font-family="gibson" text-anchor="middle">${
		label ?? ''
	}</text>`;

	const svg = `<svg width="${radius * 2}" height="${
		radius * 2
	}" xmlns="http://www.w3.org/2000/svg">
        <circle cx="${radius}" cy="${radius}" r="${
		radius - strokeWidth
	}" stroke="${borderColor}" stroke-opacity="${borderOpacity}" fill="${fillColor}" fill-opacity="${fillOpacity}" stroke-width="${strokeWidth}" />
		${mapType === MapType.bing ? bingLabel : ''}
    </svg>`;

	return {
		radius,
		svg: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`,
		fontSize,
		fontColor,
		fontWeight,
	};
};

export const getStrokeIcons = (
	mapType: MapType,
	label: string | undefined,
	challengeType: ChallengeType | undefined,
	scaleLevel = 1
): IconObject => {
	const normalIcon = getSvg(
		mapType,
		StrokeViewType.normal,
		label,
		challengeType,
		scaleLevel
	);
	const selectedIcon = getSvg(
		mapType,
		StrokeViewType.selected,
		label,
		challengeType,
		scaleLevel
	);
	const hoverIcon = getSvg(
		mapType,
		StrokeViewType.hover,
		label,
		challengeType,
		scaleLevel
	);
	const transparentIcon = getSvg(
		mapType,
		StrokeViewType.transparent,
		label,
		challengeType,
		scaleLevel
	);

	return {
		normalIcon,
		selectedIcon,
		hoverIcon,
		transparentIcon,
	};
};

export const getCircleColors = (mapType: MapType, scaleLevel = 1) => ({
	fill: 'transparent',
	stroke: 5 / scaleLevel,
	strokeColor: {
		r: 255,
		g: 255,
		b: 255,
		a: 0.5,
	},
});
