import React, { SVGProps } from 'react';

const CloseOutIcon = ({ fill, ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={12}
		height={12}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...rest}
	>
		<path
			d='m6 6.966-4.828 4.827A.662.662 0 0 1 .69 12a.662.662 0 0 1-.483-.207A.662.662 0 0 1 0 11.31c0-.184.069-.345.207-.482L5.034 6 .207 1.172A.662.662 0 0 1 0 .69C0 .506.069.345.207.207A.662.662 0 0 1 .69 0c.184 0 .344.069.482.207L6 5.034 10.828.207A.661.661 0 0 1 11.31 0c.184 0 .345.069.483.207A.662.662 0 0 1 12 .69a.662.662 0 0 1-.207.482L6.966 6l4.827 4.828a.662.662 0 0 1 .207.482.662.662 0 0 1-.207.483.662.662 0 0 1-.483.207.662.662 0 0 1-.482-.207L6 6.966Z'
			fill={fill}
		/>
	</svg>
);

export default CloseOutIcon;
