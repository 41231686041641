import React from 'react';

import useGetEventChallengesQuery from '../../../../api/challenges/queries/useGetEventChallengesQuery';
import CalendarIcon from '../../../../icons/CalendarIcon';
import GolfTeeIcon from '../../../../icons/GolfTeeIcon';
import LiveResultsWeatherIcon from '../../../../icons/LiveResultsWeatherIcon';
import { formatDateRange } from '../../../shared/helper-functions';
import useGetMapParams from '../../../shared/hooks/useGetMapParams';
import './EventInfoBanner.scss';

interface Props {
	classNames?: string;
}

const EventInfoBanner = ({ classNames }: Props) => {
	const { eventId } = useGetMapParams();

	const { eventChallenges } = useGetEventChallengesQuery(eventId);

	return eventChallenges ? (
		<div className={`event-info__icons ${classNames}`}>
			<div>
				<GolfTeeIcon
					width='0.6875rem'
					height='1rem'
					fill='white'
					className='icon'
					style={{ marginTop: '-0.1rem' }}
				/>
				<span>{eventChallenges.venueName}</span>
			</div>
			<div>
				<CalendarIcon
					width='1.125rem'
					height='1.25rem'
					fill='white'
					className='icon'
				/>
				<span>
					{formatDateRange(
						eventChallenges.eventStartDate,
						eventChallenges.eventEndDate
					)}
				</span>
			</div>
			<div>
				<LiveResultsWeatherIcon
					width='0.6875rem'
					height='0.875rem'
					fill='white'
					className='icon'
				/>
				<span>{eventChallenges.eventLocation}</span>
			</div>
		</div>
	) : null;
};

export default EventInfoBanner;
