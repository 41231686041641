import { useEffect, useRef } from 'react';

const useUpdateEffect = (
	effect: () => void | (() => void),
	deps?: ReadonlyArray<unknown>
) => {
	const isInitialMount = useRef(true);

	useEffect(
		() => {
			if (isInitialMount.current) {
				isInitialMount.current = false;
			} else {
				effect();
			}
		},
		deps ? [...deps] : undefined
	);
};

export default useUpdateEffect;
