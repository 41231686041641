import { useMemo } from 'react';

import FullRoundChallengeDetails from '../../../../domain/models/challenge-details/full-round-challenge-details';
import { StrokeLocation } from '../../../../domain/types/enum-types';
import { useAppSelector } from '../../../../redux/store';
import Player from '../models/player';

const useGetPlayers = (
	challengeDetails?: FullRoundChallengeDetails,
	showLostShots = true
) => {
	const { searchValue } = useAppSelector(state => state.ctp);

	return useMemo(() => {
		let players: Player[] = [];

		if (challengeDetails) {
			players = challengeDetails.participantsSorted?.map(
				player =>
					new Player(
						player.id,
						player.groupId,
						player.groupName,
						player.firstName,
						player.lastName,
						player.age,
						player.division,
						showLostShots
							? player.strokes
							: player.strokes.filter(
									x => x.courseLocation !== StrokeLocation.lost
							  )
					)
			);

			if (searchValue) {
				players = players.filter(x =>
					x.fullName.toLocaleLowerCase().includes(searchValue)
				);
			}
		}
		return players;
	}, [challengeDetails, searchValue]);
};

export default useGetPlayers;
