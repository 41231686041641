import { useEffect, useState } from 'react';

import { usePinPointChallengeDetailsQuery } from '../../../../api/challenges/queries/useGetChallengeDetailsQuery';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import useModal from '../../../shared/hooks/useModal';
import useOnceEffect from '../../../shared/hooks/useOnceEffect';
import { clearAllSelected, setGroupFilter } from '../../challenge-reducer';
import useGetGroupNamePairs from './useGetGroupNamePairs';
import useGetPlayerStrokes from './useGetPlayerStrokes';

interface OptionData {
	value: string;
	selected: boolean;
}

interface FilterData {
	label: string;
	isSectionOpen: boolean;
	options: OptionData[];
}

interface Props {
	eventId: number;
	roundId: number;
	holeNum: number;
}

const useGetFilters = ({ eventId, roundId, holeNum }: Props) => {
	const { challengeDetails } = usePinPointChallengeDetailsQuery(
		eventId,
		roundId,
		holeNum
	);

	const { allPlayers } = useGetPlayerStrokes(challengeDetails, {
		skipFilter: true,
	});

	const { filters, teeType, searchValue } = useAppSelector(state => state.ctp);

	const groupNames = useGetGroupNamePairs(allPlayers);
	const selectedGroups = Object.values(groupNames).map(x => x.name);

	const { isModalOpen, toggleModal } = useModal();
	const [filterData, setFilterData] = useState<FilterData[]>([]);
	const [filterCount, setFilterCount] = useState<number>(0);

	const dispatch = useAppDispatch();

	const setDefaultFormData = (groups?: string[]) => {
		const filteredGroups = groups ?? filters.selectedGroups;
		setFilterData([
			{
				label: 'Groups',
				isSectionOpen: true,
				options: Object.values(groupNames).map(group => ({
					value: group.name,
					selected: filteredGroups.some(x => x === group.name),
				})),
			},
		]);

		dispatch(setGroupFilter(selectedGroups));
	};

	// Once the data has loaded, find all unique groups and add them to selected list
	useOnceEffect(
		selectedGroups.length > 0,
		() => {
			setDefaultFormData(selectedGroups);
		},
		[selectedGroups]
	);

	useEffect(() => {
		setDefaultFormData(selectedGroups);
		setFilterCount(0); // need to rest filter count to 0 when tee type is switched
	}, [teeType, searchValue]);

	const cancelModal = () => {
		setDefaultFormData();

		toggleModal();
	};

	const handleSubmit = () => {
		toggleModal();

		setFilterData(filterData.map(x => ({ ...x, isSectionOpen: true })));

		// need to check if a filter has been applied for each dropdown for styling purposes
		setFilterCount(
			filterData
				.map(form => form.options.filter(x => !x.selected))
				.filter(x => x.length > 0).length
		);

		const filteredGroups =
			filterData
				.find(x => x.label.includes('Group'))
				?.options.filter(x => x.selected)
				.map(x => x.value) ?? [];

		dispatch(setGroupFilter(filteredGroups));
		// we need to unselect current strokes since data has changed
		dispatch(clearAllSelected());
	};

	return {
		data: {
			isModalOpen,
			filterData,
			filterCount,
		},
		handlers: {
			setFilterData,
			toggleModal,
			cancelModal,
			handleSubmit,
		},
	};
};

export default useGetFilters;
