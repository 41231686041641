import { useCallback, useEffect, useMemo } from 'react';

import { StrokeLocation } from '../../../../../domain/types/enum-types';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import useDropdown from '../../../../shared/forms/Dropdown/useDropdown';
import { TableColumn } from '../../../../shared/layout/Table/ScrollableTable';
import { clearAllSelected, selectStrokes } from '../../../challenge-reducer';
import Player from '../../models/player';

const useLeaderCard = (player: Player) => {
	const { selectedStrokeIds } = useAppSelector(state => state.ctp);

	const dispatch = useAppDispatch();

	const {
		openDropdown,
		dropdownHeight,
		closeDropdown,
		isDropdownOpen,
		dropdownRef,
	} = useDropdown();

	const isPlayerSelected = useMemo(
		() => player.includesStroke(selectedStrokeIds),
		[player.strokeIds]
	);

	const [columns, rows] = useMemo(() => {
		const tableColumns: TableColumn[] = [
			{
				id: 'shots',
				label: 'Shots',
				styles: { paddingLeft: '0.5rem' },
			},
		];
		const tableRows: {
			[key: string]: string | number;
		}[] = [
			{ shots: 'Shot \n Distance (yds)' },
			{ shots: 'Distance \n From Pin (yds)' },
		];

		player.strokes.forEach((stroke, index) => {
			const strokeNumber = stroke.strokeNumber.toString();
			tableColumns.push({
				id: strokeNumber,
				label: strokeNumber,
				styles: { textAlign: 'center' },
			});

			let shotDistance: number | string = Math.round(stroke.shotDistance.yards);
			let holeDistance: number | string = Math.round(stroke.holeDistance.yards);
			if (stroke.courseLocation === StrokeLocation.lost) {
				shotDistance = 'LOST';
				holeDistance = 'LOST';
			} else if (stroke.courseLocation === StrokeLocation.drop) {
				shotDistance = 'DROP';
			}

			if (
				index > 0 &&
				stroke.strokeNumber - 1 !== player.strokes[index - 1].strokeNumber
			) {
				shotDistance = '--';
			}
			tableRows[0][strokeNumber] = shotDistance;
			tableRows[1][strokeNumber] = holeDistance;
		});

		return [tableColumns, tableRows];
	}, [player.strokeIds]);

	useEffect(() => {
		// If marker is clicked on map, open the dropdown
		if (isPlayerSelected) {
			openDropdown();
		}
		// If another dropdown is open, close
		else {
			closeDropdown();
		}
	}, [isPlayerSelected]);

	return {
		dropdownRef,
		isPlayerSelected,
		isDropdownOpen,
		dropdownHeight,
		columns,
		rows,
		toggleDropdown: useCallback(() => {
			if (isDropdownOpen) {
				// Only close dropdown if its the only player selected and its open
				closeDropdown();
			} else {
				dispatch(clearAllSelected());
				dispatch(selectStrokes(player.strokeIds));
				openDropdown();
			}
		}, [isDropdownOpen, JSON.stringify(player.strokeIds), player.id]),
	};
};

export default useLeaderCard;
