import React, { SVGProps } from 'react';

interface StarIconProps extends SVGProps<SVGSVGElement> {
	selected?: boolean;
}

const StarIcon = ({
	selected = false,
	width,
	height,
	fill,
	...props
}: StarIconProps) => (
	<svg
		width={width || 16}
		height={height || 15}
		viewBox='0 0 16 15'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		{selected ? (
			<>
				<path
					d='M10.9578 0.833313C10.9578 0.557171 10.7339 0.333313 10.4578 0.333313H5.96555C5.68941 0.333313 5.46555 0.557171 5.46555 0.833313V15H10.9578V0.833313Z'
					fill='#003865'
				/>
				<path
					d='M11.6578 15H16V7.70831C16 7.43217 15.7761 7.20831 15.5 7.20831H12.1578C11.8816 7.20831 11.6578 7.43217 11.6578 7.70831V15Z'
					fill='#003865'
				/>
				<path
					d='M0 6.05831C0 5.78217 0.223858 5.55831 0.5 5.55831H4.26555C4.54169 5.55831 4.76555 5.78217 4.76555 6.05831V15H0L0 6.05831Z'
					fill='#003865'
				/>
			</>
		) : (
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.66667 5.5241V0.833313C4.66667 0.557171 4.89052 0.333313 5.16667 0.333313H11.1619C11.4381 0.333313 11.6619 0.557171 11.6619 0.833313V7.21352H15.5C15.7761 7.21352 16 7.43738 16 7.71352V15H0L0 6.0241C0 5.74795 0.223858 5.5241 0.5 5.5241H4.66667ZM10.9326 1.15789H5.49697V14.1754H10.9326V1.15789ZM4.66667 6.34867H0.804617V14.1754H4.66667V6.34867ZM15.1954 8.07893H11.6619V14.1754H15.1954V8.07893Z'
				fill={fill || '#737373'}
			/>
		)}
	</svg>
);

export default StarIcon;
