// eslint-disable-next-line max-classes-per-file
export class Prize {
	constructor(
		public id: number,
		public marketingInfoId: number,
		public title: string,
		public description: string,
		public imagePath: string
	) {}
}

export default class MarketingDetails {
	constructor(
		public id: number,
		public eventId: number,
		public title: string,
		public subtitle: string,
		public heroImagePath: string,
		public linkText: string,
		public linkUrl: string,
		public prizeText: string,
		public prizeDetailsLink: string,
		public rules: string[],
		public prizes: Prize[],
		public showImages: boolean
	) {}
}
