import React, { SVGProps } from 'react';

interface StarIconProps extends SVGProps<SVGSVGElement> {
  selected?: boolean;
}

const StarIcon = ({
  selected = false,
  width,
  height,
  fill,
  ...props
}: StarIconProps) => (
  <svg width={width || 17} height={height || 16} viewBox="0 0 17 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    {selected ? (
	  <path fillRule="evenodd" clipRule="evenodd" d="M9.08439 0.807048C8.78463 0.199653 7.9185 0.199652 7.61873 0.807048L5.77969 4.53335C5.66066 4.77455 5.43055 4.94172 5.16438 4.9804L1.05216 5.57794C0.38186 5.67534 0.114212 6.49908 0.599247 6.97187L3.57487 9.87239C3.76748 10.0601 3.85537 10.3306 3.8099 10.5957L3.10745 14.6913C2.99295 15.3589 3.69366 15.868 4.2932 15.5528L7.97128 13.6192C8.20935 13.494 8.49377 13.494 8.73185 13.6192L12.4099 15.5528C13.0095 15.868 13.7102 15.3589 13.5957 14.6913L12.8932 10.5957C12.8478 10.3306 12.9356 10.0601 13.1283 9.87239L16.1039 6.97187C16.5889 6.49908 16.3213 5.67534 15.651 5.57794L11.5387 4.9804C11.2726 4.94172 11.0425 4.77455 10.9234 4.53335L9.08439 0.807048Z" fill="#003865"/>
	  
    ) : (
		<path fillRule="evenodd" clipRule="evenodd" d="M10.1906 4.89508L8.35156 1.16878L6.51252 4.89508C6.27445 5.37748 5.81425 5.71183 5.28189 5.78919L1.16968 6.38673L4.1453 9.28725C4.53052 9.66274 4.7063 10.2037 4.61536 10.7339L3.91291 14.8295L7.59099 12.8959C8.06714 12.6455 8.63598 12.6455 9.11213 12.8959L12.7902 14.8295L12.0878 10.7339C11.9968 10.2037 12.1726 9.66274 12.5578 9.28725L15.5335 6.38673L11.4212 5.78919C10.8889 5.71183 10.4287 5.37748 10.1906 4.89508ZM9.08439 0.807109C8.78463 0.199714 7.9185 0.199714 7.61873 0.807109L5.77969 4.53341C5.66066 4.77461 5.43055 4.94178 5.16438 4.98046L1.05216 5.578C0.38186 5.6754 0.114212 6.49914 0.599247 6.97193L3.57487 9.87245C3.76748 10.0602 3.85537 10.3307 3.8099 10.5958L3.10745 14.6914C2.99295 15.359 3.69366 15.8681 4.2932 15.5529L7.97128 13.6192C8.20935 13.494 8.49377 13.494 8.73185 13.6192L12.4099 15.5529C13.0095 15.8681 13.7102 15.359 13.5957 14.6914L12.8932 10.5958C12.8478 10.3307 12.9356 10.0602 13.1283 9.87245L16.1039 6.97193C16.5889 6.49914 16.3213 5.6754 15.651 5.578L11.5387 4.98046C11.2726 4.94178 11.0425 4.77461 10.9234 4.53341L9.08439 0.807109Z" fill="#737373"/>
    )}
  </svg>
);

export default StarIcon;
