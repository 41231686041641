import Stroke from '../../../domain/models/stroke';
import SortService from '../../../domain/services/SortService';
import { SlChallengeStroke } from '../schema';

const toStroke = (slChallengeStroke: SlChallengeStroke) =>
	new Stroke(
		slChallengeStroke.id,
		slChallengeStroke.participantId,
		slChallengeStroke.strokeNumber,
		slChallengeStroke.courseLocation,
		slChallengeStroke.strokeTime,
		slChallengeStroke.ballTeeHeading,
		slChallengeStroke.ballHoleHeading,
		slChallengeStroke.ballLocation,
		slChallengeStroke.teeDistance,
		slChallengeStroke.shotDistance,
		slChallengeStroke.holeDistance
			? SortService.updateHoleInOneDistance(
					slChallengeStroke.courseLocation,
					slChallengeStroke.holeDistance
			  )
			: slChallengeStroke.holeDistance,
		slChallengeStroke.driveDistance,
		slChallengeStroke.teeLocationId
	);

export default toStroke;
