export default class ShotLocations {
	private regionData: { [key: string]: string } = {
		green: '#CCDB2A',
		rough: '#003865',
		sand: '#979797',
		water: '#A6C5D0',
		fairway: '#C5C2C2',
		out: '#D9D9D9',
		lost: '#47B39C',
	};

	constructor(public ctpShotLocations: object, private shotsTaken: number) {}

	get shotData() {
		return Object.entries(this.regionData).map(x => {
			const region = x[0];
			const color = x[1];

			const shotsInRegion =
				this.ctpShotLocations[region as keyof typeof this.ctpShotLocations] ??
				0;

			return {
				name: region,
				value: shotsInRegion,
				percentage: Math.round((shotsInRegion / this.shotsTaken) * 100),
				fill: color,
				defaultFill: color,
			};
		});
	}

	changeShotDataColor(activeIndex: number | undefined) {
		return this.shotData.map((x, index) => ({
			...x,
			fill: index === activeIndex ? x.fill : '#F9F9F9',
		}));
	}
}
