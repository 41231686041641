import Challenge from '../../../domain/models/challenge';
import ChallengeType from '../../../domain/types/challenge-type';
import { SlChallenge } from '../schema';
import { toHole } from './toHole';

const toChallenge = (slChallenge: SlChallenge) =>
	new Challenge(
		slChallenge.challengeId,
		slChallenge.challengeName,
		slChallenge.challengeDate,
		slChallenge.challengeType as ChallengeType,
		slChallenge.challengeDescription,
		slChallenge.challengeMapType,
		slChallenge.activePlayers,
		slChallenge.active,
		slChallenge.maxDistanceFromTeeToPin,
		slChallenge.minDistanceFromTeeToPin,
		slChallenge.averageDistanceToPin,
		slChallenge.averageDriveDistance,
		slChallenge.greensInRegulation,
		slChallenge.holes.map(x => toHole(x))
	);

export default toChallenge;
