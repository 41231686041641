import React, { useMemo } from 'react';

import { StrokeLocation } from '../../../../../domain/types/enum-types';
import ChevronUpIcon from '../../../../../icons/ChevronUpIcon';
import InfoIcon from '../../../../shared/forms/InfoIcon/InfoIcon';
import ScrollableTable from '../../../../shared/layout/Table/ScrollableTable';
import Player from '../../models/player';
import useLeaderCard from './useLeaderCard';

interface Props {
	player: Player;
	boxShadow: boolean;
}

const LeaderCard = ({ player, boxShadow }: Props) => {
	const {
		dropdownRef,
		isPlayerSelected,
		isDropdownOpen,
		dropdownHeight,
		columns,
		rows,
		toggleDropdown,
	} = useLeaderCard(player);

	const isLastShotInHole =
		player.strokes.at(-1)?.courseLocation === StrokeLocation.hole;

	const totalShots = player.strokes.at(-1)?.strokeNumber;

	return (
		<section
			className={`w-full mb-[0.375rem] bg-white rounded-[6px] leader-card ${
				isPlayerSelected ? 'selected' : ''
			}`}
			style={{
				border: isPlayerSelected
					? '2px solid rgba(0, 56, 101, 0.3)'
					: '1px solid #EDEDED',
				boxShadow: boxShadow ? '4px 4px 20px rgba(0,0,0,0.07)' : 'none',
			}}
		>
			<div
				className='leader-card__inner h-16'
				onKeyDown={toggleDropdown}
				onClick={toggleDropdown}
				role='button'
				tabIndex={0}
			>
				<div className='flex flex-col mr-[0.8125rem]'>
					<p
						className='text-[16px]'
						style={{
							fontWeight: isPlayerSelected ? 'bold' : 'normal',
						}}
					>
						{player.fullName}
					</p>
					<p className='text-text-gray text-[12px] mb-[0.33rem]'>
						{player.groupName}
					</p>
				</div>
				<div className='ml-auto mr-[0.625rem]'>
					<div className='icon-background grey-icon'>
						<div className={!isDropdownOpen ? 'inverted' : ''}>
							<ChevronUpIcon />
						</div>
					</div>
				</div>
			</div>
			<div
				className='px-[0.5rem]'
				style={{
					height: dropdownHeight ? dropdownHeight + 2 : 0, // adjust for selected LeaderCard borders
					overflow: 'hidden',
					transition: useMemo(() => {
						if (isPlayerSelected) {
							return 'none';
						}
						if (isDropdownOpen) return 'all 500ms ease';
						return 'all 200ms ease';
					}, [isDropdownOpen, isPlayerSelected]),
					marginBottom: isDropdownOpen ? '0.75rem' : '0',
				}}
			>
				<div className='pt-[.4rem]' ref={dropdownRef}>
					<ScrollableTable
						columns={columns}
						data={rows ?? []}
						tableStyles={{
							border: '1px solid #EDEDED',
						}}
						classes='horizontal-overflow'
					/>
					<div className='flex justify-between items-center mt-2'>
						<p className='text-[12px] text-[#737373] leading-none italic'>
							Total shots for this hole:&nbsp;
							<b>{isLastShotInHole ? totalShots : 'unknown'}</b>
						</p>
						<InfoIcon
							classes='info-icon__small'
							customStyles={{
								marginRight: '0.2rem',
							}}
							text={
								isLastShotInHole
									? 'Only tracked shots shown in table'
									: 'The final shot that landed in the hole has not been tracked'
							}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LeaderCard;
