import useGetOrgDetailsQuery from '../../../api/common/queries/useGetOrgDetailsQuery';
import useLazyGetEventQuery from '../../../api/common/queries/useLazyGetEventQuery';

const useGetSocialLinks = (eventId: number) => {
	const { orgDetails } = useGetOrgDetailsQuery();
	const { event } = useLazyGetEventQuery(eventId);
	let footerTitle = orgDetails?.name ?? '';
	let aboutUrl = null;
	let contactUrl = null;
	let linkedinUrl = null;
	let twitterUrl = null;
	let youtubeUrl = null;

	if (event?.aboutUrl) {
		aboutUrl = event.aboutUrl;
		footerTitle = event.name;
	} else if (orgDetails?.aboutUrl) {
		aboutUrl = orgDetails.aboutUrl;
	}
	if (event?.contactUrl) {
		contactUrl = event.contactUrl;
	} else if (orgDetails?.contactUrl) {
		contactUrl = orgDetails.contactUrl;
	}
	if (event?.linkedinUrl) {
		linkedinUrl = event.linkedinUrl;
	} else if (orgDetails?.linkedinUrl) {
		linkedinUrl = orgDetails.linkedinUrl;
	}
	if (event?.twitterUrl) {
		twitterUrl = event.twitterUrl;
	} else if (orgDetails?.twitterUrl) {
		twitterUrl = orgDetails.twitterUrl;
	}
	if (event?.youtubeUrl) {
		youtubeUrl = event.youtubeUrl;
	} else if (orgDetails?.youtubeUrl) {
		youtubeUrl = orgDetails.youtubeUrl;
	}

	return {
		footerTitle,
		aboutUrl,
		contactUrl,
		linkedinUrl,
		twitterUrl,
		youtubeUrl,
		isOrg: !!orgDetails,
	};
};

export default useGetSocialLinks;
