import CONSUME_CONFIG from './api-config';

const { CONSUME_API_KEY } = CONSUME_CONFIG;

const SessionStorageService = {
	key: 'apiKey',
	shellDomain: 'shellAppDomain',
	showOffer: 'showOfferPopup',
	showMarketing: 'showMarketingModal',

	get apiKey() {
		return sessionStorage.getItem(this.key) ?? CONSUME_API_KEY;
	},
	set apiKey(value: string) {
		sessionStorage.setItem(this.key, value);
	},

	get shellAppDomain() {
		return sessionStorage.getItem(this.shellDomain);
	},
	set shellAppDomain(value: string | null) {
		sessionStorage.setItem(this.shellDomain, value ?? '');
	},

	get showOfferPopup() {
		return sessionStorage.getItem(this.showOffer) ?? 'true';
	},
	set showOfferPopup(value: string) {
		sessionStorage.setItem(this.showOffer, value);
	},

	get showMarketingModal() {
		return sessionStorage.getItem(this.showMarketing) ?? 'true';
	},
	// local storage only allows strings
	set showMarketingModal(value: string) {
		sessionStorage.setItem(this.showMarketing, value);
	},
};

export default SessionStorageService;
