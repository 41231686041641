import { useEffect, useRef } from 'react';

import ChallengeType from '../../../../domain/types/challenge-type';
import { IconType, MapType } from '../../../../domain/types/enum-types';
import ScatterplotFlagIcon from '../../../../icons/ScatterplotFlagIcon.png';
import ScatterplotTeeBox from '../../../../icons/ScatterplotTeeBox.png';
import { useAppSelector } from '../../../../redux/store';
import { MarkerProps } from '../Maps/types';
import './Marker.scss';
import { SvgType, getStrokeIcons } from './marker-helper-functions';

interface GoogleMarkerProps extends MarkerProps {
	map?: google.maps.Map;
}

const GoogleMarker = ({
	iconType,
	map,
	position,
	label,
	onClick,
	popupContent,
	challengeType,
	isSelected = false,
}: GoogleMarkerProps) => {
	const { selectedStrokeIds } = useAppSelector(state => state.ctp);

	const lowerZIndex = -20;
	const higherZIndex = -19;

	const marker = new google.maps.Marker();
	const infoWindow = new google.maps.InfoWindow({
		content: `<div style="margin-left:5px">${popupContent}</div>`,
		ariaLabel: 'Cluster tooltip',
		disableAutoPan: true,
	});

	const hoverRef = useRef<boolean>(false);

	const { normalIcon, selectedIcon, hoverIcon, transparentIcon } =
		getStrokeIcons(MapType.google, label, challengeType);

	const setIcon = (svg: string, radius: number) => ({
		url: svg,
		anchor: new google.maps.Point(radius / 2, radius / 2),
		scaledSize: new google.maps.Size(radius, radius),
	});

	const mouseOver = () => {
		if (!hoverRef.current) {
			if (!isSelected) {
				marker.setOptions({
					icon: setIcon(hoverIcon.svg, hoverIcon.radius),
					zIndex: higherZIndex,
				});
			}
			hoverRef.current = true;
			infoWindow?.open({
				anchor: marker,
				map,
			});
		}
	};

	const mouseOut = () => {
		if (hoverRef.current) {
			if (!isSelected) {
				const icon =
					challengeType === ChallengeType.FullRound &&
					selectedStrokeIds.length > 0
						? transparentIcon
						: normalIcon;
				marker.setOptions({
					icon: setIcon(icon.svg, icon.radius),
					zIndex: lowerZIndex,
				});
			}
			hoverRef.current = false;

			if (
				!isSelected ||
				(challengeType === ChallengeType.FullRound &&
					!hoverRef.current &&
					!label)
			) {
				infoWindow?.close();
			}
		}
	};

	// if marker is selected, show info window. if it's selected and full round challenge, only show info window for marker that has initials (label)
	useEffect(() => {
		if (
			isSelected &&
			(challengeType !== ChallengeType.FullRound ||
				(challengeType === ChallengeType.FullRound && label))
		) {
			infoWindow?.open({
				anchor: marker,
				map,
			});
		} else {
			infoWindow?.close();
		}
	}, [isSelected]);

	// only add selected stroke id's to dependency array for full round challenge type
	const dependencies =
		challengeType !== ChallengeType.FullRound
			? [map, position, isSelected]
			: [map, position, isSelected, selectedStrokeIds];

	useEffect(() => {
		if (iconType === IconType.cluster) {
			let icon: SvgType;
			if (isSelected) {
				icon = selectedIcon;
			} else if (
				challengeType === ChallengeType.FullRound &&
				selectedStrokeIds.length > 0
			) {
				icon = transparentIcon;
			} else {
				icon = normalIcon;
			}

			marker.setOptions({
				map,
				position: new google.maps.LatLng(position.latitude, position.longitude),
				icon: setIcon(icon.svg, icon.radius),
				label: {
					fontSize: `${icon.fontSize}px`,
					color: icon.fontColor,
					fontWeight: icon.fontWeight,
					text: label ?? ' ',
				},
				zIndex: isSelected ? higherZIndex : lowerZIndex,
			});
		} else if (iconType === IconType.flag || iconType === IconType.tee) {
			const anchorX = iconType === IconType.flag ? 27 : 10;
			const anchorY = iconType === IconType.flag ? 44 : 22;
			marker.setOptions({
				map,
				position: new google.maps.LatLng(position.latitude, position.longitude),
				icon: {
					url:
						iconType === IconType.flag
							? ScatterplotFlagIcon
							: ScatterplotTeeBox,
					anchor: new google.maps.Point(anchorX, anchorY),
				},
				zIndex: -25,
			});
			marker.setCursor('default');
		}

		// click options
		if (onClick) {
			marker.addListener('click', onClick);
			marker.addListener('mouseover', mouseOver);
			marker.addListener('mouseout', mouseOut);
		}

		// remove marker and event listeners from map on unmount
		return () => {
			marker.setMap(null);

			if (onClick) {
				window.removeEventListener('click', onClick);
				window.removeEventListener('mouseover', mouseOver);
				window.removeEventListener('mouseout', mouseOut);
			}
		};
	}, dependencies);

	return null;
};

export default GoogleMarker;
