import ParticipantHighlights from '../../../domain/models/participant-highlights';
import { SlParticipantHighlights } from '../schema';

const toParticipantHighlights = (
	slParticipantHighlights: SlParticipantHighlights
) =>
	new ParticipantHighlights(
		slParticipantHighlights.participantId,
		slParticipantHighlights.participantFirstName,
		slParticipantHighlights.participantLastName,
		slParticipantHighlights.groupName,
		slParticipantHighlights.greensInRegulation,
		slParticipantHighlights.averageDriveDistance,
		slParticipantHighlights.averageDistanceToPin,
		slParticipantHighlights.holesPlayed
	);

export default toParticipantHighlights;
