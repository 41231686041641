import PinPointParticipant from '../participant/pin-point-participant';
import Group from './group';

export default class PinPointGroup implements Group {
	constructor(
		public id: number,
		public name: string,
		public participants: PinPointParticipant[]
	) {}
}
