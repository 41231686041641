import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import './App.scss';
// import EventHeader from './features/closest-to-the-pin/components/EventHeader/EventHeader';
import Leaderboard from './features/challenges/closest-to-the-pin/components/Leaderboard/Leaderboard';
import CtpScatterplot from './features/challenges/closest-to-the-pin/components/Scatterplot/Scatterplot';
import FullRoundScatterplot from './features/challenges/full-round/components/Scatterplot/Scatterplot';
import LongestDriveScatterplot from './features/challenges/longest-drive/components/Scatterplot/Scatterplot';
import EventBanner from './features/manage-events/components/EventBanner/EventBanner';
import EventDetails from './features/manage-events/components/EventDetails/EventDetails';
import EventsList from './features/manage-events/components/EventsList/EventsList';
import LeaderboardLandingPage from './features/marketing-leaderboard/components/LeaderboardLandingPage/LeaderboardLandingPage';
// import TermsText from './features/manage-events/components/EventTerms/TermsText';
import MarketingLandingPage from './features/marketing/MarketingLandingPage/MarketingLandingPage';
import AppFooter from './features/shared/layout/AppFooter/AppFooter';
import AppHeader from './features/shared/layout/AppHeader/AppHeader';
import NotFoundPage from './features/shared/layout/NotFoundPage/NotFoundPage';
import WarningSection from './features/shared/layout/WarningSection/WarningSection';
import useInitShell from './useInitShell';

const App = () => {
	const { foundApiKey, isOrgError } = useInitShell();

	const [errorMessage, isLoading] = useMemo(() => {
		let isApiKeyLoading = false;
		if (foundApiKey === undefined) isApiKeyLoading = true;

		let message = null;
		if (isOrgError) message = 'Sorry, this organization is disabled';
		else if (foundApiKey === false) message = 'Sorry, api key not found';

		return [message, isApiKeyLoading];
	}, [foundApiKey, isOrgError]);

	return (
		<div className='app-content'>
			<AppHeader />
			{!errorMessage && !isLoading && (
				<Routes>
					<Route path='/' element={<Navigate to='/events' />} />
					<Route path='events/*'>
						<Route
							path=':eventId/marketing/'
							element={<MarketingLandingPage />}
						/>
						{/* <Route
									path=':eventId/terms-and-conditions'
									element={
										<>
											<AppHeader />
											<EventHeader />
											<TermsText />
											<AppFooter />
										</>
									}
								/> */}
						<Route path=':eventId/challenges/*'>
							<Route path=':roundId/holes/:holeNumber/'>
								<Route path='pinpoint' element={<CtpScatterplot />} />
								<Route
									path='pinpointleaderboard'
									element={
										<div className='max-w-3xl m-auto px-2'>
											<Leaderboard />
										</div>
									}
								/>
								<Route path='fullround' element={<FullRoundScatterplot />} />
								<Route
									path='longestdrive'
									element={<LongestDriveScatterplot />}
								/>
								<Route
									path='leaderboard/*'
									element={<LeaderboardLandingPage />}
								/>
							</Route>
							<Route
								path='*'
								element={
									<>
										<EventDetails />
										<AppFooter />
									</>
								}
							/>
						</Route>
						<Route
							path='*'
							element={
								<>
									<EventsList />
									<AppFooter />
								</>
							}
						/>
					</Route>
					<Route path='*' element={<NotFoundPage />} />
				</Routes>
			)}
			{errorMessage && !isLoading && (
				<>
					<EventBanner className='event-info'>
						<h3 className='error-header'>{errorMessage}</h3>
					</EventBanner>
					<WarningSection>
						<p>
							Please try refreshing or contacting the site admin for support at{' '}
							<a href='mailto:shotlander@captechconsulting.com'>
								<u>shotlander@captechconsulting.com</u>
							</a>
						</p>
					</WarningSection>
				</>
			)}
		</div>
	);
};

export default App;
