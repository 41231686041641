import { useMemo } from 'react';

import challengeApi from '../challenges-api';
import toLeaderboard from '../transforms/toLeaderboard';

const useGetLeaderboardDetailsQuery = (
	eventId: number,
	roundId: number,
	holeNum: number
) => {
	const { data, refetch, error, ...rest } =
		challengeApi.useGetLeaderboardDetailsQuery({ eventId, roundId, holeNum });

	return {
		leaderboardDetails: useMemo(
			() => (data ? toLeaderboard(data) : undefined),
			[data]
		),
		error: error as { status: number } | undefined,
		refetchLeaderboard: refetch,
		...rest,
	};
};

export default useGetLeaderboardDetailsQuery;
