import Image01 from '../../../../assets/golf-event/event-image-01.webp';
import Image02 from '../../../../assets/golf-event/event-image-02.webp';
import Image03 from '../../../../assets/golf-event/event-image-03.webp';
import Image04 from '../../../../assets/golf-event/event-image-04.webp';
import Image05 from '../../../../assets/golf-event/event-image-05.webp';
import Image06 from '../../../../assets/golf-event/event-image-06.webp';
import Image07 from '../../../../assets/golf-event/event-image-07.webp';
import Image08 from '../../../../assets/golf-event/event-image-08.webp';
import Image09 from '../../../../assets/golf-event/event-image-09.webp';
import Image10 from '../../../../assets/golf-event/event-image-10.webp';
import Image11 from '../../../../assets/golf-event/event-image-11.webp';
import Event from '../../../../domain/models/event';

function getImage(num: number) {
	switch (num % 11) {
		case 1:
			return Image01;
		case 2:
			return Image02;
		case 3:
			return Image03;
		case 4:
			return Image04;
		case 5:
			return Image05;
		case 6:
			return Image06;
		case 7:
			return Image07;
		case 8:
			return Image08;
		case 9:
			return Image09;
		case 10:
			return Image10;
		default:
			return Image11;
	}
}

/**
 * Temporary fix that assigns events a random stock photo from our collection for cards
 * First sort ascending, assign a photo, then reverse to have them be descending.
 * This sorting will help assign the same photos to the same events even if more are added over time
 * @param events
 * @returns
 */
const setHeroImages = (events?: Event[]) => {
	if (events) {
		events
			.sort((a, b) => a.endDate.toMillis() - b.endDate.toMillis())
			.forEach((event, index) => {
				event.imageUrl = getImage(index + 1);
			});

		return events.reverse();
	}

	return events;
};

export default setHeroImages;
