import React from 'react';

import GolferSwinging from '../../../assets/marketing/GolferSwinging.png';
import ScatterplotDesktopView from '../../../assets/marketing/ScatterplotDesktopView.png';
import ScatterplotMobileView from '../../../assets/marketing/ScatterplotMobileView.png';
import ArrowRightIcon from '../../../icons/ArrowRight';
import EventBanner from '../../manage-events/components/EventBanner/EventBanner';
import EventInfoBanner from '../../manage-events/components/EventInfoBanner/EventInfoBanner';
import AppFooter from '../../shared/layout/AppFooter/AppFooter';
import WarningSection from '../../shared/layout/WarningSection/WarningSection';
import './MarketingLandingPage.scss';
import useMarketingLandingPage from './useMarketingLandingPage';

const MarketingLandingPage = () => {
	const {
		marketingDetails,
		isLoading,
		heroBackgroundImage,
		howToPlaySection,
		promotionSection,
	} = useMarketingLandingPage();

	const backgroundImage = `linear-gradient(180.02deg, rgba(0, 0, 0, 0) 6.79%, #000000 83.91%), url(${heroBackgroundImage})`;

	if (!marketingDetails && !isLoading) {
		// TODO : when there's time, create custom hook for this error page so you don't have to keep importing these components on every page
		return (
			<>
				<EventBanner className='event-info'>
					<h3 className='error-header'>
						There is no marketing page setup for this event
					</h3>
				</EventBanner>
				<WarningSection>
					<p>
						Please try refreshing or contacting the site admin for support at{' '}
						<a href='mailto:shotlander@captechconsulting.com'>
							<u>shotlander@captechconsulting.com</u>
						</a>
					</p>
				</WarningSection>
			</>
		);
	}

	return marketingDetails ? (
		<section className='marketing-section'>
			<div
				className='marketing-section__event-banner
			md:py-[140px] py-[60px] text-white space-y-12 md:px-[11.5vh] px-[4vh]'
				style={{
					backgroundImage,
				}}
			>
				<EventInfoBanner classNames='small-screen-flex-col' />
				<div className='max-w-2xl space-y-10'>
					<h1 className='text-[32px] font-bold'>{marketingDetails.title}</h1>
					{ marketingDetails.subtitle && 
						<p className='text-[14px] md:text-[16px] leading-[20px] md:leading-[30px]'>
							{ marketingDetails.subtitle }
						</p>
					}
					
					{marketingDetails.linkText.length > 0 && (
						<a
							aria-label='Marketing External Link'
							target='_blank'
							rel='noreferrer'
							href={marketingDetails.linkUrl}
							className='text-black bg-white font-semibold py-4 px-8 inline-block'
						>
							{marketingDetails.linkText}
						</a>
					)}
				</div>
			</div>
			{marketingDetails.prizes.length > 0 && (
				<>
					<div className='md:mx-[11.5vh] mx-[4vh] mt-[25px] md:mt-[100px] flex flex-row items-center justify-between'>
						<h1 className='text-[32px] text-ct-blue align-middle'>
							{marketingDetails.prizeText}
						</h1>
						<div className='flex flex-row items-center justify-center space-x-2'>
							<a
								aria-label='Full Contest Details Link'
								target='_blank'
								rel='noreferrer'
								href={marketingDetails.prizeDetailsLink}
								className='text-[12px] md:text-[16px] font-bold align-middle text-ct-blue'
							>
								Full Contest Details
							</a>
							<ArrowRightIcon fill='#003865' />
						</div>
					</div>
					<div className='md:mx-[11.5vh] mx-[4vh] my-[10px] border-t-2 border-[#EDEDED]' />
					<div className='md:mb-[72px]'>{promotionSection}</div>
				</>
			)}

			{marketingDetails.rules.length > 0 && (
				<div className='pb-[60px] pt-[100px] md:pl-[11.5vh] pl-[4vh] bg-[#F7F7F7] md:flex md:justify-between'>
					<div className='md:-mt-36 -mt-32 mb-12 ml-8 order-2'>
						<img
							alt='img'
							className='md:max-w-[92%] xxxl:max-w-[100%] ml-auto'
							src={GolferSwinging}
						/>
					</div>
					<div className='md:max-w-[620px] order-first'>
						<h1 className='text-[32px] text-ct-blue'>How to Play</h1>
						<div className='mt-[10px] mb-[40px] border-t-2 border-[#DADADA]' />
						{howToPlaySection}
					</div>
				</div>
			)}

			<div
				className='marketing-section__event-banner py-[80px] bg-[#003865]'
				style={{ backgroundImage }}
			>
				<div className='space-y-6 text-center px-[4vh]'>
					<h1 className='text-[12px] md:text-[16px] sm:leading-[20px] sm:tracking-[3px] font-bold text-white'>
						A NEW WAY TO EXPERIENCE CLOSEST TO THE PIN
					</h1>
					<h1 className='text-[22px] md:text-[44px] leading-[29px] md:leading-[56px] tracking-[.2px] text-white font-bold'>
						Using Simple Technology to Enhance Your Experience
					</h1>
					<h1 className='text-[14px] md:text-[16px] leading-[20px] md:leading-[30px] text-white md:w-[660px] mx-auto'>
						Thank you for your support of Children’s Miracle Network Hospitals,
						locally supporting Orlando Health Arnold Palmer Hospital for
						Children
					</h1>
				</div>
				<div className='md:mx-auto mx-[4vh] mt-10 relative mx-auto w-fit'>
					<img alt='Scatterplot desktop view' src={ScatterplotDesktopView} />
					<img
						alt='Scatterplot mobile view'
						className='absolute right-4 md:right-[-3rem] bottom-[-3rem] w-3/12 md:w-fit'
						src={ScatterplotMobileView}
					/>
				</div>
			</div>
			<AppFooter />
		</section>
	) : null;
};

export default MarketingLandingPage;
