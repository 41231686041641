import React, { useState } from 'react';

import useGetFullRoundHighlightsQuery from '../../../../../api/challenges/queries/useGetFullRoundHighlightsQuery';
import ChevronUpIcon from '../../../../../icons/ChevronUpIcon';
import { useAppDispatch } from '../../../../../redux/store';
import InfoIcon from '../../../../shared/forms/InfoIcon/InfoIcon';
import useGetMapParams from '../../../../shared/hooks/useGetMapParams';
import { clearSelectedStrokes, runSearch } from '../../../challenge-reducer';
import SearchInput from '../../../shared/components/SearchInput/SearchInput';
import useGetFullRoundHighlights from '../../selectors/useGetFullRoundHighlights';
import RoundHighlightsCard from './RoundHighlightsCard';

const RoundHighlights = () => {
	const dispatch = useAppDispatch();

	const { eventId, roundId } = useGetMapParams();

	const { fullRoundHighlights } = useGetFullRoundHighlightsQuery(
		eventId,
		roundId
	);

	const {
		greensInRegulationSorted,
		averageDriveSorted,
		averageDistanceToPinSorted,
	} = useGetFullRoundHighlights(fullRoundHighlights);

	const [isGreenSectionOpen, setisGreenSectionOpen] = useState<boolean>(false);
	const [isDriveSectionOpen, setisDriveSectionOpen] = useState<boolean>(false);
	const [isDistanceToPinSectionOpen, setIsDistanceToPinSectionOpen] =
		useState<boolean>(false);

	const initialItemsShown = 2;

	return (
		<section
			className='bg-white leaderboard__overlay-section pt-4 border-t border-[#e0dfdf] xl:rounded-b-lg'
			// ref={refContent}
		>
			<SearchInput
				onChange={e => {
					dispatch(runSearch(e.target.value));
					dispatch(clearSelectedStrokes());
				}}
			/>
			<div className='leaderboard__container mt-2.5 mb-[1rem] items-start'>
				<h3>Greens In Regulation</h3>
				{(!isGreenSectionOpen
					? greensInRegulationSorted?.slice(0, initialItemsShown)
					: greensInRegulationSorted
				)?.map(p => (
					<RoundHighlightsCard
						key={`Green in Regulation card for player ${p.participantId}`}
						rank={p.rank}
						name={p.fullName}
						group={p.groupName}
						score={p.greensInRegulation.toString()}
					/>
				))}
				{(greensInRegulationSorted?.length ?? 0) > 2 && (
					<button
						type='button'
						className='ml-auto mr-auto text-[#003865] text-[14px] font-bold flex items-center'
						onClick={() => {
							setisGreenSectionOpen(prev => !prev);
						}}
					>
						See {!isGreenSectionOpen ? 'more' : 'less'}
						<div
							className={`${!isGreenSectionOpen ? 'inverted pr-1' : 'pl-1'}`}
						>
							<ChevronUpIcon />
						</div>
					</button>
				)}

				<h3 className='mt-5'>Average Drive Distance (Par 4 & 5)</h3>
				{(!isDriveSectionOpen
					? averageDriveSorted?.slice(0, initialItemsShown)
					: averageDriveSorted
				)?.map(p => (
					<RoundHighlightsCard
						key={`Average Drive Distance card for player ${p.participantId}`}
						rank={p.rank}
						name={p.fullName}
						group={p.groupName}
						score={
							p.averageDriveDistance
								? `${Math.round(p.averageDriveDistance.yards)}`
								: 'N/A'
						}
						subscore={p.averageDriveDistance ? 'yds' : ''}
					/>
				))}
				{(averageDriveSorted?.length ?? 0) > 2 && (
					<button
						type='button'
						className='ml-auto mr-auto text-[#003865] text-[14px] font-bold flex items-center'
						onClick={() => {
							setisDriveSectionOpen(prev => !prev);
						}}
					>
						See {!isDriveSectionOpen ? 'more' : 'less'}
						<div
							className={`${!isDriveSectionOpen ? 'inverted pr-1' : 'pl-1'}`}
						>
							<ChevronUpIcon />
						</div>
					</button>
				)}

				<span className='flex items-center mt-5'>
					<h3 className='mr-2'>Average Distance From Pin (Par 3)</h3>
					<InfoIcon text='Average distance to pin calculates the average distance between the first stroke and the hole on par 3s.' />
				</span>
				{(!isDistanceToPinSectionOpen
					? averageDistanceToPinSorted?.slice(0, initialItemsShown)
					: averageDistanceToPinSorted
				)?.map(p => (
					<RoundHighlightsCard
						key={`Average Drive Distance card for player ${p.participantId}`}
						rank={p.rank}
						name={p.fullName}
						group={p.groupName}
						score={
							p.averageDistanceToPin
								? `${Math.round(p.averageDistanceToPin.feet)}'`
								: 'N/A'
						}
					/>
				))}
				{(averageDistanceToPinSorted?.length ?? 0) > 2 && (
					<button
						type='button'
						className='ml-auto mr-auto text-[#003865] text-[14px] font-bold flex items-center'
						onClick={() => {
							setIsDistanceToPinSectionOpen(prev => !prev);
						}}
					>
						See {!isDistanceToPinSectionOpen ? 'more' : 'less'}
						<div
							className={`${
								!isDistanceToPinSectionOpen ? 'inverted pr-1' : 'pl-1'
							}`}
						>
							<ChevronUpIcon />
						</div>
					</button>
				)}
			</div>
		</section>
	);
};

export default RoundHighlights;
