import ChallengeType from '../types/challenge-type';
import Distance from '../types/distance';
import { MapType } from '../types/enum-types';
import Hole from './hole';

export default class Challenge {
	constructor(
		public id: number,
		public name: string,
		public date: string,
		public type: ChallengeType,
		public description: string,
		public mapType: MapType,
		public activePlayers: number,
		public active: boolean,
		public maxDistanceFromTeeToPin: Distance | null,
		public minDistanceFromTeeToPin: Distance | null,
		public averageDistanceToPin: Distance | null,
		public averageDriveDistance: Distance | null,
		public greensInRegulation: number,
		public holes: Hole[]
	) {}
}
