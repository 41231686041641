import { useMemo } from 'react';

import { arrayToDictionary } from '../../../shared/helper-functions';
import PlayerStroke from '../models/player-stroke';

const useGetGroupNamePairs = (playerStrokes?: PlayerStroke[]) =>
	useMemo(() => {
		if (playerStrokes) {
			return arrayToDictionary(
				Array.from(
					new Set(playerStrokes.map(x => ({ id: x.teamId, name: x.teamName })))
				)
			);
		}

		return {};
	}, [playerStrokes]);

export default useGetGroupNamePairs;
