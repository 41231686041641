import Distance from '../types/distance';
import Region from './region';
import ShotLocations from './shot-locations';

export default class GameHighlights {
	constructor(
		public averageDistance: Distance,
		public shotsAboveAverage: number,
		public shotsTaken: number,
		public activePlayers: number,
		public parLevel: number | null,
		public shotLocations: ShotLocations,
		public regions?: Region[]
	) {}

	get trendingRegion() {
		if (this.regions && this.regions.length > 0) {
			return this.regions.reduce((prev, curr) =>
				prev.trendingShots > curr.trendingShots ? prev : curr
			);
		}
		return undefined;
	}
}
