import React, { SVGProps } from 'react';

import CapTechIcon from '../domain/types/captech-icon';

const TwitterIcon = ({
	width,
	height,
	fill,
	...props
}: SVGProps<SVGSVGElement> & CapTechIcon) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M25.2952 4.97778H7.41588C6.06985 4.97778 4.97778 6.06985 4.97778 7.41588V25.2952C4.97778 26.6413 6.06985 27.7333 7.41588 27.7333H25.2952C26.6413 27.7333 27.7333 26.6413 27.7333 25.2952V7.41588C27.7333 6.06985 26.6413 4.97778 25.2952 4.97778ZM22.8114 13.0438C22.8216 13.186 22.8216 13.3333 22.8216 13.4756C22.8216 17.8794 19.4692 22.9537 13.3435 22.9537C11.454 22.9537 9.70159 22.4051 8.22858 21.4603C8.49778 21.4908 8.75683 21.501 9.03112 21.501C10.5905 21.501 12.0229 20.9727 13.1657 20.0787C11.7029 20.0483 10.4737 19.0883 10.0521 17.7676C10.5651 17.8438 11.0273 17.8438 11.5556 17.7067C10.0318 17.3968 8.88889 16.0559 8.88889 14.4356V14.3949C9.3308 14.6438 9.84889 14.7962 10.3924 14.8165C9.93546 14.5126 9.56089 14.1003 9.30209 13.6163C9.04329 13.1324 8.90831 12.592 8.90921 12.0432C8.90921 11.4235 9.07175 10.8546 9.36128 10.3619C11.0019 12.3835 13.4654 13.7041 16.2286 13.8464C15.7562 11.586 17.4476 9.75239 19.4794 9.75239C20.4394 9.75239 21.3029 10.1537 21.9124 10.8038C22.6641 10.6616 23.3854 10.3822 24.0254 10.0013C23.7765 10.7733 23.2533 11.4235 22.5625 11.8349C23.233 11.7638 23.8832 11.5759 24.4825 11.3168C24.0305 11.9822 23.4616 12.5714 22.8114 13.0438Z'
			fill={fill}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM27.0221 7.11109H6.39989V25.6H27.0221V7.11109Z'
			fill={fill}
		/>
	</svg>
);

export default TwitterIcon;
