import React, { useMemo, useState } from 'react';

import Challenge from '../../../../domain/models/challenge';
import CalendarIcon from '../../../../icons/CalendarIcon';
import HoleIcon from '../../../../icons/HoleIcon';
import {
	formatDateRange,
	lowerCaseFirstLetter,
} from '../../../shared/helper-functions';
import useModal from '../../../shared/hooks/useModal';
import useNavigateToPage from '../../../shared/hooks/useNavigateToPage';
import Modal from '../../../shared/layout/Modal/Modal';
import challengeTypeRules from './ChallengeTypeRules.json';

interface Props {
	challenge: Challenge;
}

enum ModalType {
	ViewRules,
	Highlights,
}

const FullRoundDetailsCard = ({
	challenge: {
		id: challengeId,
		name,
		date,
		type,
		description,
		mapType,
		activePlayers,
		averageDriveDistance,
		greensInRegulation,
		holes,
	},
}: Props) => {
	const navigateToPage = useNavigateToPage();
	const { isModalOpen, toggleModal } = useModal();

	const [modalType, setModalType] = useState<ModalType>();

	const challengeRules =
		challengeTypeRules[
			lowerCaseFirstLetter(type.toString()) as keyof typeof challengeTypeRules
		];

	const [firstHoleNumber, numberOfHolesInRound] = useMemo(() => {
		if (holes.length > 0) {
			return [holes[0].holeNumber, holes.length];
		}
		return [1, 0];
	}, [holes]);

	return (
		<section className='challenge-card'>
			<div className='text-[12px] md:text-[14px] text-[#737373] tracking-normal'>
				<span>Full Round Challenge</span>
				<span className='text-[8px] md:text-[10px] relative bottom-1'>TM</span>
			</div>
			<div className='challenge-card__title'>
				<h4>{name}</h4>
			</div>
			<div className='challenge-card__hole-info flex items-center'>
				{date && (
					<>
						<CalendarIcon
							width='1.125rem'
							height='1.25rem'
							fill='#003865'
							className='icon'
						/>
						<span className='pl-2 mr-16'>{formatDateRange(date)}</span>
					</>
				)}
				{numberOfHolesInRound && (
					<>
						<HoleIcon />
						<span className='pl-2'>{numberOfHolesInRound} Holes</span>
					</>
				)}
			</div>
			<hr style={{ borderTop: '1px solid #DADADA' }} />
			<div className='challenge-card__body'>
				<p>
					<span className={`${description ? 'mr-[5px]' : ''}`}>
						{description}
					</span>
					<button
						type='button'
						onClick={() => {
							toggleModal();
							setModalType(ModalType.ViewRules);
						}}
					>
						View Rules
					</button>
				</p>
			</div>
			<div className='text-[12px] md:text-[14px] text-[#737373] tracking-normal mt-[15px]'>
				Current Results
			</div>
			<div className='challenge-card__round-info'>
				<div className='challenge-card__round-detail'>
					<span>{`${activePlayers} Active Players`}</span>
				</div>
				{averageDriveDistance?.yards &&
					!Number.isNaN(Number(averageDriveDistance.yards)) && (
						<div className='challenge-card__round-detail'>
							<span>{`${Math.round(
								averageDriveDistance.yards
							)}yd Avg Drive Distance`}</span>
						</div>
					)}
				<div className='challenge-card__round-detail'>
					<span>{`${greensInRegulation} Greens in Regulation`}</span>
				</div>
			</div>
			<div className='challenge-card__action-buttons'>
				<button
					type='button'
					className='results'
					onClick={() => {
						const path = `${challengeId}/holes/${firstHoleNumber}`;
						navigateToPage(`${path}/fullround?mapType=${mapType}`);
					}}
				>
					<span>FULL RESULTS</span>
				</button>
			</div>
			<Modal
				isVisible={isModalOpen && modalType === ModalType.ViewRules}
				toggleModal={toggleModal}
				handleSubmit={toggleModal}
				showBottomButtons={false}
			>
				<div className='challenge-card__rules-modal'>
					<div className='header'>
						<h2>Welcome to {challengeRules?.title} Challenge</h2>
					</div>
					<div className='content'>
						{challengeRules?.rules.map(rule => (
							<p key={rule}>{rule}</p>
						))}
					</div>
					<div className='footer'>
						<button
							type='button'
							onClick={e => {
								e.stopPropagation();
								toggleModal();
							}}
						>
							CLOSE
						</button>
					</div>
				</div>
			</Modal>
		</section>
	);
};

export default FullRoundDetailsCard;
