import React, { ChangeEventHandler } from 'react';

import './Checkbox.scss';

interface CheckboxProps {
	onChange: ChangeEventHandler;
	label: string;
	value: boolean;
	customStyles?: { [key: string]: string };
}
const Checkbox = ({
	label,
	value,
	customStyles: styles,
	onChange,
}: CheckboxProps) => (
	<label htmlFor={label} className='checkbox-container' style={styles}>
		<input type='checkbox' checked={value} onChange={onChange} id={label} />{' '}
		<span className='checkbox' />
		{label}
	</label>
);

export default Checkbox;
