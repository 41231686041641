import React from 'react';

import { MapType } from '../../../../domain/types/enum-types';
import EventBanner from '../../../manage-events/components/EventBanner/EventBanner';
import WarningSection from '../../layout/WarningSection/WarningSection';

interface ErrorProps {
	errorMessage: string;
	mapType?: MapType;
}

const MapErrorSection = ({
	errorMessage,
	mapType = MapType.google,
}: ErrorProps) => (
	<div className='map-error'>
		<EventBanner className='event-info'>
			<h3 className='error-header'>Sorry, map was unable to load</h3>
		</EventBanner>
		<WarningSection>
			<p className='mb-9'>
				Please try refreshing or contacting the site admin for support at{' '}
				<a href='mailto:shotlander@captechconsulting.com'>
					<u>shotlander@captechconsulting.com</u>
				</a>
			</p>
			{mapType === MapType.google && (
				<div>
					<h2 className='mb-2 font-bold'>
						PLEASE READ IF YOU ARE USING CHROME ON A MAC
					</h2>
					<p>
						Google maps uses WebGL to view their maps. However, there is a
						Chrome bug affecting these scripts and causing the map to error out.
						Please try these steps to see if it helps:
					</p>
					<ul style={{ listStyleType: 'circle' }} className='ml-9'>
						<li>Open a new tab and navigate to: chrome://flags/</li>
						<li>
							Search for <q>Override software rendering list</q> and click{' '}
							<q>Enable</q> from dropdown
						</li>
						<li>
							Search for <q>Choose ANGLE graphics backend</q> and click{' '}
							<q>Metal</q> from dropdown
						</li>
						<li>Restart browser</li>
					</ul>
					<p>
						NOTE: This will not fix all WebGL errors, but it should decrease
						them.
					</p>
				</div>
			)}
			<p className='error-text mt-9'>Error message: {errorMessage}</p>
		</WarningSection>
	</div>
);

export default MapErrorSection;
