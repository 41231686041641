import { useEffect, useRef } from 'react';

import ChallengeType from '../../../../domain/types/challenge-type';
import { IconType, MapType } from '../../../../domain/types/enum-types';
import ScatterplotFlagIcon from '../../../../icons/ScatterplotFlagIcon.png';
import ScatterplotFlagIconSmall from '../../../../icons/ScatterplotFlagIconSmall.png';
import ScatterplotTeeBox from '../../../../icons/ScatterplotTeeBox.png';
import { useAppSelector } from '../../../../redux/store';
import useWindowResize from '../../hooks/useWindowResize';
import { MarkerProps } from '../Maps/types';
import './Marker.scss';
import { SvgType, getStrokeIcons } from './marker-helper-functions';

interface BingMarkerProps extends MarkerProps {
	map?: Microsoft.Maps.Map;
}

const BingMarker = ({
	iconType,
	map,
	position,
	label,
	onClick,
	popupContent,
	challengeType,
	isSelected = false,
}: BingMarkerProps) => {
	let pin: Microsoft.Maps.Pushpin | null = null;
	let infoWindow: Microsoft.Maps.Infobox | null;
	const hoverRef = useRef<boolean>(false);
	const { isMobileScreen } = useWindowResize();

	const { manualMapScale, selectedStrokeIds } = useAppSelector(
		state => state.ctp
	);

	const { normalIcon, selectedIcon, hoverIcon, transparentIcon } =
		getStrokeIcons(MapType.bing, label, challengeType, manualMapScale);

	const setIcon = (svg: string, radius: number) => ({
		icon: svg,
		anchor: new Microsoft.Maps.Point(radius, radius),
	});

	const mouseOver = () => {
		if (!hoverRef.current && pin) {
			if (!isSelected) {
				pin.setOptions(setIcon(hoverIcon.svg, hoverIcon.radius));
			}
			hoverRef.current = true;
			infoWindow?.setOptions({
				visible: true,
				offset: new Microsoft.Maps.Point(
					hoverIcon.radius,
					hoverIcon.radius / 2
				),
			});
		}
	};

	const mouseOut = () => {
		if (hoverRef.current && pin) {
			if (!isSelected) {
				const icon =
					challengeType === ChallengeType.FullRound &&
					selectedStrokeIds.length > 0
						? transparentIcon
						: normalIcon;
				pin.setOptions(setIcon(icon.svg, icon.radius));
			}

			hoverRef.current = false;
			infoWindow?.setOptions({ visible: false });
		}
	};

	// only add selected stroke id's to dependency array for full round challenge type
	const dependencies =
		challengeType !== ChallengeType.FullRound
			? [map, position, isSelected, manualMapScale]
			: [map, position, isSelected, manualMapScale, selectedStrokeIds];

	useEffect(() => {
		let clickId: Microsoft.Maps.IHandlerId;
		let mouseOverId: Microsoft.Maps.IHandlerId;
		if (map) {
			try {
				infoWindow = new Microsoft.Maps.Infobox(
					new Microsoft.Maps.Location(position.latitude, position.longitude),
					{
						// html content should be relative to how much map is zoomed in
						htmlContent: `<div style="
							font-size: ${14 / manualMapScale}px; 
							background-color: white;
							padding: ${5 / manualMapScale}px ${10 / manualMapScale}px;
							border-radius: ${5 / manualMapScale}px"
						>${popupContent}</div>`,
						visible: false,
					}
				);
				infoWindow.setMap(map);

				let icon: SvgType;
				if (isSelected) {
					icon = selectedIcon;
				} else if (
					challengeType === ChallengeType.FullRound &&
					selectedStrokeIds.length > 0
				) {
					icon = transparentIcon;
				} else {
					icon = normalIcon;
				}

				// add custom pin
				if (iconType === IconType.cluster) {
					pin = new Microsoft.Maps.Pushpin(
						new Microsoft.Maps.Location(position.latitude, position.longitude),
						{
							icon: icon.svg,
							anchor: new Microsoft.Maps.Point(icon.radius, icon.radius),
						}
					);
				} else if (iconType === IconType.flag || iconType === IconType.tee) {
					let anchorX = iconType === IconType.flag ? 27 : 10;
					let anchorY = iconType === IconType.flag ? 44 : 22;
					if (manualMapScale > 1 && manualMapScale < 2) {
						anchorX += manualMapScale;
						anchorY += manualMapScale;
					} else if (manualMapScale >= 2) {
						anchorX /= 2;
						anchorY /= 2;
					}

					let iconImg;
					if (iconType === IconType.flag) {
						iconImg =
							manualMapScale < 2
								? ScatterplotFlagIcon
								: ScatterplotFlagIconSmall;
					} else {
						iconImg = ScatterplotTeeBox;
					}

					pin = new Microsoft.Maps.Pushpin(
						new Microsoft.Maps.Location(position.latitude, position.longitude),
						{
							icon: iconImg,
							anchor: new Microsoft.Maps.Point(anchorX, anchorY),
						}
					);
				}
				if (pin) map.entities.push(pin);

				// disable click events on mobile devices because the manual zoom is messing up touch events
				if (onClick && pin && !isMobileScreen) {
					clickId = Microsoft.Maps.Events.addHandler(pin, 'click', onClick);
					mouseOverId = Microsoft.Maps.Events.addHandler(
						pin,
						'mouseover',
						mouseOver
					);
					// READ : don't add remove handler for this function because it messes up functionality
					Microsoft.Maps.Events.addHandler(pin, 'mouseout', mouseOut);
				}
			} catch (error) {
				// weird bing maps error, just log error for now
				// eslint-disable-next-line no-console
				console.log(error);
				map = undefined;
			}
		}
		return () => {
			if (map && pin) {
				map.entities.remove(pin);
				Microsoft.Maps.Events.removeHandler(clickId);
				Microsoft.Maps.Events.removeHandler(mouseOverId);
			}
		};
	}, dependencies);

	return null;
};

export default BingMarker;
