import React, { SVGProps, useMemo, useState } from 'react';
import { Sector, SectorProps, Surface, Symbols } from 'recharts';

import useGetGameHighlightsQuery from '../../../../api/analytics/queries/useGetGameHighlightsQuery';
import SelectedSquareIcon from '../../../../icons/SelectedSquareIcon.svg';
import SquareIcon from '../../../../icons/SquareIcon.svg';

const regions: { [key: string]: number } = {
	'Long Left': 0,
	'Long Right': 1,
	'Short Left': 2,
	'Short Right': 3,
};

interface Props {
	eventId: number;
	roundId: number;
	holeNumber: number;
}

const useGameHighlightsModal = ({ eventId, roundId, holeNumber }: Props) => {
	const { gameHighlights, refetchRound, ...rest } = useGetGameHighlightsQuery({
		eventId,
		roundId,
		holeNumber,
	});

	const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

	const squares = useMemo(() => {
		const regionNumber = regions[gameHighlights?.trendingRegion?.name ?? ''];

		const newSquares: JSX.Element[] = [];
		for (let i = 0; i < 4; i++) {
			const icon = i === regionNumber ? SelectedSquareIcon : SquareIcon;

			newSquares.push(
				<img src={icon} key={`${i} square icon`} alt={`${i} square icon`} />
			);
		}

		return newSquares;
	}, [gameHighlights?.regions]);

	const shotLocationData = useMemo(() => {
		if (activeIndex === undefined) {
			return gameHighlights?.shotLocations.shotData;
		}
		return gameHighlights?.shotLocations.changeShotDataColor(activeIndex);
	}, [activeIndex, gameHighlights?.shotLocations]);

	const renderActiveShape = ({
		payload,
		cx,
		cy,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
	}: SVGProps<SVGPathElement> &
		SectorProps & { payload: { percentage: number; name: string } }) => (
		<g>
			<text
				x={cx}
				y={cy}
				dy={-1}
				textAnchor='middle'
				fill={fill}
				capHeight={2.5}
				fontSize={40}
				fontWeight={400}
				fontFamily='Bebas Neue'
			>
				{payload.percentage}%
			</text>
			<text x={cx} y={cy} dy={24} textAnchor='middle' fill='#737373'>
				{payload.name}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
		</g>
	);

	const renderCustomizedLegend = () => (
		<div className='grid grid-cols-3 gap-y-[1rem] justify-items-center pt-[3rem]'>
			{shotLocationData?.map(item => (
				<span className='flex items-start' key={item.name}>
					<Surface width={15} height={15}>
						<Symbols
							cx={7.5}
							cy={7.5}
							type='square'
							size={150}
							fill={item.defaultFill}
						/>
					</Surface>
					<div className='flex flex-col pl-[4px] pt-[2px]'>
						<span className='legend-name'>{item.name}</span>
						<span>
							{item.value}/{gameHighlights?.shotsTaken} shots
						</span>
					</div>
				</span>
			))}
		</div>
	);

	return {
		data: {
			activeIndex,
			shotLocationData,
			trendingRegion: gameHighlights?.trendingRegion,
			squares,
			gameHighlights,
			error: rest.error,
		},
		handlers: {
			setActiveIndex,
			renderActiveShape,
			renderCustomizedLegend,
			refetchRound,
		},
	};
};

export default useGameHighlightsModal;
