import React, { useEffect, useMemo, useRef, useState } from 'react';

import useGetLeaderboardDetailsQuery from '../../../../api/challenges/queries/useGetLeaderboardDetailsQuery';
import useWebSocket from '../../../../api/web-socket/useWebSocket';
import LeaderboardParticipant from '../../../../domain/models/participant/leaderboard-participant';
import {
	getImageFromBucket,
	reverseCamelCase,
	toCamelCase,
} from '../../../shared/helper-functions';
import useGetMapParams from '../../../shared/hooks/useGetMapParams';
import useInterval from '../../../shared/hooks/useInterval';
import preEventConfig from './preEventConfig.json';

export interface PreEventDescriptions {
	title: string;
	location: string;
	times: {
		type: string | null;
		time: string;
	}[];
}

export interface PreEventData {
	title: string | null;
	descriptions: PreEventDescriptions[];
	extra: string;
}

export interface PreEventConfig {
	date: string;
	data: PreEventData[];
}

const useLeaderboardLandingPage = () => {
	const { eventId, roundId, holeNumber } = useGetMapParams();

	const leaderboardRef = useRef<HTMLDivElement>(null);

	const { leaderboardDetails, refetchLeaderboard } =
		useGetLeaderboardDetailsQuery(eventId, roundId, holeNumber);

	// ********************* TODO : change back to all categories after event
	const [headerCategories, setHeaderCategories] = useState<string[]>([
		'Overall Standings',
		'Amateur Men',
		'Amateur Women',
		'Junior Men',
		'Junior Women',
		'Current Group',
	]);

	const [isCurrentSectionChanging, setIsCurrentSectionChanging] =
		useState<boolean>(false);

	const [filteredPlayers, setFilteredPlayers] = useState<
		LeaderboardParticipant[]
	>([]);

	const [preEventDetails, setPreEventDetails] = useState<PreEventConfig[]>([]);

	const showBeforeEventPage = useMemo(() => {
		const paths = window.location.pathname.split('/');
		const showBefore = paths.pop()?.toLowerCase() === 'before';

		if (showBefore) {
			setHeaderCategories(
				Object.keys(preEventConfig).map(x => reverseCamelCase(x))
			);
		}

		return showBefore;
	}, []);

	// only connect to web sockets for leaderboard page, not before event page
	const webSocketMessages = useWebSocket(
		showBeforeEventPage ? [] : ['strokes', 'participants'],
		refetchLeaderboard
	);

	// This extra delay is to give the page enough time to load up the player cards because they have animation delays attached to them
	// In order to calculate animationDelay, just go to LeaderCard.scss file, multiple the delay by the number of cards shown on the page times a 1000
	// for example, if each card has a delay of 1 second, and there are 5 cards shown on the screen, then the animation delay would be : 1 * 5 * 1000 = 5000
	const timerIntervals = {
		betweenSections: showBeforeEventPage ? 21750 : 23000,
		animationDelay: 0,
		fadeOutDelay: showBeforeEventPage ? 2000 : 1000,
	};

	const totalTimeBetweenSection = Object.values(timerIntervals).reduce(
		(a, b) => a + b,
		0
	);

	// need to refetch leaderboard results after new websocket message has been received, which means either a new player has been checked in or a new stroke has been added
	useEffect(() => {
		refetchLeaderboard();
	}, [webSocketMessages]);

	// Everytime the interval is done running, it will switch this value to true which will trigger the animations to fade-out the headers
	useInterval(() => {
		setIsCurrentSectionChanging(true);
	}, totalTimeBetweenSection);

	// once this value is true, start 2 second timer to switch the order of the categories to get a new current section
	useEffect(() => {
		if (isCurrentSectionChanging) {
			const timeoutId = setTimeout(() => {
				setFilteredPlayers([]);

				const firstElement = headerCategories[0];
				const newHeaderCategories = headerCategories.slice(1);
				newHeaderCategories.push(firstElement);
				setHeaderCategories(newHeaderCategories);
				if (leaderboardRef.current) {
					leaderboardRef.current.scrollTop = 0;
				}
			}, timerIntervals.fadeOutDelay);

			return () => clearTimeout(timeoutId);
		}
		return () => {};
	}, [isCurrentSectionChanging]);

	const [currentSection, upcomingSections] = useMemo(() => {
		const newUpcomingSections = headerCategories
			.slice(1)
			.map(category => <div key={category}>{category.toUpperCase()}</div>);

		return [headerCategories[0].toUpperCase(), newUpcomingSections];
	}, [headerCategories]);

	// return data that are apart of current section
	useEffect(() => {
		if (leaderboardDetails && !showBeforeEventPage) {
			setFilteredPlayers(
				leaderboardDetails[
					toCamelCase(currentSection) as keyof typeof leaderboardDetails
				]
			);
		} else if (showBeforeEventPage) {
			setPreEventDetails(
				preEventConfig[
					toCamelCase(currentSection) as keyof typeof preEventConfig
				] as PreEventConfig[]
			);
		} else {
			setFilteredPlayers([]);
		}

		// need to set this value to false so that it loads in data with fade-in animation
		setIsCurrentSectionChanging(false);
	}, [leaderboardDetails, currentSection, showBeforeEventPage]);

	useEffect(() => {
		if (!showBeforeEventPage) {
			const playerSectionHeight = leaderboardRef.current?.clientHeight;
			const playerCardHeight =
				leaderboardRef.current?.children &&
				leaderboardRef.current?.children.length > 0
					? leaderboardRef.current?.children[0].clientHeight
					: undefined;

			let scrollFactor = 5;
			let numberOfScrolls = 1;
			if (playerSectionHeight && playerCardHeight) {
				scrollFactor = Math.floor(playerSectionHeight / playerCardHeight);
				// need to calculate number of scrolls needed to reach bottom of list so that we can figure out the time inbetween each scroll
				numberOfScrolls = Math.ceil(
					(playerCardHeight * filteredPlayers.length) / playerSectionHeight
				);
			}

			let index = 1;
			let interval: NodeJS.Timer;

			if (numberOfScrolls > 1) {
				interval = setInterval(() => {
					const scrollElement =
						leaderboardRef.current?.children[scrollFactor * index];
					if (scrollElement) {
						scrollElement.scrollIntoView({ behavior: 'smooth' });
						index++;
					} else {
						clearInterval(interval);
					}
				}, timerIntervals.betweenSections / numberOfScrolls);
			}
			return () => {
				clearInterval(interval);
			};
		}
		return () => {};
	}, [filteredPlayers, leaderboardRef, showBeforeEventPage]);

	return {
		currentSection,
		upcomingSections,
		leaderboardRef,
		filteredPlayers,
		isCurrentSectionChanging,
		showBeforeEventPage,
		preEventDetails,
		timerIntervals,
		videoUrl: getImageFromBucket(
			`videos/events/${eventId}/on-course-video.webm`
		),
	};
};

export default useLeaderboardLandingPage;
