import {
	DivisionType,
	StrokeLocation,
} from '../../../../domain/types/enum-types';
import LatLong from '../../../../domain/types/lat-long';

export default class PlayerStroke {
	constructor(
		public strokeId: number,
		public playerId: number,
		public firstName: string,
		public lastName: string,
		public division: DivisionType | null,
		public teamName: string,
		public teamId: number,
		public strokeTime: string,
		public strokeLocation: StrokeLocation,
		public ballDistance: number | null,
		public ballLocation: LatLong,
		public playerRankForHole: number,
		public shots?: number | null
	) {}

	static clone(playerStroke: PlayerStroke) {
		return new PlayerStroke(
			playerStroke.strokeId,
			playerStroke.playerId,
			playerStroke.firstName,
			playerStroke.lastName,
			playerStroke.division,
			playerStroke.teamName,
			playerStroke.teamId,
			playerStroke.strokeTime,
			playerStroke.strokeLocation,
			playerStroke.ballDistance,
			playerStroke.ballLocation,
			playerStroke.playerRankForHole,
			playerStroke.shots
		);
	}

	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}
}
