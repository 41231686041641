import FullRoundParticipant from '../participant/full-round-participant';
import Group from './group';

export default class FullRoundGroup implements Group {
	constructor(
		public id: number,
		public name: string,
		public participants: FullRoundParticipant[]
	) {}
}
