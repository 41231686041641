import Distance from '../types/distance';
import { StrokeLocation } from '../types/enum-types';
import LatLong from '../types/lat-long';

export default class Stroke {
	constructor(
		public id: number,
		public participantId: number,
		public strokeNumber: number,
		public courseLocation: StrokeLocation,
		public strokeTime: string,
		public ballTeeHeading: number,
		public ballHoleHeading: number,
		public ballLocation: LatLong,
		public teeDistance: Distance,
		public shotDistance: Distance,
		public holeDistance: Distance,
		public driveDistance: Distance,
		public teeLocationId?: number
	) {}
}
