import alphaNumericSort from '../../features/shared/helper-functions';
import Challenge from './challenge';

export default class EventDetails {
	constructor(
		public eventId: number,
		public eventName: string,
		public eventDescription: string,
		public venueName: string,
		public eventLocation: string,
		public eventStartDate: string,
		public eventEndDate: string,
		public challenges: Challenge[]
	) {}

	get challengesSorted() {
		return [
			...this.challenges.sort((p1, p2) => alphaNumericSort(p1.name, p2.name)),
		];
	}
}
