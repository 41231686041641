import { GeoPosition } from 'geo-position.ts';

import Stroke from '../../../../domain/models/stroke';
import LatLong from '../../../../domain/types/lat-long';

export default class DistanceToPinService {
	static convertMetersToFeet(distance: number) {
		return distance * 3.28084;
	}

	/**
	 *
	 * @param pinLocation
	 * @param strokesList
	 * @returns number[]
	 * Calculate distances in METERS to pin of array of shot locations
	 */
	static calculateDistanceToPin(pinLocation: LatLong, strokesList: Stroke[]) {
		const result = strokesList.map((stroke: Stroke) => {
			const pin = new GeoPosition(pinLocation.latitude, pinLocation.longitude);
			const ball = new GeoPosition(
				stroke.ballLocation.latitude,
				stroke.ballLocation.longitude
			);
			return ball.Distance(pin);
		});

		return result;
	}

	static calculateAvgDistanceToPin(
		pinLocation: LatLong,
		strokesList: Stroke[]
	) {
		if (strokesList.length > 0) {
			const summedDistances = DistanceToPinService.calculateDistanceToPin(
				pinLocation,
				strokesList
			).reduce((prevVal: number, currentVal: number) => prevVal + currentVal);

			return summedDistances / strokesList.length;
		}
		return 0;
	}
}
