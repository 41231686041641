/* eslint-disable import/prefer-default-export */
import FullRoundGroup from '../../../domain/models/group/full-round-group';
import PinPointGroup from '../../../domain/models/group/pin-point-group';
import { SlFullRoundChallengeGroup, SlPinPointChallengeGroup } from '../schema';
import { toFullRoundParticipant, toPinPointParticipant } from './toParticipant';

export const toPinPointGroup = (
	slPinPointChallengeGroup: SlPinPointChallengeGroup
) =>
	new PinPointGroup(
		slPinPointChallengeGroup.id,
		slPinPointChallengeGroup.name,
		slPinPointChallengeGroup.participants.map(toPinPointParticipant)
	);

export const toFullRoundGroup = (
	slFullRoundChallengeGroup: SlFullRoundChallengeGroup
) =>
	new FullRoundGroup(
		slFullRoundChallengeGroup.id,
		slFullRoundChallengeGroup.name,
		slFullRoundChallengeGroup.participants.map(toFullRoundParticipant)
	);
