import React, { SVGProps } from 'react';

const Hamburgericon = ({
	fill,
	width,
	height,
	...rest
}: SVGProps<SVGSVGElement>) => (
	<svg
		width={width ?? '22'}
		height={height ?? '15'}
		viewBox='0 0 22 15'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M0.916657 15C0.651845 15 0.432866 14.9115 0.259719 14.7344C0.0865732 14.5573 0 14.3333 0 14.0625C0 13.7917 0.0865732 13.5677 0.259719 13.3906C0.432866 13.2135 0.651845 13.125 0.916657 13.125H21.0831C21.3479 13.125 21.5669 13.2135 21.74 13.3906C21.9132 13.5677 21.9998 13.7917 21.9998 14.0625C21.9998 14.3333 21.9132 14.5573 21.74 14.7344C21.5669 14.9115 21.3479 15 21.0831 15H0.916657ZM0.916657 8.4375C0.651845 8.4375 0.432866 8.34896 0.259719 8.17188C0.0865732 7.99479 0 7.77083 0 7.5C0 7.22917 0.0865732 7.00521 0.259719 6.82812C0.432866 6.65104 0.651845 6.5625 0.916657 6.5625H21.0831C21.3479 6.5625 21.5669 6.65104 21.74 6.82812C21.9132 7.00521 21.9998 7.22917 21.9998 7.5C21.9998 7.77083 21.9132 7.99479 21.74 8.17188C21.5669 8.34896 21.3479 8.4375 21.0831 8.4375H0.916657ZM0.916657 1.875C0.651845 1.875 0.432866 1.78646 0.259719 1.60937C0.0865732 1.43229 0 1.20833 0 0.9375C0 0.666667 0.0865732 0.442708 0.259719 0.265625C0.432866 0.0885418 0.651845 0 0.916657 0H21.0831C21.3479 0 21.5669 0.0885418 21.74 0.265625C21.9132 0.442708 21.9998 0.666667 21.9998 0.9375C21.9998 1.20833 21.9132 1.43229 21.74 1.60937C21.5669 1.78646 21.3479 1.875 21.0831 1.875H0.916657Z'
			fill='#003865'
		/>
	</svg>
);

export default Hamburgericon;
