import { TouchEvent, useState } from 'react';

const useSwipe = (onSwipeLeft: () => void, onSwipeRight: () => void) => {
	const [touchPosition, setTouchPosition] = useState<number | null>(null);

	const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
		const touchDown = e.touches[0].clientX;
		setTouchPosition(touchDown);
	};

	const onTouchMove = (e: TouchEvent<HTMLDivElement>) => {
		const touchDown = touchPosition;

		if (touchDown === null) {
			return;
		}

		const currentTouch = e.touches[0].clientX;
		const diff = touchDown - currentTouch;

		if (diff > 5) {
			onSwipeLeft();
		}

		if (diff < -5) {
			onSwipeRight();
		}

		setTouchPosition(null);
	};

	return {
		onTouchStart,
		onTouchMove,
	};
};

export default useSwipe;
