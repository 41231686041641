import { useMemo } from 'react';

import LatLong from '../../../../domain/types/lat-long';
import { useAppSelector } from '../../../../redux/store';
import PlayerStroke from '../models/player-stroke';
import ClusteringService from '../services/ClusteringService';

const useGetClusters = (
	playerStrokes?: PlayerStroke[],
	originLocation?: LatLong
) => {
	const { zoomLevel, sortOption } = useAppSelector(state => state.ctp);

	return useMemo(() => {
		if (playerStrokes) {
			const clusters = ClusteringService.findClusters(
				playerStrokes,
				zoomLevel,
				originLocation || { latitude: 0, longitude: 0 }
			);

			return ClusteringService.sortClusters(clusters, sortOption);
		}

		return [];
	}, [playerStrokes, zoomLevel, sortOption, originLocation]);
};

export default useGetClusters;
