import React from 'react';

import { ViewOption } from '../../../../../domain/types/enum-types';
import { useAppSelector } from '../../../../../redux/store';
import './SearchInput.scss';

interface Props {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeHolder?: string;
}

const SearchInput = ({ onChange, placeHolder }: Props) => {
	const { shotViewOption, searchValue } = useAppSelector(({ ctp }) => ctp);

	return (
		<input
			className='search__input flex-none'
			type='text'
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				onChange(e);
			}}
			placeholder={
				placeHolder ??
				(shotViewOption === ViewOption.playerView
					? 'Search Players'
					: 'Search Teams')
			}
			value={searchValue}
		/>
	);
};

export default SearchInput;
