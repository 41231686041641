import MarketingDetails, {
	Prize,
} from '../../../domain/models/marketing-details';
import { SlMarketingDetails, SlPrize } from '../schema';

const toPrize = (slPrize: SlPrize) =>
	new Prize(
		slPrize.id,
		slPrize.marketingInfoId,
		slPrize.title,
		slPrize.description,
		slPrize.imagePath
	);

const toMarketingDetails = (slMarketingDetails: SlMarketingDetails) =>
	new MarketingDetails(
		slMarketingDetails.id,
		slMarketingDetails.eventId,
		slMarketingDetails.title,
		slMarketingDetails.subtitle,
		slMarketingDetails.heroImagePath,
		slMarketingDetails.linkText,
		slMarketingDetails.linkUrl,
		slMarketingDetails.prizeText,
		slMarketingDetails.prizeDetailsLink,
		slMarketingDetails.rules,
		slMarketingDetails.prizes.map(x => toPrize(x)),
		slMarketingDetails.showImages
	);

export default toMarketingDetails;
