import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
	name: 'app',
	initialState: {
		isNavigationOpen: false,
	},
	reducers: {
		closeNavigation: state => {
			state.isNavigationOpen = false;
		},
		openNavigation: state => {
			state.isNavigationOpen = true;
		},
	},
});

export const { closeNavigation, openNavigation } = appSlice.actions;

export default appSlice.reducer;
