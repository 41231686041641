import React, { forwardRef, useEffect, useState } from 'react';

import {
	PreEventConfig,
	PreEventDescriptions,
} from '../LeaderboardLandingPage/useLeaderboardLandingPage';
import './PreEventDetails.scss';

interface Props {
	details: PreEventConfig[];
	timerIntervals: { [key: string]: number };
}

const PreEventDetails = forwardRef(
	(
		{ details, timerIntervals }: Props,
		ref: React.ForwardedRef<HTMLDivElement>
	) => {
		const [eventDetails, setEventDetails] = useState<PreEventConfig>();
		const [eventDetailsIndex, setEventDetailsIndex] = useState<number>(0);

		const totalTimeBetweenSection = Object.values(timerIntervals).reduce(
			(a, b) => a + b,
			0
		);

		useEffect(() => {
			if (details.length > 0 && totalTimeBetweenSection) {
				setEventDetails(details[0]);

				let index = 1;
				const interval = setInterval(() => {
					setEventDetailsIndex(index);

					index++;
					if (index === details.length) {
						clearInterval(interval);
					}
				}, totalTimeBetweenSection / details.length);

				return () => clearInterval(interval);
			}
			return () => {};
		}, [details, totalTimeBetweenSection]);

		// Set a timer to allow for fade in/out animation of data
		useEffect(() => {
			if (eventDetailsIndex > 0) {
				const timeoutId = setTimeout(() => {
					setEventDetails(details[eventDetailsIndex]);

					if (ref !== null && typeof ref !== 'function' && ref.current) {
						ref.current.scrollTop = 0;
					}

					setEventDetailsIndex(0);
				}, timerIntervals.fadeOutDelay / 2);

				return () => clearTimeout(timeoutId);
			}
			return () => {};
		}, [eventDetailsIndex]);

		// If the data has overflow, then need to scroll through the rest of the div halfway through the timer
		useEffect(() => {
			if (
				ref !== null &&
				typeof ref !== 'function' &&
				ref.current &&
				ref.current.scrollHeight > ref.current.clientHeight
			) {
				const timeoutId = setTimeout(() => {
					ref.current?.scroll({
						top: ref.current?.clientHeight ?? 0,
						behavior: 'smooth',
					});
				}, totalTimeBetweenSection / details.length / 2);

				return () => clearTimeout(timeoutId);
			}
			return () => {};
		}, [eventDetails, ref]);

		const timeList = (description: PreEventDescriptions) => (
			<ul>
				{description.times.map((time, timeIndex) => (
					<li key={`${time.time} ${timeIndex.toString()}`}>
						<b>{time.type ?? ''}</b> {time.type ? ` : ${time.time}` : time.time}
					</li>
				))}
			</ul>
		);

		return eventDetails ? (
			<div
				className={`marketing-leaderboard__pre-event-details ${
					eventDetailsIndex > 0 ? 'fade-out' : 'fade-in'
				}`}
			>
				<div
					className='marketing-leaderboard__divider'
					style={{ position: 'sticky', top: '0' }}
				>
					<span>{eventDetails.date.toUpperCase()}</span>
				</div>
				{eventDetails.data.map(detail => (
					<div
						className='marketing-leaderboard__pre-event-details__card'
						key={detail.title}
					>
						{detail.title && (
							<>
								<div className='marketing-leaderboard__pre-event-details__card__title'>
									{detail.title}
								</div>
								<div className='marketing-leaderboard__pre-event-details__card__descriptions'>
									<ul>
										{detail.descriptions.map((description, index) => (
											<div
												key={`${description.title} ${index.toString()}`}
												style={{
													marginBottom:
														detail.descriptions.length > 1 &&
														index < detail.descriptions.length - 1
															? '1em'
															: '',
												}}
											>
												<li>
													{index !== 0 ? (
														<b>{description.title}</b>
													) : (
														description.title
													)}{' '}
													{description.location
														? ` : ${description.location}`
														: ''}
												</li>
												<li className='no-bullet'>{timeList(description)}</li>
											</div>
										))}
									</ul>
								</div>
							</>
						)}
						{!detail.title && (
							<>
								{detail.descriptions.map((description, index) => (
									<div key={`${description.title} ${index.toString()}`}>
										<div className='marketing-leaderboard__pre-event-details__card__title'>
											{description.title}
										</div>
										<div className='marketing-leaderboard__pre-event-details__card__descriptions'>
											{timeList(description)}
										</div>
									</div>
								))}
							</>
						)}
						<div
							className='marketing-leaderboard__pre-event-details__card__extra'
							style={{
								fontSize: detail.descriptions.length === 0 ? '1.5em' : '1em',
								marginTop: detail.descriptions.length === 0 ? '' : '0.8em',
							}}
						>
							{detail.extra}
						</div>
					</div>
				))}
			</div>
		) : null;
	}
);

export default PreEventDetails;
