import TeeLocation from '../../../domain/models/tee-location';
import { SlTeeLocation } from '../schema';

const toTeeLocation = (slTeeLocation: SlTeeLocation) =>
	new TeeLocation(
		slTeeLocation.id,
		slTeeLocation.holeConfigId,
		slTeeLocation.location,
		slTeeLocation.type,
		slTeeLocation.holeTeeHeading,
		slTeeLocation.holeTeeDistance
	);

export default toTeeLocation;
