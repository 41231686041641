import ChallengeType from '../../types/challenge-type';
import Hole from '../hole';
import LongestDriveTeeLocation from '../longest-drive-tee-location';

export default class LongestDriveChallengeDetails {
	constructor(
		public challengeType: ChallengeType,
		public hole: Hole,
		public teeLocations: LongestDriveTeeLocation[]
	) {}
}
