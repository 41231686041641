import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import { StrokeLocation } from '../../../../domain/types/enum-types';
import LeaderCard from '../LeaderCard/LeaderCard';
import PreEventDetails from '../PreEventDetails/PreEventDetails';
import './LeaderboardLandingPage.scss';
import useLeaderboardLandingPage from './useLeaderboardLandingPage';

const LandingPage = () => {
	const {
		currentSection,
		upcomingSections,
		leaderboardRef,
		filteredPlayers,
		isCurrentSectionChanging,
		showBeforeEventPage,
		preEventDetails,
		timerIntervals,
		videoUrl,
	} = useLeaderboardLandingPage();

	const [isVideoError, setIsVideoError] = useState<boolean>(!videoUrl);

	return (
		<section style={{ fontSize: '16px' }}>
			<section className='marketing-leaderboard-landing-page'>
				<div className='leaderboard-header'>
					<div className='leaderboard-header__current'>
						<span
							className={`${isCurrentSectionChanging ? 'fade-out' : 'fade-in'}`}
						>
							{currentSection}
						</span>
					</div>
					<div className='leaderboard-header__upcoming'>
						<div
							className={`${isCurrentSectionChanging ? 'fade-out' : 'fade-in'}`}
						>
							{upcomingSections}
						</div>
					</div>
				</div>
				<div className='leaderboard-content'>
					{!showBeforeEventPage && (
						<div
							className={`leaderboard-content__cards ${
								isCurrentSectionChanging ? 'fade-out' : ''
							}`}
							ref={leaderboardRef}
						>
							{filteredPlayers.length > 0 &&
								filteredPlayers.map((x, index) => {
									const isShotOnGreen = (i: number) =>
										i < filteredPlayers.length &&
										(filteredPlayers[i].courseLocation ===
											StrokeLocation.green ||
											filteredPlayers[i].courseLocation ===
												StrokeLocation.hole);

									const isNextShotValid = (i: number) =>
										i < filteredPlayers.length &&
										!!filteredPlayers[i].distanceToHole;

									return (
										<LeaderCard
											key={x.id}
											player={x}
											rank={index + 1}
											showDivision={
												!currentSection.toLowerCase().includes('amateur') &&
												!currentSection.toLowerCase().includes('junior')
											}
											shotsOnGreen={{
												currentShot: isShotOnGreen(index),
												nextShot: isShotOnGreen(index + 1),
												isNextShotNull:
													isNextShotValid(index) && !isNextShotValid(index + 1),
											}}
										/>
									);
								})}
							{filteredPlayers.length === 0 && (
								<div className='leaderboard-content__cards__no-players'>
									No shots have been recorded yet
								</div>
							)}
						</div>
					)}
					{showBeforeEventPage && (
						<div
							className={`leaderboard-content__cards ${
								isCurrentSectionChanging ? 'fade-out' : 'fade-in'
							}`}
							ref={leaderboardRef}
							style={{ marginTop: '0' }}
						>
							<PreEventDetails
								details={preEventDetails}
								timerIntervals={timerIntervals}
								ref={leaderboardRef}
							/>
						</div>
					)}
					<div className='leaderboard-content__social'>
						<div style={{ height: '100%' }}>
							<div className='leaderboard-content__social__video'>
								{!isVideoError && (
									<ReactPlayer
										url={videoUrl ?? ''}
										muted
										playing
										loop
										width='100%'
										height='100%'
										onError={() => {
											setIsVideoError(true);
										}}
									/>
								)}
								{isVideoError && <span>MASTER THE MOMENT</span>}
							</div>
						</div>
					</div>
				</div>
			</section>
		</section>
	);
};

export default LandingPage;
