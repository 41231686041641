import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';

import SessionStorageService from '../../../../api/SessionStorageService';
import useGetEventsQuery from '../../../../api/common/queries/useGetEventsQuery';
import useGetOrgDetailsQuery from '../../../../api/common/queries/useGetOrgDetailsQuery';
import ChevronUpIcon from '../../../../icons/ChevronUpIcon';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { runSearch } from '../../../challenges/challenge-reducer';
import SearchInput from '../../../challenges/shared/components/SearchInput/SearchInput';
import EventDetailsCard from './EventDetailsCard';
import './EventsList.scss';
import setHeroImages from './setHeroImages';

const EventsList = () => {
	const { events, isLoading } = useGetEventsQuery();
	const { orgDetails } = useGetOrgDetailsQuery();

	const dispatch = useAppDispatch();
	const { searchValue } = useAppSelector(state => state.ctp);

	const [isCurrentEventsOpen, setIsCurrentEventsOpen] = useState<boolean>(true);
	const [isUpcomingEventsOpen, setIsUpcomingEventsOpen] =
		useState<boolean>(true);
	const [isPastEventsOpen, setIsPastEventsOpen] = useState<boolean>(true);

	const updatedEvents = useMemo(
		() =>
			setHeroImages(events)?.filter(x =>
				x.name.toLowerCase().includes(searchValue.toLowerCase())
			),
		[events, searchValue]
	);

	const ongoingEvents = updatedEvents?.filter(
		x =>
			x.startDate.startOf('day') <= DateTime.now().startOf('day') &&
			x.endDate.startOf('day') >= DateTime.now().startOf('day')
	);

	const upcomingEvents = updatedEvents?.filter(
		x => x.startDate.startOf('day') > DateTime.now().startOf('day')
	);

	const pastEvents = updatedEvents?.filter(
		x => x.endDate.startOf('day') < DateTime.now().startOf('day')
	);

	// need to reset session storage variables
	useEffect(() => {
		SessionStorageService.showMarketingModal = 'true';
		SessionStorageService.showOfferPopup = 'true';
		dispatch(runSearch(''));
	}, []);

	return (
		<section className='manage-events'>
			<div className='events-list'>
				<h1 className='events-list__title'>
					{orgDetails?.title ?? 'Sports Experience'} Challenge Events
				</h1>

				{!isLoading && (
					<div className='events-list__events'>
						{((updatedEvents && updatedEvents.length >= 8) ||
							searchValue !== '') && (
							<div className='events-list__events--search'>
								<SearchInput
									onChange={e => dispatch(runSearch(e.target.value))}
									placeHolder='Search for an event'
								/>
								{searchValue.length > 0 && (
									<button type='button' onClick={() => dispatch(runSearch(''))}>
										Clear
									</button>
								)}
							</div>
						)}
						{/* Current */}
						<div className='events-list__events--ongoing'>
							<button
								type='button'
								className='events-list__events--header'
								onClick={() => setIsCurrentEventsOpen(prev => !prev)}
							>
								<h3>Current Events</h3>
								<div className='icon-background'>
									<div className={!isCurrentEventsOpen ? 'inverted' : ''}>
										<ChevronUpIcon />
									</div>
								</div>
							</button>
							{isCurrentEventsOpen && ongoingEvents && (
								<div>
									{ongoingEvents.length > 0 &&
										ongoingEvents.map(event => (
											<div key={event.id} className='events-list__card-wrapper'>
												<EventDetailsCard event={event} />
											</div>
										))}
									{ongoingEvents.length === 0 && (
										<div className='no-results'>No results found.</div>
									)}
								</div>
							)}
						</div>
						{/* Upcoming */}
						<div className='events-list__events--upcoming'>
							<button
								type='button'
								className='events-list__events--header'
								onClick={() => setIsUpcomingEventsOpen(prev => !prev)}
							>
								<h3>Upcoming Events</h3>
								<div className='icon-background'>
									<div className={!isUpcomingEventsOpen ? 'inverted' : ''}>
										<ChevronUpIcon />
									</div>
								</div>
							</button>
							{isUpcomingEventsOpen && upcomingEvents && (
								<div>
									{upcomingEvents.length > 0 &&
										upcomingEvents.map(event => (
											<div key={event.id} className='events-list__card-wrapper'>
												<EventDetailsCard event={event} />
											</div>
										))}
									{upcomingEvents.length === 0 && (
										<div className='no-results'>No results found.</div>
									)}
								</div>
							)}
						</div>
						{/* Past */}
						<div className='events-list__events--past'>
							<button
								type='button'
								className='events-list__events--header'
								onClick={() => setIsPastEventsOpen(prev => !prev)}
							>
								<h3>Past Events</h3>
								<div className='icon-background'>
									<div className={!isPastEventsOpen ? 'inverted' : ''}>
										<ChevronUpIcon />
									</div>
								</div>
							</button>
							{isPastEventsOpen && pastEvents && (
								<div>
									{pastEvents.length > 0 &&
										pastEvents.map(event => (
											<div key={event.id} className='events-list__card-wrapper'>
												<EventDetailsCard event={event} />
											</div>
										))}
									{pastEvents.length === 0 && (
										<div className='no-results'>No results found.</div>
									)}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export default EventsList;
