import classNames from 'classnames';
import React, { useMemo } from 'react';

import ChevronUpIcon from '../../../../../icons/ChevronUpIcon';
import PlayerClusterIcon from '../../../../../icons/PlayerClusterIcon.svg';
import Team from '../../models/team';
import './TeamDropdownLeaderCard.scss';
import useDropdownLeaderCard from './useDropdownLeaderCard';

interface Props {
	children: React.ReactNode;
	teamData: Team;
}
const TeamDropdownLeaderCard = ({ children, teamData }: Props) => {
	const {
		dropdownRef,
		isClusterSelected,
		isSectionSelected,
		isSelectedStrokeInCluster,
		isDropdownOpen,
		dropdownHeight,
		toggleDropdown,
	} = useDropdownLeaderCard(teamData.cluster);
	return (
		<section
			className={`w-full mb-[0.375rem] bg-white rounded-[6px] leader-card ${
				isClusterSelected ? 'selected' : ''
			}`}
			style={{
				border: isSectionSelected
					? '2px solid rgba(0, 56, 101, 0.3)'
					: '1px solid #EDEDED',
				boxShadow: '4px 4px 20px rgba(0,0,0,0.07)',
			}}
		>
			<div
				className='leader-card__inner h-16'
				onKeyDown={toggleDropdown}
				onClick={toggleDropdown}
				role='button'
				tabIndex={0}
			>
				<div
					className={classNames(
						'mr-[1rem] rounded-full border-[4px] font-sans font-bold leadercard__rank-number',
						{
							'border-[#F8F8F8]': !isSectionSelected,
							'border-[#eaedc2]': isSectionSelected,
							'bg-electric-green': isSectionSelected,
						}
					)}
				>
					<img src={PlayerClusterIcon} alt='Player Cluster Icon' />
				</div>
				<div className='flex flex-col mr-[0.8125rem]'>
					<p
						className='text-[16px]'
						style={{
							fontWeight: isSectionSelected ? 'bold' : 'normal',
						}}
					>
						{teamData.playerStrokes[0].teamName}
					</p>
					<p className='text-text-gray text-[12px]'>
						{teamData.playerStrokes.length} Players
					</p>
				</div>
				<div className='ml-auto mr-[0.625rem]'>
					<div className='icon-background grey-icon'>
						<div className={!isDropdownOpen ? 'inverted' : ''}>
							<ChevronUpIcon />
						</div>
					</div>
				</div>
			</div>
			<div
				className='px-[0.5rem]'
				style={{
					height: dropdownHeight ? dropdownHeight + 2 : 0, // adjust for selected LeaderCard borders
					overflow: 'hidden',
					transition: useMemo(() => {
						if (isSelectedStrokeInCluster || isSectionSelected) {
							return 'none';
						}
						if (isDropdownOpen) return 'all 500ms ease';
						return 'all 200ms ease';
					}, [isDropdownOpen, isSectionSelected, isSelectedStrokeInCluster]),
					marginBottom: isDropdownOpen ? '0.75rem' : '0',
				}}
			>
				<div className=' pt-[.4rem]' ref={dropdownRef}>
					{children}
				</div>
			</div>
		</section>
	);
};

export default TeamDropdownLeaderCard;
