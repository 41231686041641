import LeaderboardParticipant from './participant/leaderboard-participant';

export default class Leaderboard {
	constructor(
		public currentGroup: LeaderboardParticipant[],
		public overallStandings: LeaderboardParticipant[],
		public amateurMen: LeaderboardParticipant[],
		public amateurWomen: LeaderboardParticipant[],
		public juniorMen: LeaderboardParticipant[],
		public juniorWomen: LeaderboardParticipant[]
	) {}
}
