import React from 'react';

import EventBanner from '../../../manage-events/components/EventBanner/EventBanner';
import WarningSection from '../WarningSection/WarningSection';
import './NotFoundPage.scss';

const NotFoundPage = () => (
	<section className='not-found flex-column'>
		<EventBanner>
			<h3>Page Not Found</h3>
		</EventBanner>
		<WarningSection>
			<p>
				The page event you are looking for could not be found for one of the
				following reasons:
			</p>
			<ul>
				<li>It may have been cancelled or changed</li>
				<li>The link may be incorrect or out of date</li>
			</ul>
			<p>Please contact the event coordinator for more information.</p>
		</WarningSection>
	</section>
);

export default NotFoundPage;
