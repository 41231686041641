import React from 'react';

import LinkedInIcon from '../../../../icons/LinkedInIcon';
import TwitterIcon from '../../../../icons/TwitterIcon';
import YouTubeIcon from '../../../../icons/YouTubeIcon';
import { closeNavigation } from '../../../../redux/app-reducer';
import { useAppDispatch } from '../../../../redux/store';
import useNavigateToPage from '../../hooks/useNavigateToPage';
import './Navigation.scss';
import useNavigation from './useNavigation';

interface Props {
	eventId: number;
}

const Navigation = ({ eventId }: Props) => {
	const {
		eventLinks,
		footerTitle,
		aboutUrl,
		contactUrl,
		linkedinUrl,
		twitterUrl,
		youtubeUrl,
	} = useNavigation(eventId);

	const dispatch = useAppDispatch();
	const navigateToPage = useNavigateToPage();

	return (
		<nav className='navigation'>
			{eventId > 0 && (
				<button
					type='button'
					onClick={() => {
						navigateToPage(`/events/${eventId}/marketing`);
						dispatch(closeNavigation());
					}}
					className='navigation__external-link'
				>
					More About This Event
				</button>
			)}
			<div className='navigation__list'>
				<div className='navigation__list'>
					<button
						type='button'
						className={!eventLinks?.some(x => x.isSelected) ? 'selected' : ''}
						onClick={() => {
							navigateToPage('/events');
							dispatch(closeNavigation());
						}}
					>
						All Events
					</button>
				</div>
				<ul>
					{eventLinks?.map(({ id, title, href, isSelected }) => (
						<li key={id}>
							<button
								type='button'
								className={isSelected ? 'selected' : ''}
								onClick={() => {
									navigateToPage(href);
									dispatch(closeNavigation());
								}}
							>
								{title}
							</button>
						</li>
					))}
				</ul>
			</div>
			{aboutUrl && (
				<a
					aria-label='About Url'
					target='_blank'
					rel='noreferrer'
					className='navigation__external-link'
					href={aboutUrl}
				>
					About {footerTitle}
				</a>
			)}
			{contactUrl && (
				<a
					aria-label='Contact Url'
					target='_blank'
					rel='noreferrer'
					className='navigation__external-link'
					href={contactUrl}
				>
					Contact
				</a>
			)}
			<div className='navigation__social-media-logo-container'>
				{linkedinUrl && (
					<a
						aria-label='LinkedIn Url'
						target='_blank'
						rel='noreferrer'
						href={linkedinUrl}
					>
						<LinkedInIcon width='32' height='32' fill='#003865' />
					</a>
				)}
				{twitterUrl && (
					<a
						aria-label='Twitter Url'
						target='_blank'
						rel='noreferrer'
						href={twitterUrl}
					>
						<TwitterIcon width='32' height='32' fill='#003865' />
					</a>
				)}
				{youtubeUrl && (
					<a
						aria-label='YouTube Url'
						target='_blank'
						rel='noreferrer'
						href={youtubeUrl}
					>
						<YouTubeIcon width='32' height='32' fill='#003865' />
					</a>
				)}
			</div>
		</nav>
	);
};

export default Navigation;
