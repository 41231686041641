import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import useGetOrgDetailsQuery from '../../../../api/common/queries/useGetOrgDetailsQuery';
import CloseOutIcon from '../../../../icons/CloseOutIcon';
import Hamburgericon from '../../../../icons/HamburgerIcon';
import CapTechSvg from '../../../../icons/captech-logo-icon.svg';
import { closeNavigation, openNavigation } from '../../../../redux/app-reducer';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { getImageFromBucket } from '../../helper-functions';
import useGetMapParams from '../../hooks/useGetMapParams';
import useWindowResize from '../../hooks/useWindowResize';
import Navigation from '../Navigation/Navigation';
import './AppHeader.scss';

const AppHeader = () => {
	const { eventId } = useGetMapParams(false);

	const isNavigationOpen = useAppSelector(({ app }) => app.isNavigationOpen);
	const { width } = useWindowResize();

	const burgerHeight = width < 1024 ? '10' : '15';
	const burgerWidth = width < 1024 ? '15' : '22';

	const { orgDetails } = useGetOrgDetailsQuery();

	const dispatch = useAppDispatch();

	// grab org img from s3 bucket if we are using shell app and not in a local environment
	const companyLogoUrl =
		getImageFromBucket('images/org-logo.png') ?? CapTechSvg;

	const [isEventImgError, setIsEventImgError] = useState<boolean>(false);

	// grab event img from s3 bucket if we are using shell app and not in a local environment
	const eventLogoUrl = useMemo(() => {
		setIsEventImgError(false);
		if (eventId > 0) {
			return getImageFromBucket(`images/events/${eventId}/event-logo.png`);
		}
		return null;
	}, [eventId]);

	return (
		<>
			<div id='app-header' className='app-header base-layout-margin'>
				<img src={companyLogoUrl} className='app-header__logo' alt='logo' />
				<p className='app-header__divider'>|</p>
				<p className='app-header__title'>
					{eventLogoUrl && !isEventImgError ? (
						<img
							src={eventLogoUrl}
							// if event image doesn't exist in S3 bucket, then just use org title
							onError={() => setIsEventImgError(true)}
							className='app-header__logo'
							alt='logo'
						/>
					) : (
						orgDetails?.title ?? 'SPORTS EXPERIENCE'
					)}
				</p>
				{!isNavigationOpen && (
					<button
						onClick={() => dispatch(openNavigation())}
						type='button'
						className={`ml-auto ${
							isNavigationOpen ? 'hidden' : 'inline-block'
						}`}
					>
						<Hamburgericon
							height={burgerHeight}
							width={burgerWidth}
							fill='#003865'
						/>
					</button>
				)}
				{isNavigationOpen && (
					<button
						onClick={() => dispatch(closeNavigation())}
						type='button'
						className={`ml-auto text-electric-green ${
							isNavigationOpen ? 'inline-block' : 'hidden'
						}`}
					>
						<CloseOutIcon fill='#003865' />
					</button>
				)}
			</div>
			<div
				className={classNames('app-header__nav-container', {
					'app-header__nav-close': !isNavigationOpen,
				})}
			>
				<Navigation eventId={eventId} />
			</div>
		</>
	);
};

export default AppHeader;
