import SortService from '../../../../domain/services/SortService';
import LatLong from '../../../../domain/types/lat-long';
import alphaNumericSort from '../../../shared/helper-functions';
import PlayerStroke from './player-stroke';

export default class Cluster {
	constructor(
		public orderId: number,
		public ballLocation: LatLong,
		public playerStrokes: PlayerStroke[],
		public clusterDistance: number | null
	) {}

	// Id is a list of all stroke ids included in the cluster
	get id() {
		return this.strokeIds.join(',');
	}

	get numberOfShots() {
		return this.playerStrokes.length;
	}

	get strokeIds() {
		return this.playerStrokes.map(x => x.strokeId);
	}

	static clone(cluster: Cluster) {
		return new Cluster(
			cluster.orderId,
			cluster.ballLocation,
			[...cluster.playerStrokes],
			cluster.clusterDistance
		);
	}

	includesStroke(strokeIds: number[]) {
		return this.playerStrokes.some(x => strokeIds.includes(x.strokeId));
	}

	sortPlayerStrokesDistance(isAscending: boolean) {
		this.playerStrokes.sort((a, b) =>
			SortService.sortStrokesByScore(
				a.ballDistance,
				b.ballDistance,
				isAscending
			)
		);
	}

	sortPlayerStrokesAlpha(isAscending: boolean) {
		if (isAscending) {
			this.playerStrokes.sort(
				(a, b) =>
					alphaNumericSort(a.firstName, b.firstName) ||
					alphaNumericSort(a.lastName, b.lastName)
			);
		} else {
			this.playerStrokes.sort(
				(a, b) =>
					alphaNumericSort(b.firstName, a.firstName) ||
					alphaNumericSort(b.lastName, a.lastName)
			);
		}
	}
}
