/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';

import FullRoundChallengeDetails from '../../../domain/models/challenge-details/full-round-challenge-details';
import LongestDriveChallengeDetails from '../../../domain/models/challenge-details/longest-drive-challenge-details';
import PinPointChallengeDetails from '../../../domain/models/challenge-details/pin-point-challenge-details';
import useNavigateToPage from '../../../features/shared/hooks/useNavigateToPage';
import challengeApi from '../challenges-api';
import toChallengeDetails from '../transforms/toChallengeDetails';

const useChallengeDetailsQuery = (
	eventId: number,
	roundId: number,
	holeNum: number
) => {
	const { data, ...rest } = challengeApi.useGetChallengeDetailsQuery(
		{
			eventId,
			roundId,
			holeNum,
		},
		{
			pollingInterval: 120 * 1000,
		}
	);

	const navigateToPage = useNavigateToPage();

	const urlPath = window.location.pathname;
	const challengeType = urlPath.split('/').slice(-1)[0];

	// if the data returned doesn't have the same challenge type as the one in the url, then need to navigate to the correct page
	// FYI : this if statement shouldn't apply very often. only when people are messing with the url
	if (data && challengeType !== data.challengeType.toLowerCase()) {
		// need a slight delay to allow components to fully load before rendering different components otherwise it throws error
		window.setTimeout(() => {
			const urlPathAndQuery = urlPath + window.location.search;
			navigateToPage(
				urlPathAndQuery.replace(challengeType, data.challengeType.toLowerCase())
			);
		}, 500);
	}

	return {
		challengeDetails: useMemo(
			() => (data ? toChallengeDetails(data) : undefined),
			[data]
		),
		...rest,
	};
};

// use this query to cast the data returned into the pin point challenge details object
export const usePinPointChallengeDetailsQuery = (
	eventId: number,
	roundId: number,
	holeNum: number
) => {
	const { challengeDetails, ...rest } = useChallengeDetailsQuery(
		eventId,
		roundId,
		holeNum
	);

	return {
		challengeDetails: challengeDetails as PinPointChallengeDetails,
		...rest,
	};
};

// use this query to cast the data returned into the full round challenge details object
export const useFullRoundChallengeDetailsQuery = (
	eventId: number,
	roundId: number,
	holeNum: number
) => {
	const { challengeDetails, ...rest } = useChallengeDetailsQuery(
		eventId,
		roundId,
		holeNum
	);

	return {
		challengeDetails: challengeDetails as FullRoundChallengeDetails,
		...rest,
	};
};

// use this query to cast the data returned into the longest drive challenge details object
export const useLongestDriveChallengeDetailsQuery = (
	eventId: number,
	roundId: number,
	holeNum: number
) => {
	const { challengeDetails, ...rest } = useChallengeDetailsQuery(
		eventId,
		roundId,
		holeNum
	);

	return {
		challengeDetails: challengeDetails as LongestDriveChallengeDetails,
		...rest,
	};
};
