import FullRoundChallengeDetails from '../../../domain/models/challenge-details/full-round-challenge-details';
import LongestDriveChallengeDetails from '../../../domain/models/challenge-details/longest-drive-challenge-details';
import PinPointChallengeDetails from '../../../domain/models/challenge-details/pin-point-challenge-details';
import ChallengeType from '../../../domain/types/challenge-type';
import {
	SlChallengeDetails,
	SlFullRoundChallengeDetails,
	SlLongestDriveChallengeDetails,
	SlPinPointChallengeDetails,
} from '../schema';
import { toFullRoundGroup, toPinPointGroup } from './toGroup';
import { toHole } from './toHole';
import toLongestDriveTeeLocation from './toLongestDriveTeeLocation';

const toChallengeDetails = (slChallengeDetails: SlChallengeDetails) => {
	if (
		slChallengeDetails.challengeType === ChallengeType.PinPoint ||
		slChallengeDetails.challengeType === ChallengeType.PinPointLeaderboard
	) {
		const slPinPointChallengeDetails =
			slChallengeDetails as SlPinPointChallengeDetails;
		return new PinPointChallengeDetails(
			slPinPointChallengeDetails.challengeType as ChallengeType,
			slPinPointChallengeDetails.groups.map(x => toPinPointGroup(x)),
			toHole(slPinPointChallengeDetails.hole),
			slPinPointChallengeDetails.averageDistance
		);
	}
	if (slChallengeDetails.challengeType === ChallengeType.LongestDrive) {
		const slLongestDriveChallengeDetails =
			slChallengeDetails as SlLongestDriveChallengeDetails;

		return new LongestDriveChallengeDetails(
			slLongestDriveChallengeDetails.challengeType as ChallengeType,
			toHole(slLongestDriveChallengeDetails.hole),
			slLongestDriveChallengeDetails.teeLocations.map(x =>
				toLongestDriveTeeLocation(x)
			)
		);
	}

	// if it's not pinpoint, pinpoint leaderboard, or longest drive, then it's full round
	const slFullRoundChallengeDetails =
		slChallengeDetails as SlFullRoundChallengeDetails;
	return new FullRoundChallengeDetails(
		slFullRoundChallengeDetails.challengeType as ChallengeType,
		slFullRoundChallengeDetails.groups.map(x => toFullRoundGroup(x)),
		toHole(slFullRoundChallengeDetails.hole)
	);
};

export default toChallengeDetails;
