import { useEffect, useMemo } from 'react';

import commonApi from '../common-api';
import toEvent from '../transforms/toEvent';

const useLazyGetEventQuery = (eventId: number) => {
	const [refetch, { data, ...rest }] = commonApi.useLazyGetEventQuery();

	useEffect(() => {
		if (eventId > 0) {
			refetch(eventId);
		}
	}, [eventId]);

	return {
		event: useMemo(
			() => (data && eventId > 0 ? toEvent(data) : undefined),
			[data, eventId]
		),
		...rest,
	};
};

export default useLazyGetEventQuery;
