import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '../api-config';
import { SlResponseArray, createGetQuery } from '../api-util';
import {
	SlEvent,
	SlMarketingDetails,
	SlMarketingModalDetails,
	SlOrgDetails,
	SlSponsorshipDetails,
} from './schema';

const commonApi = createApi({
	reducerPath: 'ctp/common-api',
	baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/common` }),
	endpoints: builder => ({
		getEvents: builder.query<SlResponseArray<SlEvent>, void>({
			query: () => createGetQuery(`events?size=1000&active=true`),
		}),
		getEvent: builder.query<SlEvent, number>({
			query: eventId => createGetQuery(`events/${eventId}`),
		}),
		getOrgDetails: builder.query<SlOrgDetails, void>({
			query: () => createGetQuery('organizations'),
		}),
		getMarketingDetails: builder.query<SlMarketingDetails, number>({
			query: eventId => createGetQuery(`events/${eventId}/marketing`),
		}),
		getMarketingModalDetails: builder.query<SlMarketingModalDetails, number>({
			query: eventId => createGetQuery(`events/${eventId}/marketing/bonus`),
		}),
		getSponsorshipDetails: builder.query<SlSponsorshipDetails[], number>({
			query: eventId => createGetQuery(`events/${eventId}/sponsorship`),
		}),
	}),
});

export default commonApi;
