import React, { useMemo } from 'react';

export interface DrawerTab {
	name: string;
	icon: (selected: boolean) => JSX.Element;
	component: JSX.Element;
}

interface Props {
	drawerTabs: DrawerTab[];
	selectedTab: DrawerTab;
	setSelectedTab: React.Dispatch<React.SetStateAction<DrawerTab>>;
	isWideScreen: boolean;
}

const DrawerTabs = ({
	drawerTabs,
	selectedTab,
	setSelectedTab,
	isWideScreen,
}: Props) => {
	const handleTabClick = (tab: DrawerTab) => {
		setSelectedTab(tab);
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLDivElement>,
		tab: DrawerTab
	) => {
		if (event.key === 'Enter' || event.key === ' ') {
			setSelectedTab(tab);
		}
	};

	const tabs = useMemo(
		() =>
			isWideScreen ? (
				<div className='flex flex-wrap text-sm font-medium text-center h-10'>
					{drawerTabs.map(tab => (
						<div
							key={tab.name}
							className={`space-x-2 flex flex-row justify-center items-center mr-1 bg-white inline-block p-[0.75rem] rounded-t-lg ${
								selectedTab.name === tab.name ? 'bg-white' : 'bg-[#EFEFEF]'
							}`}
							onClick={() => handleTabClick(tab)}
							onKeyDown={e => handleKeyDown(e, tab)}
							role='button'
							tabIndex={0}
						>
							{tab.icon(selectedTab.name === tab.name)}
							{selectedTab.name === tab.name && (
								<div
									className={`${
										selectedTab.name === tab.name
											? 'text-shotlanderBlue'
											: 'text-[#737373]'
									}`}
								>
									{tab.name}
								</div>
							)}
						</div>
					))}
				</div>
			) : (
				<div
					className='fixed bottom-0 flex w-screen h-12'
					style={{ border: '1px solid #BDBCBC' }}
				>
					{drawerTabs.map(tab => (
						<div
							key={tab.name}
							className='bg-white w-1/2 text-center py-2 flex flex-col justify-between'
							onClick={() => handleTabClick(tab)}
							onKeyDown={e => handleKeyDown(e, tab)}
							role='button'
							tabIndex={0}
						>
							{tab.icon(selectedTab.name === tab.name)}
							<div
								className={`${
									selectedTab.name === tab.name
										? 'text-shotlanderBlue'
										: 'text-[#737373]'
								} text-[10px] leading-none`}
							>
								{tab.name}
							</div>
						</div>
					))}
				</div>
			),
		[isWideScreen, selectedTab]
	);

	return (
		<section className='flex justify-between items-center z-10'>{tabs}</section>
	);
};

export default DrawerTabs;
