import SessionStorageService from '../../api/SessionStorageService';

const alphaNumericSort = (a: string, b: string) =>
	a.localeCompare(b, undefined, {
		numeric: true,
		sensitivity: 'base',
	});

export const lowerCaseFirstLetter = (value: string) =>
	value.charAt(0).toLowerCase() + value.slice(1);

export const capitalizeFirstLetter = (value: string) =>
	value.charAt(0).toUpperCase() + value.slice(1);

export const toCamelCase = (value: string) => {
	const regex = /\s+(\w)?/gi;
	return value
		.toLowerCase()
		.replace(regex, (match, letter) => letter.toUpperCase());
};

export const reverseCamelCase = (value: string) => {
	// put spaces between characters that have an upper case (break up camel case)
	const spacedString = value.replace(/([A-Z])/g, ' $1').trim();
	return capitalizeFirstLetter(spacedString);
};

export const getImageFromBucket = (path: string) =>
	SessionStorageService.shellAppDomain && process.env.REACT_APP_STAGE
		? `${SessionStorageService.shellAppDomain}/${path}`
		: null;

// https://dev.to/devtronic/javascript-map-an-array-of-objects-to-a-dictionary-3f42
export const arrayToDictionary = <T extends { id: number }>(
	array: T[]
): { [id: number]: T } =>
	Object.assign({}, ...array.map(x => ({ [x.id]: { ...x } })));

const createIdGenerator = () => {
	let id = 0;
	return function generateUniqueId() {
		return id++;
	};
};

/**
 * Formats date range from YYYY-MM-DD to a more readable format
 * @param startDate
 * @param endDate
 * @returns a string date in one of the following formats:
 * Mon DD, YYYY
 * Mon DD-DD, YYYY
 * Mon DD-Mon DD, YYYY
 * Mon DD, YYYY-Mon DD, YYYY
 */
export const formatDateRange = (startDate: string, endDate?: string) => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	const [startYear, startMonth, startDay] = startDate.split('-');
	if (endDate) {
		const [endYear, endMonth, endDay] = endDate.split('-');
		if (startYear === endYear) {
			if (startMonth === endMonth)
				if (startDay === endDay)
					return `${months[Number(startMonth) - 1]} ${startDay}, ${startYear}`;
				else
					return `${
						months[Number(startMonth) - 1]
					} ${startDay}-${endDay}, ${startYear}`;
			return `${months[Number(startMonth) - 1]} ${startDay}-${
				months[Number(endMonth) - 1]
			} ${endDay}, ${startYear}`;
		}
		return `${months[Number(startMonth) - 1]} ${startDay}, ${startYear}-${
			months[Number(endMonth) - 1]
		} ${endDay}, ${endYear}`;
	}
	return `${months[Number(startMonth) - 1]} ${startDay}, ${startYear}`;
};

export const generateUniqueId = createIdGenerator();

export default alphaNumericSort;
