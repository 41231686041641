import { useMemo } from 'react';

import { useFullRoundChallengeDetailsQuery } from '../../../../../api/challenges/queries/useGetChallengeDetailsQuery';
import Stroke from '../../../../../domain/models/stroke';
import {
	StrokeLocation,
	TeeType,
} from '../../../../../domain/types/enum-types';
import useModal from '../../../../shared/hooks/useModal';
import MapMarker from '../../../../shared/models/map-marker';
import Player from '../../models/player';
import useGetPlayers from '../../selectors/useGetPlayers';
import useGetSelectedPlayer from '../../selectors/useGetSelectedPlayer';

const useScatterplot = (eventId: number, roundId: number, holeNum: number) => {
	const { challengeDetails, isLoading, error, refetch } =
		useFullRoundChallengeDetailsQuery(eventId, roundId, holeNum);

	const players = useGetPlayers(challengeDetails, false);
	const selectedPlayer = useGetSelectedPlayer(players);

	const marketingModal = useModal('marketingModal');

	const mapMarkers = useMemo(
		() =>
			players?.flatMap(player =>
				player.strokes
					.filter(x => x.courseLocation !== StrokeLocation.hole)
					.map(
						(stroke: Stroke, i: number, row: Stroke[]) =>
							new MapMarker(
								player.strokeIds,
								stroke.ballLocation,
								player.fullName,
								i + 1 === row.length ? player.initials : undefined // if it's last shot for that player, then show initials
							)
					)
			),
		[players]
	);

	// need to update model return type to include boolean to show line or not. if set to false, then don't show line on map
	const shotLocations = (player: Player) => {
		const locations = [];
		// need to look at first stroke to see which tee box they hit from
		const teeId = player.strokes[0].teeLocationId;
		const teeLocation = teeId
			? challengeDetails?.hole.teeLocations.find(x => x.id === teeId)
			: challengeDetails?.hole.teeLocations.find(
					x => x.type === TeeType.default
			  );
		if (teeLocation) {
			locations.push(teeLocation.location);
		}
		locations.push(...player.strokes.map(x => x.ballLocation));

		return locations;
	};

	return {
		modal: {
			marketingModal,
		},
		data: {
			mapMarkers,
			pinLocation: challengeDetails?.hole.pinLocation || {
				latitude: -1,
				longitude: -1,
			},
			error,
			isLoading,
			refreshData: () => refetch(),
		},
		mapProps: {
			center: useMemo(
				() => ({
					latitude: challengeDetails?.hole.pinLocation.latitude || -1,
					longitude: challengeDetails?.hole.pinLocation.longitude || -1,
				}),
				[challengeDetails?.hole]
			),
			teeLocations: challengeDetails?.hole.teeLocations,
			panTo: {
				latitude:
					selectedPlayer?.strokes.slice(-1)[0].ballLocation.latitude || 0,
				longitude:
					selectedPlayer?.strokes.slice(-1)[0].ballLocation.longitude || 0,
			},
			fitBounds: useMemo(() => {
				if (selectedPlayer) return shotLocations(selectedPlayer);
				if (challengeDetails) {
					return [
						challengeDetails.hole.teeLocations.find(
							x => x.type === TeeType.default
						)?.location ?? { latitude: 0, longitude: 0 },
						challengeDetails.hole.pinLocation,
					];
				}
				return undefined;
			}, [players, selectedPlayer]),
			shotLines: useMemo(
				() =>
					players?.map(player => ({
						isSelected: player.id === selectedPlayer?.id,
						locations: shotLocations(player),
					})),
				[players, selectedPlayer]
			),
		},
	};
};

export default useScatterplot;
