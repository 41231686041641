import { useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import useDropdown from '../../../../shared/forms/Dropdown/useDropdown';
import {
	addSelectedCluster,
	clearAllSelected,
	removeSelectedCluster,
	selectStrokes,
} from '../../../challenge-reducer';
import Cluster from '../../models/cluster';

const useDropdownLeaderCard = (clusterData: Cluster) => {
	const { selectedClusterIds, selectedStrokeIds } = useAppSelector(
		state => state.ctp
	);

	const dispatch = useAppDispatch();

	const {
		openDropdown,
		dropdownHeight,
		closeDropdown,
		isDropdownOpen,
		dropdownRef,
	} = useDropdown();

	// Grouped into same memo because they share same deps
	const [isClusterSelected, isSelectedStrokeInCluster] = useMemo(
		() => [
			clusterData.playerStrokes.every(s =>
				selectedStrokeIds.includes(s.strokeId)
			),
			clusterData.includesStroke(selectedStrokeIds),
		],
		[JSON.stringify(clusterData.strokeIds), JSON.stringify(selectedStrokeIds)]
	);

	useEffect(() => {
		// Used in leadercard to signal that the entire cluster is selected and
		// individual cards inside that cluster should not be selected
		if (isClusterSelected) {
			dispatch(addSelectedCluster(clusterData.id));
		}

		// Remove cluster from selected list on unmount
		return () => {
			dispatch(removeSelectedCluster(clusterData.id));
		};
	}, [isClusterSelected]);

	useEffect(() => {
		// If marker is clicked on map, open the dropdown
		if (isSelectedStrokeInCluster) {
			openDropdown();
		}
		// If another dropdown is open and another marker is clicked, close
		else {
			closeDropdown();
		}
	}, [isSelectedStrokeInCluster]);

	return {
		dropdownRef,
		isClusterSelected,
		isSectionSelected: useMemo(() => {
			// When open, the dropdown header is only selected if all strokes are selected
			if (isDropdownOpen) {
				return isClusterSelected;
			}

			// When closed, the header is selected regardless of the number selected inside
			return isSelectedStrokeInCluster;
		}, [
			isDropdownOpen,
			JSON.stringify(clusterData.strokeIds),
			JSON.stringify(selectedStrokeIds),
		]),
		isDropdownOpen,
		dropdownHeight,
		isSelectedStrokeInCluster,
		toggleDropdown: useCallback(() => {
			if (
				selectedClusterIds.length === 1 &&
				selectedClusterIds.includes(clusterData.id) &&
				isDropdownOpen
			) {
				// Only close dropdown if its the only cluster selected and its open
				closeDropdown();
			} else {
				dispatch(clearAllSelected());
				dispatch(selectStrokes(clusterData.strokeIds));
				dispatch(addSelectedCluster(clusterData.id));
				openDropdown();
			}
		}, [
			isDropdownOpen,
			JSON.stringify(selectedClusterIds),
			JSON.stringify(clusterData.strokeIds),
			clusterData.id,
		]),
	};
};

export default useDropdownLeaderCard;
