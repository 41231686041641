import classNames from 'classnames';
import React, { useState } from 'react';

import useGetEventQuery from '../../../../api/common/queries/useGetEventQuery';
import ChallengeType from '../../../../domain/types/challenge-type';
import ChevronUpIcon from '../../../../icons/ChevronUpIcon';
import './BottomDrawer.scss';
import DrawerTabs, { DrawerTab } from './DrawerTabs';
import useDrawer from './useBottomDrawer';

interface Props {
	tabs: DrawerTab[];
	challengeType: ChallengeType;
}

const Drawer = ({ tabs, challengeType }: Props) => {
	const showHoleDropdown = challengeType === ChallengeType.FullRound;

	const showTabs = tabs.length > 1;
	const {
		eventId,
		holeNumber,
		isWideScreen,
		drawerHeight,
		handleDrawerHeight,
		holeList,
		selectedHole,
		onHoleChange,
	} = useDrawer(showTabs);

	const { event } = useGetEventQuery(eventId);

	const [touchStartY, setTouchStartY] = useState(0);
	const [touchEndY, setTouchEndY] = useState(0);

	const [selectedTab, setSelectedTab] = useState<DrawerTab>(tabs[0]);

	const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
		setTouchStartY(e.targetTouches[0].clientY);
		setTouchEndY(e.targetTouches[0].clientY);
	};

	const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
		setTouchEndY(e.targetTouches[0].clientY);
	};

	const handleTouchEnd = () => {
		const touchDeltaY = touchStartY - touchEndY;
		if (touchDeltaY > 10 || touchDeltaY < -10) {
			handleDrawerHeight(touchDeltaY);
		}
	};

	const grabBarOptions = !isWideScreen
		? {
				role: 'button',
				tabIndex: 0,
				'aria-label': 'close',
				onClick: () => handleDrawerHeight(0),
				onKeyDown: () => handleDrawerHeight(0),
				onTouchStart: handleTouchStart,
				onTouchMove: handleTouchMove,
				onTouchEnd: handleTouchEnd,
		  }
		: {};

	return (
		<section
			className={classNames(
				'bottom-drawer',
				'flex flex-col w-full xl:w-[27.75rem] xl:ml-[2rem] xl:my-[2rem]',
				{
					'slide-up-animation': !isWideScreen,
					'mb-[3rem]': showTabs,
				}
			)}
			style={{ height: drawerHeight }}
		>
			{showTabs && (
				<DrawerTabs
					drawerTabs={tabs}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
					isWideScreen={isWideScreen}
				/>
			)}
			<div
				className='bg-white overflow-hidden flex flex-col'
				style={{
					borderRadius: isWideScreen ? '0 10px 10px 10px' : '10px 10px 0 0',
				}}
			>
				<div className='bottom-drawer__grab-bar' {...grabBarOptions}>
					{!isWideScreen && (
						<div className='bottom-drawer__grab-bar__content' />
					)}
					<div className='bottom-drawer__grab-bar__event-name'>
						<div className='overflow-hidden'>
							<p className='text-[18px] xl:text-[22px] truncate'>
								{event?.name || 'Event Not Found'}
							</p>
						</div>
						{showHoleDropdown && (
							<div className='flex items-center select'>
								<select
									value={selectedHole}
									onChange={e => onHoleChange(e.target.value)}
									onClick={e => {
										e.stopPropagation();
									}}
								>
									{holeList?.map(hole => (
										<option key={hole} value={hole}>
											Hole {hole}
										</option>
									))}
								</select>

								<div className='inverted pr-1'>
									<ChevronUpIcon />
								</div>
							</div>
						)}
						{!showHoleDropdown && (
							<p className='text-sm xl:text-[18px] font-bold text-ct-blue whitespace-nowrap'>
								Hole {holeNumber}
							</p>
						)}
					</div>
				</div>
				{selectedTab.component}
			</div>
		</section>
	);
};

export default Drawer;
