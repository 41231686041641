import { useMemo } from 'react';

import commonApi from '../common-api';
import toMarketingDetails from '../transforms/toMarketingDetails';

const useGetMarketingDetailsQuery = (eventId: number) => {
	const { data, ...rest } = commonApi.useGetMarketingDetailsQuery(eventId);

	return {
		marketingDetails: useMemo(
			() => (data ? toMarketingDetails(data) : undefined),
			[data]
		),
		...rest,
	};
};

export default useGetMarketingDetailsQuery;
