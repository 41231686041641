import React, { useEffect, useRef, useState } from 'react';

import { usePinPointChallengeDetailsQuery } from '../../../../../api/challenges/queries/useGetChallengeDetailsQuery';
import { SortOption, ViewOption } from '../../../../../domain/types/enum-types';
import WarningCircleIcon from '../../../../../icons/WarningCircleIcon';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import InfoIcon from '../../../../shared/forms/InfoIcon/InfoIcon';
import RadioButtonDrawer from '../../../../shared/forms/RadioButtonDrawer/RadioButtonDrawer';
import useGetMapParams from '../../../../shared/hooks/useGetMapParams';
import {
	clearAllSelected,
	clearSelectedStrokes,
	runSearch,
	setShowViewOption,
	setSort,
} from '../../../challenge-reducer';
import FilterDrawer from '../../../shared/components/FilterDrawer/FilterDrawer';
import LeaderCard from '../../../shared/components/LeaderCard/LeaderCard';
import SearchInput from '../../../shared/components/SearchInput/SearchInput';
import TeamDropdownLeaderCard from '../../../shared/components/TeamDropdownLeaderCard/TeamDropdownLeaderCard';
import Team from '../../../shared/models/team';
import useGetClusters from '../../../shared/selectors/useGetClusters';
import useGetFirstSelectedCluster from '../../../shared/selectors/useGetFirstSelectedCluster';
import { useGetPlayerStrokes } from '../../../shared/selectors/useGetPlayerStrokes';
import useGetTeams from '../../../shared/selectors/useGetTeams';
import ClusteringService from '../../../shared/services/ClusteringService';
import './Leaderboard.scss';

const Leaderboard = () => {
	const { sortOption, shotViewOption } = useAppSelector(state => state.ctp);

	const dispatch = useAppDispatch();

	const { eventId, roundId, holeNumber } = useGetMapParams();

	const { challengeDetails } = usePinPointChallengeDetailsQuery(
		eventId,
		roundId,
		holeNumber
	);

	const { playerStrokes, allPlayers } = useGetPlayerStrokes(challengeDetails);

	const clusters = useGetClusters(
		allPlayers,
		challengeDetails?.hole.pinLocation
	);
	const teams = useGetTeams(allPlayers);
	const selectedCluster = useGetFirstSelectedCluster(clusters);

	const [filtersSectionBorder, setFiltersSectionBorder] = useState<string>('');

	const refContent = useRef<HTMLElement>(null);
	const refHeader = useRef<HTMLElement>(null);

	const updateTopFilterClass = (isTruthy: boolean) => {
		if (isTruthy) {
			setFiltersSectionBorder('1px solid #e0dfdf');
		} else {
			setFiltersSectionBorder('none');
		}
	};

	useEffect(() => {
		if (refContent.current) {
			const { scrollTop, scrollHeight, clientHeight } = refContent.current;

			// check to see if modal has a scroll bar. If so, show the horizontal line to break up the content and bottom button
			updateTopFilterClass(scrollHeight > clientHeight && scrollTop > 0);
		}
	}, [clusters]);

	const onScroll = () => {
		if (refContent.current) {
			const { scrollTop } = refContent.current;

			// check to see if scroll bar is all the way at the top of the modal. If not, show the horizontal line to break up the content and filters
			updateTopFilterClass(scrollTop > 0);
		}
	};

	useEffect(() => {
		// The cluster id is a comma separated list of stroke IDs, so take the first
		const elements = document.getElementsByClassName(`leader-card selected`);

		const scrollElement = elements[0];

		if (scrollElement) {
			setTimeout(
				() => scrollElement.scrollIntoView({ behavior: 'smooth' }),
				250
			);
		}
	}, [selectedCluster?.id]);

	return (
		<>
			<section className='bg-white border-t border-[#e0dfdf]' ref={refHeader}>
				<div
					className='leaderboard__filters'
					style={{ borderBottom: filtersSectionBorder }}
				>
					<FilterDrawer />
					<RadioButtonDrawer
						label='Sort'
						listValues={Object.values(SortOption)}
						currentLabel={sortOption}
						onSubmitHandler={sortLabel => {
							dispatch(setSort(sortLabel as SortOption));
						}}
					/>
					<RadioButtonDrawer
						label='View'
						listValues={Object.values(ViewOption)}
						currentLabel={shotViewOption}
						onSubmitHandler={(viewLabel: string) => {
							dispatch(setShowViewOption(viewLabel as ViewOption));
							dispatch(setSort(SortOption.distanceAscending));
							dispatch(clearAllSelected());
						}}
					/>
				</div>
			</section>
			<section
				className='leaderboard__overlay-section xl:rounded-b-lg bg-white'
				ref={refContent}
				onScroll={() => onScroll()}
			>
				{challengeDetails?.participantStrokes?.length > 0 && (
					<>
						<SearchInput
							onChange={e => {
								dispatch(runSearch(e.target.value));
								dispatch(clearSelectedStrokes());
							}}
						/>
						<div className='leaderboard__container mt-2.5 mb-[1rem]'>
							{shotViewOption === ViewOption.playerView &&
								playerStrokes.map(x => (
									<div key={x.section} className='w-full'>
										{x.section !== 'All' && x.players.length > 0 && (
											<div className='text-[14px] mt-2.5 mr-auto flex items-center gap-1'>
												{x.section}
												<InfoIcon
													classes='info-icon__small'
													overridePopupStyles={{
														left: '1.3rem',
														bottom: '-5rem',
													}}
													overrideArrowStyles={{
														left: '0.4rem',
														bottom: '-0.3rem',
														transform: 'rotate(0deg)',
													}}
													text={x.infoText}
												/>
											</div>
										)}
										{x.players.map(stroke => (
											<LeaderCard
												key={`scatterplot-marker-${stroke.ballLocation.latitude}-${stroke.ballLocation.longitude}`}
												strokeData={stroke}
												boxShadow
												bottomText={`Team ${stroke.teamName}`}
												avgDistance={challengeDetails?.averageDistance?.feet}
											/>
										))}
									</div>
								))}

							{shotViewOption === ViewOption.teamView &&
								teams.map((team: Team, idx: number) => (
									<TeamDropdownLeaderCard
										key={`scatterplot-marker-${team.cluster.ballLocation.latitude}-${team.id}`}
										teamData={team}
									>
										{team.playerStrokes.map(stroke => (
											<LeaderCard
												key={`scatterplot-marker-${
													stroke.ballLocation.latitude
												}-${idx.toString()}`}
												strokeData={stroke}
												boxShadow={false}
												bottomText={
													ClusteringService.isStrokeClustered(
														clusters,
														stroke.strokeId
													)
														? 'Shown in cluster on map.'
														: ''
												}
												avgDistance={challengeDetails?.averageDistance?.feet}
											/>
										))}
									</TeamDropdownLeaderCard>
								))}
						</div>
					</>
				)}
				{challengeDetails?.participantStrokes?.length === 0 && (
					<div
						className='flex flex-col gap-4 items-center justify-center rounded-[6px] p-[1rem]'
						style={{
							boxShadow: '4px 4px 20px rgba(0,0,0,0.07)',
							border: '1px solid #EFEFEF',
						}}
					>
						<WarningCircleIcon />
						No shots recorded for this hole.
					</div>
				)}
			</section>
		</>
	);
};

export default Leaderboard;
