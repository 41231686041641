import React, { useEffect, useState } from 'react';

import SessionStorageService from '../../../../../api/SessionStorageService';
import ChallengeType from '../../../../../domain/types/challenge-type';
import GraphIcon from '../../../../../icons/GraphIcon';
import StarIcon from '../../../../../icons/StarIcon';
import MarketingModal from '../../../../marketing/MarketingModal/MarketingModal';
import OfferPopup from '../../../../marketing/OfferPopup/OfferPopup';
import MapWrapper from '../../../../shared/components/Maps/MapWrapper';
import useGetMapParams from '../../../../shared/hooks/useGetMapParams';
import Drawer from '../../../../shared/layout/BottomDrawer/BottomDrawer';
import ErrorBanner from '../../../../shared/layout/ErrorBanner/ErrorBanner';
import Leaderboard from '../Leaderboard/Leaderboard';
import RoundHighlights from '../RoundHighlights/RoundHighlights';
import useScatterPlot from './useScatterplot';

const drawerTabs = [
	{
		name: 'Leaderboard',
		icon: (selected: boolean) => (
			<GraphIcon className='mx-auto' selected={selected} />
		),
		component: <Leaderboard />,
	},
	{
		name: 'Round Highlights',
		icon: (selected: boolean) => (
			<StarIcon className='mx-auto' selected={selected} />
		),
		component: <RoundHighlights />,
	},
];

const Scatterplot = () => {
	const { eventId, roundId, holeNumber, mapType } = useGetMapParams();

	const {
		modal: { marketingModal },
		data: { mapMarkers, isLoading, pinLocation, error, refreshData },
		mapProps,
	} = useScatterPlot(eventId, roundId, holeNumber);

	const [errorMessage, setErrorMessage] = useState<string>();

	const isMapAvailable =
		mapMarkers &&
		pinLocation.latitude !== 0 &&
		pinLocation.longitude !== 0 &&
		pinLocation.latitude !== -1 &&
		pinLocation.longitude !== -1;

	useEffect(() => {
		if (!isLoading) {
			if (!error) {
				if (!isMapAvailable) {
					setErrorMessage('The map is not available. Please try again later.');
				} else if (!mapMarkers && eventId && roundId && holeNumber) {
					setErrorMessage(
						'No shots recorded. Please check back after the competition has started.'
					);
				}
			} else {
				setErrorMessage('Failed to fetch data.');
			}
		}
	}, [isLoading]);

	marketingModal.isModalOpen =
		SessionStorageService.showMarketingModal === 'true';

	return (
		<div className='relative'>
			{!error && (
				<div>
					<MapWrapper
						mapMarkers={mapMarkers}
						mapType={mapType}
						challengeType={ChallengeType.FullRound}
						{...mapProps}
					/>

					<Drawer tabs={drawerTabs} challengeType={ChallengeType.FullRound} />

					<MarketingModal
						isOpen={marketingModal.isModalOpen}
						toggleModal={marketingModal.toggleModal}
					/>
					<OfferPopup />
				</div>
			)}
			{errorMessage && (
				<div className='error-container'>
					<ErrorBanner
						classes='mt-5'
						message={errorMessage}
						click={() => {
							refreshData();
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default Scatterplot;
