import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '../api-config';
import { createGetQuery } from '../api-util';
import { SlGameHighlights } from './schema';

const analyticsApi = createApi({
	reducerPath: 'analytics-api',
	baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/analytics` }),
	endpoints: builder => ({
		getGameHighlights: builder.query<
			SlGameHighlights,
			{ eventId: number; roundId: number; holeNumber: number }
		>({
			query: ({ eventId, roundId, holeNumber }) =>
				createGetQuery(`/${eventId}/${roundId}/${holeNumber}`),
		}),
	}),
});

export default analyticsApi;
