import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';

import useGetEventsQuery from '../../../../api/common/queries/useGetEventsQuery';
import useGetSocialLinks from '../../hooks/useGetSocialLinks';

const useNavigation = (eventId: number) => {
	const { pathname } = useLocation();
	const { events } = useGetEventsQuery();

	const links = useGetSocialLinks(eventId);

	const eventLinks = events
		?.filter(
			x =>
				x.startDate.startOf('day') <= DateTime.now().startOf('day') &&
				x.endDate.startOf('day') >= DateTime.now().startOf('day')
		)
		.map(x => ({
			id: x.id,
			href: `/events/${x.id}/challenges`,
			title: x.name,
			isSelected: pathname.includes(`/events/${x.id}`),
		}));

	return {
		eventLinks,
		...links,
	};
};

export default useNavigation;
