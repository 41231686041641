import React, { ReactNode } from 'react';

import './DataCard.scss';

interface Props {
	title: string;
	infoSection: string | null;
	children?: ReactNode;
}

const DataCard = ({ title, infoSection, children }: Props) => (
	<div className='data-card'>
		<div className='data-card__header'>
			<h2 className='title'>{title}</h2>
			{infoSection && (
				<button type='button' className='info-section'>
					{infoSection}
				</button>
			)}
		</div>
		{children && <div className='data-card__content'>{children}</div>}
	</div>
);

export default DataCard;
