import React, { useMemo, useState } from 'react';

import SessionStorageService from '../../../api/SessionStorageService';
import commonApi from '../../../api/common/common-api';
import TermsBulletIcon from '../../../icons/TermsBulletIcon';
import closeIcon from '../../../icons/close-icon.png';
import GiftIcon from '../../../icons/gift-icon.svg';
import { getImageFromBucket } from '../../shared/helper-functions';
import useGetMapParams from '../../shared/hooks/useGetMapParams';
import useWindowResize from '../../shared/hooks/useWindowResize';
import Modal from '../../shared/layout/Modal/Modal';

interface Props {
	isOpen: boolean;
	toggleModal: () => void;
}

const MarketingModal = ({ toggleModal, isOpen }: Props) => {
	const { width } = useWindowResize();
	const { eventId } = useGetMapParams();

	const [isEventImgError, setIsEventImgError] = useState<boolean>(false);

	// grab event img from s3 bucket if we are using shell app and not in a local environment
	const eventLogoUrl = useMemo(
		() => getImageFromBucket(`images/events/${eventId}/event-logo.png`),
		[eventId]
	);

	const { data: details } = commonApi.useGetMarketingModalDetailsQuery(eventId);

	if (!details) return null;

	return (
		<Modal
			isVisible={isOpen}
			toggleModal={toggleModal}
			handleSubmit={toggleModal}
			showBottomButtons={false}
			customStyles={{ width: width > 1024 ? '616px' : '', height: 'auto' }}
		>
			<button
				className='absolute right-2 top-2'
				type='button'
				tabIndex={0}
				onClick={e => {
					e.stopPropagation();
					toggleModal();
					SessionStorageService.showMarketingModal = 'false';
				}}
			>
				<img src={closeIcon} alt='close modal' title='Close Modal' />
			</button>
			<div className='px-4 sm:py-8 md:py-12 flex flex-col gap-8 md:gap-12 items-center justify-center'>
				<h1 className='sm:text-2xl md:text-[32px] font-bold text-ct-blue text-center'>
					{details.title}
				</h1>
				{eventLogoUrl && !isEventImgError && (
					<div className='max-h-[300px]'>
						<img
							className='object-cover mx-auto'
							src={eventLogoUrl}
							onError={() => setIsEventImgError(true)}
							alt='modal logo'
							width='188'
						/>
					</div>
				)}
				<div className='flex flex-col gap-5'>
					{details.marketingPrompts.map((prompt, index) => (
						<div
							className='flex flex-row items-center gap-5 md:mx-5'
							key={`Paragraph index: ${index.toString()}`}
						>
							<div className='h-[40px] w-[40px]'>
								<TermsBulletIcon width='40' height='40' fill='#CCDB2A' />
							</div>
							<p className='text-[14px] md:text-[18px] md:leading-6'>
								{prompt}
							</p>
						</div>
					))}
				</div>
				{/* TODO : ask UX and scott if we are showing this button anymore */}

				{/* <div className='w-full flex justify-center'>
					{onClick && (
						<button
							type='button'
							className='bg-ct-blue text-white sm:w-full md:w-[300px] h-12'
							onClick={e => {
								onClick();
								SessionStorageService.showMarketingModal = 'false';
							}}
						>
							Get Started
						</button>
					)}
				</div> */}

				{details.subtitle.length > 0 && (
					<>
						<hr
							className='w-full'
							style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
						/>
						<a
							aria-label='Marketing Modal Link'
							target='_blank'
							rel='noreferrer'
							href={details.link}
							className='flex items-center'
						>
							<img src={GiftIcon} alt='gift icon' />
							<div className='flex flex-col ml-[18px] font-normal items-start md:w-[324px]'>
								<h2 className='text-shotlanderBlue text-2xl	mt-18px mb-2'>
									{details.subtitle}
								</h2>
								<span className='text-grey49 text-[12px] md:text-[14px]'>
									<u>{details.subtitleDescription}</u>
								</span>
							</div>
						</a>
					</>
				)}
			</div>
		</Modal>
	);
};

export default MarketingModal;
