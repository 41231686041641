import React, { useMemo } from 'react';

import { useLongestDriveChallengeDetailsQuery } from '../../../../../api/challenges/queries/useGetChallengeDetailsQuery';
import { TeeType } from '../../../../../domain/types/enum-types';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { capitalizeFirstLetter } from '../../../../shared/helper-functions';
import useGetMapParams from '../../../../shared/hooks/useGetMapParams';
import { setTeeType } from '../../../challenge-reducer';

const TeeBoxToggle = () => {
	const { teeType } = useAppSelector(state => state.ctp);

	const dispatch = useAppDispatch();

	const { eventId, roundId, holeNumber } = useGetMapParams();

	const { challengeDetails } = useLongestDriveChallengeDetailsQuery(
		eventId,
		roundId,
		holeNumber
	);

	const teeTypes = useMemo(() => {
		const challengeTees = challengeDetails?.teeLocations.map(
			x => x.teeLocation.type
		);
		// if tee type exists in challenge object, then include it in tee list
		const tees = Object.values(TeeType).filter(x =>
			challengeTees?.find(y => y === x)
		);

		return tees?.map(tee => (
			<button
				type='button'
				key={tee}
				className={`rounded-[20px] py-[3px] px-[8px] ${
					tee === teeType ? 'bg-[#CCDB2A]' : 'text-[#737373]'
				}`}
				onClick={() => {
					dispatch(setTeeType(tee));
				}}
			>
				{capitalizeFirstLetter(tee)}
			</button>
		));
	}, [teeType, challengeDetails?.teeLocations]);

	return teeTypes?.length > 0 ? (
		<div className='flex gap-1 items-center absolute z-10 top-2 right-1 md:top-6 md:right-4 bg-[#EFEFEF] rounded-[20px] text-[10px] md:text-[14px] pl-[6px] md:pl-[10px]'>
			<span>Tee Box:</span>
			<div className='flex md:gap-2 bg-white rounded-[20px] p-[2px] md:p-[3px]'>
				{teeTypes}
			</div>
		</div>
	) : null;
};

export default TeeBoxToggle;
