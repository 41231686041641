import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SessionStorageService from './api/SessionStorageService';
import CONSUME_CONFIG from './api/api-config';
import useLazyGetEventQuery from './api/common/queries/useLazyGetEventQuery';
import useLazyGetOrgDetailsQuery from './api/common/queries/useLazyGetOrgDetailsQuery';
import useGetMapParams from './features/shared/hooks/useGetMapParams';

interface ShellApp {
	domain: string;
	pathname: string;
	search: string;
	apiKey: string;
}

const useInitShell = () => {
	const { eventId } = useGetMapParams(false);

	const location = useLocation();
	const navigate = useNavigate();

	const { SHELL_APP_URL, SHELL_APP_LOCAL_URL } = CONSUME_CONFIG;

	// READ : if you want to test shell app locally, then set this initial state to undefined
	const [foundApiKey, setFoundApiKey] = useState<boolean | undefined>(
		process.env.REACT_APP_STAGE === undefined ? true : undefined
	);

	const [shellDomain, setShellDomain] = useState<string>();

	const prevLocation = useRef<string>();

	const { orgDetails, isError: isOrgError } = useLazyGetOrgDetailsQuery(
		foundApiKey ?? false
	);
	const { event } = useLazyGetEventQuery(eventId);

	// send org title to shell app to update vanity tag
	useEffect(() => {
		if (shellDomain && orgDetails) {
			let vanityTag = orgDetails.title;
			if (event) vanityTag += ` - ${event.name}`;
			window.top?.postMessage(vanityTag, shellDomain);
		}
	}, [shellDomain, orgDetails, event]);

	// send url params to shell app to update browser url
	useEffect(() => {
		if (shellDomain && location.pathname !== prevLocation.current) {
			window.top?.postMessage(location, shellDomain);
			prevLocation.current = location.pathname;
		}
	}, [location]);

	useEffect(() => {
		const handler = (e: MessageEvent) => {
			if (
				(e.origin.includes(SHELL_APP_URL) && !e.origin.includes('ctp')) ||
				e.origin.includes(SHELL_APP_LOCAL_URL)
			) {
				const data = e.data as ShellApp;

				if (data.apiKey && data.domain) {
					SessionStorageService.apiKey = data.apiKey;
					setShellDomain(data.domain);
					SessionStorageService.shellAppDomain = data.domain;

					// need a slight delay so it has enough time for api key to be stored in session storage
					setTimeout(() => {
						setFoundApiKey(true);
					}, 500);
				} else {
					setFoundApiKey(false);
				}

				prevLocation.current = data.pathname;

				navigate(`${data.pathname}${data.search}`, { replace: true });
			}
		};

		window.addEventListener('message', handler);

		return () => window.removeEventListener('message', handler);
	}, []);

	return {
		foundApiKey,
		isOrgError,
	};
};

export default useInitShell;
