import React from 'react';

import Event from '../../../../domain/models/event';
import CalendarIcon from '../../../../icons/CalendarIcon';
import GolfTeeIcon from '../../../../icons/GolfTeeIcon';
import LiveResultsWeatherIcon from '../../../../icons/LiveResultsWeatherIcon';
import { useAppDispatch } from '../../../../redux/store';
import { runSearch } from '../../../challenges/challenge-reducer';
import { formatDateRange } from '../../../shared/helper-functions';
import useNavigateToPage from '../../../shared/hooks/useNavigateToPage';
import './EventDetailsCard.scss';

interface Props {
	event: Event;
}

const EventDetailsCard = ({
	event: {
		id,
		imageUrl,
		name,
		description,
		venueName,
		startDate,
		endDate,
		location,
	},
}: Props) => {
	const navigateToPage = useNavigateToPage();
	const dispatch = useAppDispatch();

	const onNavigate = () => {
		navigateToPage(`${id}/challenges`);
		dispatch(runSearch(''));
	};

	return (
		<section
			className='event-card'
			onClick={onNavigate}
			onKeyDown={onNavigate}
			role='button'
			tabIndex={0}
		>
			<div className='event-card__hero-image'>
				<img src={imageUrl} alt='Event Card Background' />
			</div>
			<div className='event-card__content'>
				<div className='event-card__title'>{name}</div>
				<div className='event-card__description'>{description}</div>
				<div className='event-card__icon-container'>
					<div>
						<GolfTeeIcon
							width='0.6875rem'
							height='1rem'
							fill='black'
							className='icon'
							style={{ marginTop: '-0.1rem' }}
						/>
						<span>{venueName}</span>
					</div>
					<div>
						<CalendarIcon
							width='1.125rem'
							height='1.25rem'
							fill='black'
							className='icon'
						/>
						<span>{`${formatDateRange(
							startDate.toFormat('yyyy-LL-dd'),
							endDate.toFormat('yyyy-LL-dd')
						)}`}</span>
					</div>
					<div>
						<LiveResultsWeatherIcon
							width='0.6875rem'
							height='0.875rem'
							fill='black'
							className='icon'
						/>
						<span>{location}</span>
					</div>
				</div>
			</div>
		</section>
	);
};

export default EventDetailsCard;
