import React, { SVGProps } from 'react';

import CapTechIcon from '../domain/types/captech-icon';

const LiveResultsWeatherIcon = ({
	width,
	height,
	fill,
	...props
}: SVGProps<SVGSVGElement> & CapTechIcon) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height={height}
		width={width}
		fill={fill}
		{...props}
	>
		<path
			d='M9.17778 13.941L4.45278 12.25L0.972222 13.6461C0.751852 13.764 0.534722 13.7575 0.320833 13.6264C0.106944 13.4953 0 13.2987 0 13.0365V2.06461C0 1.89419 0.0486111 1.74345 0.145833 1.61236C0.243056 1.48127 0.369444 1.38296 0.525 1.31742L4.06389 0.0589888C4.19352 0.019663 4.32315 0 4.45278 0C4.58241 0 4.71204 0.019663 4.84167 0.0589888L9.56667 1.73034L13.0278 0.33427C13.2481 0.229401 13.4653 0.239232 13.6792 0.363764C13.8931 0.488296 14 0.681648 14 0.943821V12.0534C14 12.1976 13.9514 12.3221 13.8542 12.427C13.7569 12.5318 13.637 12.6105 13.4944 12.6629L9.95556 13.941C9.82593 13.9803 9.6963 14 9.56667 14C9.43704 14 9.30741 13.9803 9.17778 13.941ZM8.90556 12.6039V2.67416L5.09444 1.3764V11.3062L8.90556 12.6039ZM10.0722 12.6039L12.8333 11.6798V1.61236L10.0722 2.67416V12.6039ZM1.16667 12.368L3.92778 11.3062V1.3764L1.16667 2.30056V12.368Z'
			fill='current'
		/>
	</svg>
);

export default LiveResultsWeatherIcon;
