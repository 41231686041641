import { useMemo } from 'react';

import { useAppSelector } from '../../../../redux/store';
import Cluster from '../models/cluster';

/**
 * Find the first cluster that contains a stroke id that is in the selected store stroke ids
 * @param clusters
 */
const useGetFirstSelectedCluster = (clusters: Cluster[]) => {
	const { selectedStrokeIds } = useAppSelector(state => state.ctp);

	return useMemo(
		() =>
			clusters.find(x => selectedStrokeIds.some(y => x.strokeIds.includes(y))),
		[clusters, selectedStrokeIds]
	);
};

export default useGetFirstSelectedCluster;
