import { OrgLinkType } from '../types/enum-types';
import OrgLink from './org-link';

export default class OrgDetails {
	constructor(
		public id: number,
		public name: string,
		public address: string,
		public email: string,
		public phone: string,
		public subdomain: string,
		public title: string,
		public links: OrgLink[]
	) {}

	get aboutUrl() {
		return this.links.find(x => x.type === OrgLinkType.about)?.link;
	}

	get contactUrl() {
		return this.links.find(x => x.type === OrgLinkType.contact)?.link;
	}

	get linkedinUrl() {
		return this.links.find(x => x.type === OrgLinkType.linkedin)?.link;
	}

	get twitterUrl() {
		return this.links.find(x => x.type === OrgLinkType.twitter)?.link;
	}

	get youtubeUrl() {
		return this.links.find(x => x.type === OrgLinkType.youtube)?.link;
	}
}
