import { useEffect, useMemo } from 'react';

import commonApi from '../common-api';
import toOrgDetails from '../transforms/toOrgDetails';

const useLazyGetOrgDetailsQuery = (foundApiKey: boolean) => {
	const [refetch, { data, ...rest }] = commonApi.useLazyGetOrgDetailsQuery();

	useEffect(() => {
		if (foundApiKey) {
			refetch();
		}
	}, [foundApiKey]);

	return {
		orgDetails: useMemo(() => (data ? toOrgDetails(data) : undefined), [data]),
		...rest,
	};
};

export default useLazyGetOrgDetailsQuery;
