import { CompatClient, Stomp } from '@stomp/stompjs';
import { useEffect, useRef, useState } from 'react';

import { WebSocketType } from '../../domain/types/enum-types';
import useGetMapParams from '../../features/shared/hooks/useGetMapParams';
import useOnceEffect from '../../features/shared/hooks/useOnceEffect';
import { WEB_SOCKET_URL } from '../api-config';

interface WebSocketInterface {
	timestamp: Date;
	type: WebSocketType;
}

const useWebSocket = (routes: string[], fetchMethod?: () => void) => {
	const { roundId } = useGetMapParams();

	const [isError, setIsError] = useState<boolean>(false);
	const isErrorRef = useRef<boolean>(false);
	isErrorRef.current = isError;

	const [messages, setMessages] = useState<string[]>([]);
	const messagesRef = useRef<string[]>([]);
	messagesRef.current = messages;

	// if web sockets aren't working, call infinite timer to fetch data manually every minute
	const fetchData = () => {
		setTimeout(() => {
			fetchMethod?.();

			if (isErrorRef.current) fetchData();
		}, 60000);
	};

	useEffect(() => {
		if (isError) {
			fetchData();
		}
	}, [isError]);

	useOnceEffect(
		routes.length > 0,
		() => {
			const webSockets: CompatClient[] = [];

			routes.forEach(route => {
				const ws = Stomp.client(`wss://${WEB_SOCKET_URL}/${route}`);
				ws.reconnectDelay = 20000;

				ws.connect(
					{ name: roundId },
					(frame: unknown) => {
						ws.subscribe(
							`/user/${roundId}/queue/${route}`,
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							(wsMessage: any) => {
								const strokeData: WebSocketInterface = JSON.parse(
									wsMessage.body
								);
								setMessages([...messagesRef.current, strokeData.type]);
							}
						);
						setIsError(false);
					},
					(error: unknown) => {
						// eslint-disable-next-line no-console
						console.log('STOMP error', error);
					}
				);

				// if web socket closes, need to kick off process to manually call endpoint to fetch data by using setTimeout
				ws.onWebSocketClose = () => {
					setIsError(true);
				};

				webSockets.push(ws);
			});

			return () => {
				webSockets.forEach(ws => {
					ws.disconnect();
				});
			};
		},
		[routes]
	);

	return messages;
};

export default useWebSocket;
