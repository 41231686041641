import { useParams, useSearchParams } from 'react-router-dom';

import { MapType } from '../../../domain/types/enum-types';

const useGetMapParams = (useReactHook = true) => {
	let { eventId, roundId, holeNumber } = useParams();
	const [searchParams] = useSearchParams();

	// Use window.location.pathname when unable to use useParams hook
	if (!useReactHook) {
		const paths = window.location.pathname.split('/');
		eventId = paths[1] === 'events' && !!paths[2] ? paths[2] : undefined;
		roundId = paths[3] === 'challenges' && !!paths[4] ? paths[4] : undefined;
		holeNumber = paths[5] === 'holes' && !!paths[6] ? paths[6] : undefined;
	}

	return {
		eventId: +(eventId ?? 0),
		roundId: +(roundId ?? 0),
		holeNumber: +(holeNumber ?? 0),
		mapType:
			MapType[
				(searchParams.get('mapType') ?? MapType.google) as keyof typeof MapType
			],
	};
};

export default useGetMapParams;
