import LongestDriveTeeLocation from '../../../domain/models/longest-drive-tee-location';
import { SlLongestDriveTeeLocation } from '../schema';
import { toPinPointGroup } from './toGroup';

const toLongestDriveTeeLocation = (
	slLongestDriveTeeLocation: SlLongestDriveTeeLocation
) =>
	new LongestDriveTeeLocation(
		slLongestDriveTeeLocation.averageDriveDistance,
		slLongestDriveTeeLocation.groups.map(x => toPinPointGroup(x)),
		slLongestDriveTeeLocation.teeLocation
	);

export default toLongestDriveTeeLocation;
