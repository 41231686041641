import React, { PropsWithChildren } from 'react';

import './EventBanner.scss';

interface Props
	extends PropsWithChildren,
		React.HTMLAttributes<HTMLDivElement> {}

const EventBanner = ({ children, className, ...rest }: Props) => (
	<div className={`event-banner ${className ?? ''}`} {...rest}>
		{children}
	</div>
);

export default EventBanner;
