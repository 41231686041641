import OrgDetails from '../../../domain/models/org-details';
import OrgLink from '../../../domain/models/org-link';
import { SlOrgDetails, SlOrgLink } from '../schema';

export const toSocialLink = (slOrgLink: SlOrgLink) =>
	new OrgLink(
		slOrgLink.id,
		slOrgLink.orgId,
		slOrgLink.type,
		slOrgLink.label,
		slOrgLink.link,
		slOrgLink.organizationLinkType
	);

const toOrgDetails = (slOrgDetails: SlOrgDetails) =>
	new OrgDetails(
		slOrgDetails.id,
		slOrgDetails.name,
		slOrgDetails.address,
		slOrgDetails.email,
		slOrgDetails.phone,
		slOrgDetails.subdomain,
		slOrgDetails.title,
		slOrgDetails.links.map(x => toSocialLink(x))
	);

export default toOrgDetails;
