import React, { SVGProps } from 'react';

const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='10'
		height='6'
		viewBox='0 0 10 6'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M5.01284 -5.80064e-07C5.1009 -5.72365e-07 5.18162 0.0154618 5.25501 0.0463862C5.32839 0.0773106 5.40178 0.131428 5.47516 0.208738L9.83424 4.80101C9.95165 4.92471 10.0067 5.09092 9.99935 5.29966C9.99201 5.5084 9.92964 5.67462 9.81222 5.79832C9.66545 5.95294 9.50767 6.01866 9.33889 5.99546C9.1701 5.97227 9.01966 5.89882 8.88757 5.77513L5.01284 1.69311L1.13811 5.77513C1.02069 5.89882 0.862912 5.9684 0.664772 5.98386C0.466632 5.99933 0.308854 5.92975 0.191438 5.77512C0.0446678 5.65143 -0.0177091 5.48907 0.0043064 5.28807C0.0263219 5.08706 0.0960371 4.91697 0.213453 4.77781L4.55051 0.208738C4.6239 0.131427 4.69728 0.0773105 4.77067 0.0463861C4.84405 0.0154618 4.92478 -5.87763e-07 5.01284 -5.80064e-07Z'
			fill='#003865'
		/>
	</svg>
);

export default ChevronUpIcon;
