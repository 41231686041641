import { useNavigate } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';

const useNavigateToPage = () => {
	const navigate = useNavigate();

	// READ : This 'replace' property inside the navigate function is needed when communicating with the shell app. The value will be set
	// to true so that when navigating through the main app, the new page will replace the old page in the browsing history. This is needed
	// because the shell app communicates to the main app through an iframe, and this iframe will add to the browsing history of the shell app
	// while navigating through these pages which messes up the back/next navigation. This solution fixes that problem.

	// When this application is being ran on it's own (not through the shell app) this replace logic isn't needed and will set the value
	// to false, making it work the same way it usually works.
	const navigateToPage = (url: string) => {
		navigate(url, {
			replace: !!SessionStorageService.shellAppDomain,
		});
	};

	return navigateToPage;
};

export default useNavigateToPage;
