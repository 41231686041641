import { useMemo } from 'react';

import { SlChallengeDetails } from '../../../../api/challenges/schema';
import LongestDriveChallengeDetails from '../../../../domain/models/challenge-details/longest-drive-challenge-details';
import PinPointChallengeDetails from '../../../../domain/models/challenge-details/pin-point-challenge-details';
import Stroke from '../../../../domain/models/stroke';
import ChallengeType from '../../../../domain/types/challenge-type';
import Distance from '../../../../domain/types/distance';
import { SortOption } from '../../../../domain/types/enum-types';
import LatLong from '../../../../domain/types/lat-long';
import { useAppSelector } from '../../../../redux/store';
import alphaNumericSort from '../../../shared/helper-functions';
import PlayerStroke from '../models/player-stroke';

interface Options {
	skipFilter?: boolean;
	skipSearch?: boolean;
	skipSort?: boolean;
}

interface PlayerStrokeInterface {
	section: string;
	infoText?: string;
	players: PlayerStroke[];
}

export const useGetPlayerStrokes = (
	challengeDetails?: SlChallengeDetails,
	options?: Options
) => {
	const { searchValue, filters, sortOption, teeType } = useAppSelector(
		state => state.ctp
	);

	return useMemo(() => {
		let playerStrokes: PlayerStrokeInterface[] = [];

		if (challengeDetails) {
			let playerRankForHole = 1;

			let distance = 'holeDistance';
			let metric = 'feet';
			let playerList = (challengeDetails as PinPointChallengeDetails)
				.participantsSortedByDistanceToHole;

			if (challengeDetails.challengeType === ChallengeType.LongestDrive) {
				distance = 'driveDistance';
				metric = 'yards';
				playerList =
					(
						challengeDetails as LongestDriveChallengeDetails
					)?.teeLocations?.find(x => x.teeLocation.type === teeType)
						?.participantsSortedByLongestDrive ?? [];
			}

			playerStrokes = playerList?.map(x => ({
				...x,
				players: x.players.map(
					({ stroke, firstName, lastName, division, groupName, groupId }) => {
						let strokeDistance = stroke[distance as keyof Stroke];
						if (strokeDistance)
							strokeDistance =
								strokeDistance[
									metric as keyof (string | number | LatLong | Distance)
								];
						return new PlayerStroke(
							stroke.id,
							stroke.participantId,
							firstName,
							lastName,
							division,
							groupName,
							groupId,
							stroke.strokeTime,
							stroke.courseLocation,
							+(strokeDistance ?? 0),
							stroke.ballLocation,
							playerRankForHole++
						);
					}
				),
			}));

			if (sortOption === SortOption.distanceDescending) {
				playerStrokes = playerStrokes.map(x => ({
					...x,
					players: x.players.reverse(),
				}));
			}

			if (sortOption === SortOption.alphaAscending) {
				playerStrokes = [
					{
						section: 'All',
						players: playerStrokes
							.flatMap(x => x.players)
							.sort(
								(p1, p2) =>
									alphaNumericSort(p1.firstName, p2.firstName) ||
									alphaNumericSort(p1.lastName, p2.lastName)
							),
					},
				];
			}

			if (sortOption === SortOption.alphaDescending) {
				playerStrokes = [
					{
						section: 'All',
						players: playerStrokes
							.flatMap(x => x.players)
							.sort(
								(p1, p2) =>
									alphaNumericSort(p2.firstName, p1.firstName) ||
									alphaNumericSort(p2.lastName, p1.lastName)
							),
					},
				];
			}

			if (!options?.skipSearch && searchValue) {
				playerStrokes = playerStrokes.map(x => ({
					...x,
					players: x.players.filter(y =>
						y.fullName.toLocaleLowerCase().includes(searchValue)
					),
				}));
			}

			if (!options?.skipFilter) {
				playerStrokes = playerStrokes?.map(x => ({
					...x,
					players: x.players.filter(y =>
						filters.selectedGroups.includes(y.teamName)
					),
				}));
			}
		}

		return {
			playerStrokes,
			allPlayers: playerStrokes?.flatMap(x => x.players),
		};
	}, [challengeDetails, searchValue, filters, sortOption, teeType]);
};

export default useGetPlayerStrokes;
