import React, { useEffect, useRef, useState } from 'react';

import './InfoIcon.scss';

interface Props {
	customStyles?: { [key: string]: string };
	classes?: string;
	text?: string;
	overridePopupStyles?: { [key: string]: string };
	overrideArrowStyles?: { [key: string]: string };
}

const InfoIcon = ({
	customStyles,
	text,
	overridePopupStyles,
	overrideArrowStyles,
	classes = 'info-icon__normal',
}: Props) => {
	const iconRef = useRef<HTMLButtonElement>(null);
	const [isIconOpen, setIsIconOpen] = useState<boolean>(false);

	useEffect(() => {
		const toggleDropdown = (e: MouseEvent) => {
			// close modal if it's open and user click outside of it
			if (!iconRef.current?.contains(e.target as Node) && isIconOpen) {
				setIsIconOpen(false);
			}
		};
		document.addEventListener('mouseup', toggleDropdown);
		return () => document.removeEventListener('mouseup', toggleDropdown);
	}, [isIconOpen]);

	return (
		<button
			type='button'
			className={`info-icon ${classes} m-0 p-0 text-[14px] flex items-center justify-center`}
			style={customStyles}
			onClick={() => {
				setIsIconOpen(prev => !prev);
			}}
			ref={iconRef}
		>
			<span>i</span>
			{text && (
				<>
					<span
						className={`popup ${classes}__popup ${
							isIconOpen ? 'visible' : 'invisible'
						}`}
						style={overridePopupStyles}
					>
						{text}
					</span>
					<div
						className={`arrow ${classes}__arrow ${
							isIconOpen ? 'visible' : 'invisible'
						}`}
						style={overrideArrowStyles}
					/>
				</>
			)}
		</button>
	);
};

export default InfoIcon;
