import React, {SVGProps} from 'react';

import CapTechIcon from '../domain/types/captech-icon';

const TermsBulletIcon = ({
    width,
    height,
    fill,
    ...props
}: SVGProps<SVGSVGElement> & CapTechIcon) => (
    <svg 
    width={width} 
    height={height} 
    viewBox={`0 0 ${width} ${height}`} 
    fill={fill} 
    xmlns="http://www.w3.org/2000/svg">

        <line x1="20" y1="8.74228e-08" x2="20" y2="14" stroke="#CCDB2A" strokeWidth="4"/>
        <line x1="20" y1="26" x2="20" y2="40" stroke="#CCDB2A" strokeWidth="4"/>
        <line y1="20" x2="14" y2="20" stroke="#CCDB2A" strokeWidth="4"/>
        <line x1="26" y1="20" x2="40" y2="20" stroke="#CCDB2A" strokeWidth="4"/>
    </svg>
)

export default TermsBulletIcon;