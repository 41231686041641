import {
	AnyAction,
	Dispatch,
	Middleware,
	Reducer,
	configureStore,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import analyticsApi from '../api/analytics/analytics-api';
import challengeApi from '../api/challenges/challenges-api';
import commonApi from '../api/common/common-api';
import mapsApi from '../api/maps-api/maps-api';
import ctpReducer from '../features/challenges/challenge-reducer';
import appReducer from './app-reducer';

const apiArray = [commonApi, challengeApi, analyticsApi, mapsApi];

const apiReducers: { [key: string]: Reducer } = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const apiMiddlewares: Middleware<any, any, Dispatch<AnyAction>>[] = [];

// loop through each api file
apiArray.forEach(api => {
	// create new entry in reducers dictionary that maps the reducer path to the actual reducer function
	apiReducers[api.reducerPath] = api.reducer;

	// push middleware function to array
	apiMiddlewares.push(api.middleware);
});

const store = configureStore({
	reducer: {
		app: appReducer,
		ctp: ctpReducer,
		...apiReducers,
	},
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(apiMiddlewares),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
