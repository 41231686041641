import React, { useState } from 'react';

import SessionStorageService from '../../../api/SessionStorageService';
import commonApi from '../../../api/common/common-api';
import ClosePopupIcon from '../../../icons/close-popup-icon.svg';
import useGetMapParams from '../../shared/hooks/useGetMapParams';
import useWindowResize from '../../shared/hooks/useWindowResize';

const OfferPopup = () => {
	const { width } = useWindowResize();

	const { eventId } = useGetMapParams();
	const [popupOpen, setPopupOpen] = useState<boolean>(
		SessionStorageService.showOfferPopup === 'true'
	);
	const { data: sponsors } = commonApi.useGetSponsorshipDetailsQuery(eventId);

	if (!sponsors) return null;

	return (
		<div
			className='fixed right-4 bottom-6 w-[350px] z-5 space-y-4'
			style={{ visibility: popupOpen && width > 1024 ? 'visible' : 'hidden' }}
		>
			{sponsors.map(sponsor => (
				<a
					aria-label='Marketing Sponsorship Link'
					target='_blank'
					rel='noreferrer'
					href={sponsor.link}
					key={sponsor.id}
					className={`relative flex flex-col gap-1 bg-white rounded-lg p-4 ${
						sponsor.link.length === 0 ? 'disable-link' : ''
					}`}
				>
					<h2 className='text-shotlanderBlue text-[18px]'>{sponsor.title}</h2>
					<span className='text-grey49 text-[14px]'>{sponsor.description}</span>
					<button
						type='button'
						className='absolute top-2 right-2'
						style={{ cursor: 'pointer', pointerEvents: 'all' }}
						onClick={e => {
							e.preventDefault();
							setPopupOpen(false);
							SessionStorageService.showOfferPopup = 'false';
						}}
					>
						<img src={ClosePopupIcon} alt='close popup icon' />
					</button>
				</a>
			))}
		</div>
	);
};

export default OfferPopup;
