import { useMemo } from 'react';

import { SortOption } from '../../../../domain/types/enum-types';
import { useAppSelector } from '../../../../redux/store';
import PlayerStroke from '../models/player-stroke';
import TeamService from '../services/TeamService';

const useGetTeams = (playerStrokes?: PlayerStroke[], reverseOrder = false) => {
	const { sortOption } = useAppSelector(x => x.ctp);

	return useMemo(() => {
		if (playerStrokes) {
			let teams = TeamService.findTeams(playerStrokes);
			if (
				sortOption === SortOption.alphaAscending ||
				sortOption === SortOption.alphaDescending
			) {
				teams = TeamService.sortTeamAlpha(
					teams,
					sortOption === SortOption.alphaAscending
				);
			}

			if (
				sortOption === SortOption.distanceAscending ||
				sortOption === SortOption.distanceDescending
			) {
				teams = TeamService.sortTeamDistance(
					teams,
					// default sort will rank lowest values first, but sometimes we need to reverse this logic to sort by highest value
					reverseOrder
						? !(sortOption === SortOption.distanceAscending)
						: sortOption === SortOption.distanceAscending
				);
			}

			return teams;
		}

		return [];
	}, [playerStrokes]);
};

export default useGetTeams;
