import React, { SVGProps } from 'react';

import CapTechIcon from '../domain/types/captech-icon';

const YouTubeIcon = ({
	width,
	height,
	fill,
	...props
}: SVGProps<SVGSVGElement> & CapTechIcon) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M14.466 12.9066L19.3016 15.6545L14.466 18.4025V12.9066ZM27.7333 6.7047V24.5841C27.7333 25.9301 26.6413 27.0222 25.2952 27.0222H7.41588C6.06985 27.0222 4.97778 25.9301 4.97778 24.5841V6.7047C4.97778 5.35867 6.06985 4.2666 7.41588 4.2666H25.2952C26.6413 4.2666 27.7333 5.35867 27.7333 6.7047ZM25.6 15.6596C25.6 15.6596 25.6 12.6323 25.214 11.1796C25.0006 10.3771 24.3759 9.74724 23.5784 9.5339C22.141 9.14279 16.3556 9.14279 16.3556 9.14279C16.3556 9.14279 10.5702 9.14279 9.1327 9.5339C8.33524 9.74724 7.71048 10.3771 7.49715 11.1796C7.11112 12.6272 7.11112 15.6596 7.11112 15.6596C7.11112 15.6596 7.11112 18.6869 7.49715 20.1396C7.71048 20.9422 8.33524 21.5466 9.1327 21.7599C10.5702 22.146 16.3556 22.146 16.3556 22.146C16.3556 22.146 22.141 22.146 23.5784 21.7549C24.3759 21.5415 25.0006 20.9371 25.214 20.1345C25.6 18.6869 25.6 15.6596 25.6 15.6596Z'
			fill={fill}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM27.0222 7.8222H5.68891V24.8889H27.0222V7.8222Z'
			fill={fill}
		/>
	</svg>
);

export default YouTubeIcon;
