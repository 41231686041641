import { useEffect, useState } from 'react';

// type WindowSize = [number, number];

const useWindowResize = () => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [height, setHeight] = useState<number>(window.innerHeight);

	useEffect(() => {
		const handleResize = (): void => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return { width, height, isMobileScreen: width < 655 };
};

export default useWindowResize;
