import React, { PropsWithChildren } from 'react';

import WarningIcon from '../../../../icons/WarningIcon';
import './WarningSection.scss';

const WarningSection = ({ children }: PropsWithChildren) => (
	<section className='warning-section'>
		<WarningIcon />
		<div>{children}</div>
	</section>
);

export default WarningSection;
