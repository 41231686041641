import React from 'react';

interface Props {
	rank: number;
	name: string;
	group: string;
	score: string;
	subscore?: string;
}

const RoundHighlightsCard = ({ rank, name, group, score, subscore }: Props) => (
	<section
		className='w-full mb-[0.375rem] bg-white rounded-[6px] leader-card'
		style={{
			border: '1px solid #EDEDED',
			boxShadow: '4px 4px 20px rgba(0,0,0,0.07)',
		}}
	>
		<div className='flex items-center justify-between leader-card__inner'>
			<div className='mr-[1rem] rounded-full border-[4px] font-sans font-bold border-[#F8F8F8] leadercard__rank-number'>
				{rank}
			</div>
			<div className='flex flex-col mr-[0.8125rem] gap-y-2'>
				<p className='text-[16px] md:text-[18px]'>{name}</p>
				<p className='text-text-gray text-[12px] md:text-[14px] mt-[-0.3rem] mb-[0.33rem]'>
					{group}
				</p>
			</div>
			<div className='ml-auto'>
				<div className='leaderCard__distance-number text-black'>
					{score}
					{subscore && <span className='text-[12px] mt-[5px]'>{subscore}</span>}
				</div>
			</div>
		</div>
	</section>
);

export default RoundHighlightsCard;
