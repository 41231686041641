import { useMemo } from 'react';

import commonApi from '../common-api';
import toOrgDetails from '../transforms/toOrgDetails';

const useGetOrgDetailsQuery = () => {
	const { data, ...rest } = commonApi.useGetOrgDetailsQuery();

	return {
		orgDetails: useMemo(() => (data ? toOrgDetails(data) : undefined), [data]),
		...rest,
	};
};

export default useGetOrgDetailsQuery;
