import { useRef } from 'react';

import useUpdateEffect from './useUpdateEffect';

const useOnceEffect = (
	condition: boolean,
	effect: () => void | (() => void),
	deps?: ReadonlyArray<unknown>
) => {
	const hasRun = useRef(false);

	useUpdateEffect(
		() => {
			if (condition && !hasRun.current) {
				effect();
				hasRun.current = true;
			}
		},
		deps ? [...deps] : undefined
	);
};

export default useOnceEffect;
