import FullRoundHighlights from '../../../domain/models/full-round-highlights';
import { SlFullRoundHighlights } from '../schema';
import toParticipantHighlights from './toParticipantHighlights';

const toFullRoundHighlights = (slFullRoundHighlights: SlFullRoundHighlights) =>
	new FullRoundHighlights(
		slFullRoundHighlights.challengeId,
		slFullRoundHighlights.participantHighlights.map(x =>
			toParticipantHighlights(x)
		)
	);

export default toFullRoundHighlights;
