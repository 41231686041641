import { useEffect, useMemo, useState } from 'react';

import useGetEventChallengesQuery from '../../../../api/challenges/queries/useGetEventChallengesQuery';
import { useAppDispatch } from '../../../../redux/store';
import { clearAllSelected } from '../../../challenges/challenge-reducer';
import useGetMapParams from '../../hooks/useGetMapParams';
import useNavigateToPage from '../../hooks/useNavigateToPage';
import useWindowResize from '../../hooks/useWindowResize';

const useDrawer = (showTabs: boolean) => {
	const navigateToPage = useNavigateToPage();
	const dispatch = useAppDispatch();

	const { eventId, roundId, holeNumber } = useGetMapParams();

	const { eventChallenges } = useGetEventChallengesQuery(eventId);

	const [selectedHole, setSelectedHole] = useState<number>(holeNumber);

	const initialDrawerHeight = '33%';
	const [drawerHeight, setDrawerHeight] = useState<string>(initialDrawerHeight);

	const [isHalfScreen, setisHalfScreen] = useState<boolean>(true);

	const { width } = useWindowResize();
	const isWideScreen = width > 1048;

	const headerHeight = document.getElementById('app-header')?.clientHeight ?? 0;
	const eventHeaderHeight =
		document.getElementById('event-header')?.clientHeight ?? 0;

	const totalHeaderHeight = headerHeight + eventHeaderHeight;

	// bottom drawer has a margin bottom of 3rem, so also need to account for that in full screen height calc
	const fullScreenHeight = `calc(100% - ${totalHeaderHeight}px - ${
		showTabs ? '3rem' : '0rem'
	})`;

	const handleDrawerHeight = (touchDeltaY: number) => {
		const screenPercentage = (touchDeltaY / window.innerHeight) * 100;
		if (touchDeltaY === 0) {
			if (isHalfScreen === true) {
				setDrawerHeight(fullScreenHeight);
				setisHalfScreen(false);
			} else {
				setDrawerHeight(initialDrawerHeight);
				setisHalfScreen(true);
			}
		}
		if (screenPercentage > 33) {
			setisHalfScreen(false);
			setDrawerHeight(fullScreenHeight);
		}
		if (screenPercentage > 0 && screenPercentage < 33) {
			if (isHalfScreen === false) {
				setisHalfScreen(true);
				setDrawerHeight(initialDrawerHeight);
			} else {
				setisHalfScreen(false);
				setDrawerHeight(fullScreenHeight);
			}
		}
		if (screenPercentage < -33) {
			setisHalfScreen(false);
			setDrawerHeight('4%');
		}
		if (screenPercentage < 0 && screenPercentage > -33) {
			if (isHalfScreen === true) {
				setisHalfScreen(false);
				setDrawerHeight('4%');
			} else {
				setisHalfScreen(true);
				setDrawerHeight(initialDrawerHeight);
			}
		}
	};

	useEffect(() => {
		if (!isWideScreen) {
			setisHalfScreen(true);
			setDrawerHeight(initialDrawerHeight);
		} else {
			setDrawerHeight('85%');
		}
	}, [isWideScreen]);

	const holeList = useMemo(() => {
		if (eventChallenges) {
			const challenge = eventChallenges.challenges.find(x => x.id === roundId);
			return challenge?.holes.map(x => x.holeNumber);
		}
		return undefined;
	}, [eventChallenges]);

	const onHoleChange = (hole: string) => {
		setSelectedHole(+hole);

		// update the url string with the new hole number and navigate to it
		let url = `${window.location.pathname}${window.location.search}`;
		url = url.replace(/(holes\/)(\d+)/, `holes/${hole}`);

		dispatch(clearAllSelected());
		navigateToPage(url);
	};

	return {
		eventId,
		holeNumber,
		drawerHeight,
		isWideScreen,
		handleDrawerHeight,
		holeList,
		selectedHole,
		onHoleChange,
	};
};

export default useDrawer;
