/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import { IconType, MapType } from '../../../../domain/types/enum-types';
import BingMarker from '../Markers/BingMarker';
import GoogleMarker from '../Markers/GoogleMarker';
import './Map.scss';
import useBingMap from './hooks/useBingMap';
import useGoogleMap from './hooks/useGoogleMap';
import { MapProps } from './types';

export const Map = ({ children = [], mapType, ...mapProps }: MapProps) => {
	let map: google.maps.Map | Microsoft.Maps.Map | undefined;
	let flagMarker: JSX.Element | null = null;
	let teeBoxes: JSX.Element[] = [];

	if (mapType === MapType.google) {
		map = useGoogleMap({ ...mapProps });
		flagMarker = (
			<GoogleMarker
				iconType={IconType.flag}
				position={mapProps.center}
				map={map}
				key='google flag'
			/>
		);
		if (mapProps.teeLocations) {
			teeBoxes = mapProps.teeLocations.map(tee => (
				<GoogleMarker
					iconType={IconType.tee}
					position={tee.location}
					map={map as google.maps.Map | undefined}
					key={`google tee box ${tee.id}`}
				/>
			));
		}
	} else if (mapType === MapType.bing) {
		map = useBingMap({ ...mapProps });
		flagMarker = (
			<BingMarker
				iconType={IconType.flag}
				position={mapProps.center}
				map={map}
				// dynamic key is needed so it re-renders when children changes so that it draws on the map first and stays behind the shot markers
				key={`bing flag with ${React.Children.count(children)} markers`}
			/>
		);
		if (mapProps.teeLocations) {
			teeBoxes = mapProps.teeLocations.map(tee => (
				<BingMarker
					iconType={IconType.tee}
					position={tee.location}
					map={map as Microsoft.Maps.Map | undefined}
					key={`bing tee box ${tee.id}`}
				/>
			));
		}
	}

	return (
		<section className='map-container' id='MapContainer'>
			<section className='map' ref={mapProps.mapRef} />

			{/* Annoying but recommended hack by google maps in docs.
            Allows you to inject the map element into the Children
            map markers, related to reason we need the wrapper */}
			{React.Children.map(children, child => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					return React.cloneElement(child, { map });
				}
				return null;
			})}

			{flagMarker}
			{teeBoxes}
		</section>
	);
};

export default Map;
