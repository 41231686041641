import React, { SVGProps } from 'react';

import CapTechIcon from '../domain/types/captech-icon';

const LinkedInIcon = ({
	width,
	height,
	fill,
	...props
}: SVGProps<SVGSVGElement> & CapTechIcon) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill='non'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M26.1078 4.2666H6.59798C5.70401 4.2666 4.97766 5.00311 4.97766 5.90724V25.3815C4.97766 26.2857 5.70401 27.0222 6.59798 27.0222H26.1078C27.0018 27.0222 27.7332 26.2857 27.7332 25.3815V5.90724C27.7332 5.00311 27.0018 4.2666 26.1078 4.2666ZM11.8551 23.7714H8.48242V12.9117H11.8602V23.7714H11.8551ZM10.1688 11.4285C9.08687 11.4285 8.21322 10.5498 8.21322 9.47295C8.21322 8.39613 9.08687 7.5174 10.1688 7.5174C11.2456 7.5174 12.1243 8.39613 12.1243 9.47295C12.1243 10.5549 11.2507 11.4285 10.1688 11.4285ZM24.4977 23.7714H21.125V18.4888C21.125 17.2291 21.0996 15.6088 19.3726 15.6088C17.6151 15.6088 17.3459 16.9803 17.3459 18.3974V23.7714H13.9732V12.9117H17.2088V14.3949H17.2545C17.7065 13.5415 18.8088 12.6425 20.4494 12.6425C23.8627 12.6425 24.4977 14.8926 24.4977 17.8183V23.7714Z'
			fill={fill}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM26.311 6.39997H6.39989V25.6H26.311V6.39997Z'
			fill={fill}
		/>
	</svg>
);

export default LinkedInIcon;
