import alphaNumericSort from '../../../features/shared/helper-functions';
import ChallengeType from '../../types/challenge-type';
import FullRoundGroup from '../group/full-round-group';
import Hole from '../hole';

export default class FullRoundChallengeDetails {
	constructor(
		public challengeType: ChallengeType,
		public groups: FullRoundGroup[],
		public hole: Hole
	) {}

	get participantsWithGroupDetails() {
		return this.groups.flatMap(group =>
			group.participants.flatMap(participant => ({
				groupName: group.name,
				...participant,
			}))
		);
	}

	get participantsSorted() {
		return [
			...this.participantsWithGroupDetails.sort(
				(p1, p2) =>
					alphaNumericSort(p1.firstName, p2.firstName) ||
					alphaNumericSort(p1.lastName, p2.lastName)
			),
		];
	}
}
