import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { OVERLAY_MAPS_URL } from '../api-config';
import { createGetQuery } from '../api-util';
import MapsOverlayResponse from './schema/maps-overlay-response';

const mapsApi = createApi({
	reducerPath: 'mapsApi',
	baseQuery: fetchBaseQuery({ baseUrl: OVERLAY_MAPS_URL }),
	endpoints: builder => ({
		getOverlayConfig: builder.query<
			MapsOverlayResponse,
			{ eventId: number; holeNumber: number }
		>({
			query: ({ eventId, holeNumber }) =>
				createGetQuery(`/event_${eventId}_hole_${holeNumber}.json`),
		}),
	}),
});

export const { useLazyGetOverlayConfigQuery } = mapsApi;

export default mapsApi;
