import classNames from 'classnames';
import React from 'react';

import ChallengeType from '../../../../../domain/types/challenge-type';
import { StrokeLocation } from '../../../../../domain/types/enum-types';
import GreenTriangle from '../../../../../icons/GreenTriangle';
import PlayerStroke from '../../models/player-stroke';
import './LeaderCard.scss';
import useLeaderCard from './useLeaderCard';

interface Props {
	strokeData: PlayerStroke;
	boxShadow: boolean;
	bottomText: string;
	avgDistance: number | undefined;
	challengeType?: ChallengeType;
}

const LeaderCard = ({
	strokeData,
	boxShadow,
	bottomText,
	avgDistance,
	challengeType = ChallengeType.PinPoint,
}: Props) => {
	const { fullName, ballDistance, playerRankForHole, strokeTime } = strokeData;

	const { isSectionSelected, selectStroke } = useLeaderCard(strokeData);

	return (
		<section
			className={`w-full mb-[0.375rem] bg-white rounded-[6px] leader-card ${
				isSectionSelected ? 'selected' : ''
			}`}
			style={{
				border: isSectionSelected
					? '2px solid rgba(0, 56, 101, 0.3)'
					: '1px solid #EDEDED',
				boxShadow: boxShadow ? '4px 4px 20px rgba(0,0,0,0.07)' : 'none',
			}}
			onKeyDown={selectStroke}
			onClick={selectStroke}
			role='button'
			tabIndex={0}
		>
			<div className='flex items-center justify-between leader-card__inner'>
				<div
					className={classNames(
						'mr-[1rem] rounded-full border-[4px] font-sans font-bold leadercard__rank-number',
						{
							'border-[#F8F8F8]': !isSectionSelected,
							'border-[#eaedc2]': isSectionSelected,
							'bg-electric-green': isSectionSelected,
						}
					)}
				>
					{playerRankForHole}
				</div>
				<div className='flex flex-col mr-[0.8125rem]'>
					<p
						className='text-[16px]'
						style={{
							fontWeight: isSectionSelected ? 'bold' : 'normal',
						}}
					>
						{fullName}
					</p>
					<p className='text-text-gray text-[12px] mt-[-0.3rem] mb-[0.33rem]'>
						{bottomText}
					</p>
					<p className='text-text-gray text-[12px]'>
						{new Date(strokeTime)
							.toLocaleTimeString([], {
								timeStyle: 'short',
							})
							.toLowerCase()}
					</p>
				</div>
				<div className='ml-auto'>
					{ballDistance === null && (
						<div className='leaderCard__distance-number'>--</div>
					)}
					{ballDistance !== null && (
						<div className='leaderCard__distance-number'>
							{avgDistance && (
								<>
									{challengeType !== ChallengeType.LongestDrive &&
										ballDistance <= avgDistance &&
										(strokeData.strokeLocation === StrokeLocation.green ||
											strokeData.strokeLocation === StrokeLocation.hole) && (
											<GreenTriangle />
										)}
									{challengeType === ChallengeType.LongestDrive &&
										ballDistance >= avgDistance &&
										(strokeData.strokeLocation === StrokeLocation.green ||
											strokeData.strokeLocation === StrokeLocation.fairway ||
											strokeData.strokeLocation === StrokeLocation.hole) && (
											<GreenTriangle />
										)}
								</>
							)}
							{challengeType !== ChallengeType.LongestDrive && (
								<span>{ballDistance.toFixed(1)}&apos;</span>
							)}
							{challengeType === ChallengeType.LongestDrive && (
								<span>
									{ballDistance.toFixed(1)}
									<span className='text-[14px]'>yds</span>
								</span>
							)}
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default LeaderCard;
