import { useMemo } from 'react';

import { useAppSelector } from '../../../../redux/store';
import Player from '../models/player';

/**
 * Find the first player that contains a stroke id that is in the selected store stroke ids
 * @param players
 */
const useGetSelectedPlayer = (players: Player[]) => {
	const { selectedStrokeIds } = useAppSelector(state => state.ctp);

	return useMemo(
		() => players?.find(x => x.includesStroke(selectedStrokeIds)),
		[players, selectedStrokeIds]
	);
};

export default useGetSelectedPlayer;
