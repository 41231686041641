import React, { SVGProps } from 'react';

const ArrowRightIcon = ({
	width,
	height,
	fill,
	...props
}: SVGProps<SVGSVGElement>) => (
	<svg
		width={width || 12}
		height={height || 12}
		viewBox='0 0 12 12'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z'
			fill={fill || 'white'}
		/>
	</svg>
);

export default ArrowRightIcon;
