/* eslint-disable react-hooks/rules-of-hooks */
import { Wrapper as GoogleMapWrapper, Status } from '@googlemaps/react-wrapper';
import React, { useMemo } from 'react';

import { MapType } from '../../../../domain/types/enum-types';
import Map from './Map';
import './Map.scss';
import { MapProps } from './types';

// Have to wrap it in order to use the useGoogleMaps hook above.
// The wrapper adds google to the window element, and is undefined
// if at the same scope
const GoogleMap = ({ children, ...mapProps }: MapProps) => {
	const MAPS_API_KEY = process.env.REACT_APP_MAP_API_KEY || '';

	const render = (status: Status) => {
		switch (status) {
			case Status.LOADING:
				return <section className='map'>Map is Loading</section>;
			case Status.SUCCESS:
				return (
					<Map {...mapProps} mapType={MapType.google}>
						{children}
					</Map>
				);
			case Status.FAILURE:
			default:
				return <section className='map'>Map Failed to Load</section>;
		}
	};

	return (
		<GoogleMapWrapper
			apiKey={MAPS_API_KEY}
			render={render}
			libraries={useMemo(() => ['geometry'], [])}
		/>
	);
};

export default GoogleMap;
