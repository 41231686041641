import Lottie from 'lottie-react';
import React from 'react';

import states from '../../../../domain/constants/states';
import LeaderboardParticipant from '../../../../domain/models/participant/leaderboard-participant';
import { DivisionType } from '../../../../domain/types/enum-types';
import fireworksAnimation from '../../../../icons/lottie/fireworks.json';
import './LeaderCard.scss';

interface Props {
	player: LeaderboardParticipant;
	rank: number;
	shotsOnGreen: {
		currentShot: boolean;
		nextShot: boolean;
		isNextShotNull: boolean;
	};
	showDivision?: boolean;
	customStyles?: { [key: string]: string };
}

const divisionTypeMapping = {
	amateur: 'AM',
	junior: 'JR',
	professional: 'PRO',
	default: '',
};

const LeaderCard = ({
	player,
	rank,
	shotsOnGreen,
	customStyles,
	showDivision = true,
}: Props) => {
	let { gender } = player;
	if (gender === 'Man') gender = 'Men';
	else if (gender === 'Woman') gender = 'Women';

	const abbreviatedState = states.find(
		x => x.value === player.state
	)?.abbreviation;

	let location = player.city;
	if (abbreviatedState) {
		if (!location) location = abbreviatedState;
		else location += `, ${abbreviatedState}`;
	}

	const divisionType =
		divisionTypeMapping[
			(player.division ??
				divisionTypeMapping.default) as keyof typeof divisionTypeMapping
		];

	return (
		<div className='marketing-leaderboard__leader-card-wrapper'>
			<div className='marketing-leaderboard__leader-card' style={customStyles}>
				<div className='leader-card__player-info'>
					<div className='leader-card__player-info__title'>
						<span>{rank}</span>
						<span>
							{player.firstName[0]}. <b>{player.lastName}</b>
						</span>
					</div>
					<div className='leader-card__player-info__location'>
						{showDivision && (divisionType || location) && (
							<div>
								{divisionType} {gender}
							</div>
						)}
						{player.division === DivisionType.junior && (
							<span>{player.description}</span>
						)}
						{player.division !== DivisionType.junior && <span>{location}</span>}
					</div>
				</div>
				{player.distanceToHole && (
					<div
						className='leader-card__player-rank'
						style={{
							border: shotsOnGreen.currentShot ? '' : '0.0625em solid white',
							borderRadius: player.distanceToHole.feet === 0 ? '50%' : '0',
							backgroundColor: shotsOnGreen.currentShot ? '#CCDB2A' : '',
							color: shotsOnGreen.currentShot ? 'black' : 'white',
						}}
					>
						<span>{`${player.distanceToHole.feet.toFixed(1)}'`}</span>
						{player.distanceToHole.feet === 0 && (
							<Lottie
								animationData={fireworksAnimation}
								loop
								autoplay
								style={{
									width: '7.375em',
									height: '7.375em',
									position: 'absolute',
									top: '-20%',
									left: '-20%',
								}}
							/>
						)}
					</div>
				)}
			</div>
			<div className='marketing-leaderboard__divider'>
				{shotsOnGreen.isNextShotNull && <span>NOT YET HIT</span>}
				{!shotsOnGreen.isNextShotNull &&
					shotsOnGreen.currentShot &&
					!shotsOnGreen.nextShot && <span>OFF THE GREEN</span>}
			</div>
		</div>
	);
};

export default LeaderCard;
