import React from 'react';

import Challenge from '../../../../domain/models/challenge';
import ChallengeType from '../../../../domain/types/challenge-type';
import './ChallengeDetailsCard.scss';
import CommonDetailsCard from './CommonDetailsCard';
import FullRoundDetailsCard from './FullRoundDetailsCard';

interface Props {
	eventId: number;
	challenge: Challenge;
}

const ChallengeDetailsCard = ({ eventId, challenge }: Props) => {
	if (
		challenge.type === ChallengeType.PinPoint ||
		challenge.type === ChallengeType.PinPointLeaderboard ||
		challenge.type === ChallengeType.LongestDrive
	) {
		return <CommonDetailsCard challenge={challenge} eventId={eventId} />;
	}

	// if it's not pinpoint or leaderboard or longest drive, then return full round
	return <FullRoundDetailsCard challenge={challenge} />;
};

export default ChallengeDetailsCard;
