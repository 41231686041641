import React, { SVGProps } from 'react';

const ChevronRightIcon = ({
	width,
	height,
	fill,
	...props
}: SVGProps<SVGSVGElement>) => (
	<svg
		width={width || 5}
		height={height || 9}
		fill='none'
		xmlns='http://www.w3.org/2000/svg' 
		{...props}
	>
		<path
			d='M5 4.512c0 .079-.013.151-.039.218a.605.605 0 0 1-.135.198L1 8.85a.524.524 0 0 1-.415.148.587.587 0 0 1-.416-.168c-.129-.132-.184-.274-.164-.426a.753.753 0 0 1 .183-.406L3.59 4.512.187 1.024A.655.655 0 0 1 .013.598.474.474 0 0 1 .187.172.421.421 0 0 1 .593.004c.168.02.31.082.425.188l3.808 3.903c.064.067.11.133.135.199A.595.595 0 0 1 5 4.512Z'
			fill={fill || 'currentColor'}
		/>
	</svg>
);

export default ChevronRightIcon;