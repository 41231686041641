import LatLong from '../../../domain/types/lat-long';

export default class MapMarker {
	constructor(
		public ids: number[],
		public location: LatLong,
		public popupContent: string,
		public label?: string
	) {}

	static clone(mapMarker: MapMarker) {
		return new MapMarker(
			mapMarker.ids,
			mapMarker.location,
			mapMarker.popupContent,
			mapMarker.label
		);
	}

	includesId(ids: number[]) {
		return this.ids.some(x => ids.includes(x));
	}
}
