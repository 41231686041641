import { useCallback, useRef, useState } from 'react';

const useDropdown = () => {
	const dropdownRef = useRef<HTMLDivElement>(null);

	const [isDropdownOpen, setIsOpen] = useState(false);
	const [dropdownHeight, setDropdownHeight] = useState(0);

	return {
		isDropdownOpen,
		dropdownHeight,
		openDropdown: useCallback(() => {
			setIsOpen(true);
			setDropdownHeight(
				dropdownRef.current?.getBoundingClientRect().height ?? 0
			);
		}, [dropdownRef]),
		closeDropdown: useCallback(() => {
			setIsOpen(false);
			setDropdownHeight(0);
		}, []),
		dropdownRef,
	};
};

export default useDropdown;
