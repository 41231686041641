import React, { useEffect, useState } from 'react';

import ChevronUpIcon from '../../../../icons/ChevronUpIcon';
import useModal from '../../hooks/useModal';
import Modal from '../../layout/Modal/Modal';
import RadioButton from '../RadioButton/RadioButton';
import './RadioButtonDrawer.scss';

interface InputData {
	value: boolean;
	label: string;
}

interface Props {
	label: string;
	listValues: string[];
	currentLabel: string;
	onSubmitHandler: (label: string) => void;
}

const RadioButtonDrawer = (props: Props) => {
	const { label, listValues, currentLabel, onSubmitHandler } = props;

	const { isModalOpen, toggleModal } = useModal();

	const [formData, setFormData] = useState<InputData[]>([]);
	const [dropdownLabel, setDropdownLabel] = useState<string>('');

	const handleSelectChange = (data: InputData): void => {
		setFormData(
			formData.map(input => ({
				...input,
				value: data.label === input.label,
			}))
		);
	};

	const setDefaultFormData = () => {
		setFormData(
			listValues.map(x => ({
				value: currentLabel === x,
				label: x,
			}))
		);

		setDropdownLabel(label === 'View' ? currentLabel : label);
	};

	const cancelModal = () => {
		setDefaultFormData();

		toggleModal();
	};

	const handleSubmit = () => {
		toggleModal();

		onSubmitHandler(formData.find(x => x.value)?.label ?? '');
	};

	useEffect(() => {
		setDefaultFormData();
	}, [isModalOpen]);

	return (
		<>
			<button
				type='button'
				className='bottom-drawer-action__button'
				onClick={toggleModal}
			>
				<span className='bottom-drawer-action__span'>{dropdownLabel}</span>
				<div className='bottom-drawer-action__arrow inverted'>
					<ChevronUpIcon />
				</div>
			</button>
			<Modal
				isVisible={isModalOpen}
				toggleModal={cancelModal}
				handleSubmit={handleSubmit}
				customStyles={{ padding: '1.25rem' }}
				showBottomButtons
			>
				<h2>{label}</h2>
				<div className='bottom-drawer-filters__section'>
					<ul>
						{formData.map(input => (
							<li key={input.label} style={{ padding: '10px 0' }}>
								<RadioButton
									value={input.value}
									onChange={() => {
										handleSelectChange({
											value: input.value,
											label: input.label,
										});
									}}
									label={input.label}
								/>
							</li>
						))}
					</ul>
				</div>
			</Modal>
		</>
	);
};

export default RadioButtonDrawer;
