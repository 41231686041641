/* eslint-disable import/prefer-default-export */
import {
	SlFullRoundChallengeParticipant,
	SlLeaderboardParticipant,
	SlPinPointChallengeParticipant,
} from '../../../api/challenges/schema';
import FullRoundParticipant from '../../../domain/models/participant/full-round-participant';
import LeaderboardParticipant from '../../../domain/models/participant/leaderboard-participant';
import PinPointParticipant from '../../../domain/models/participant/pin-point-participant';
import SortService from '../../../domain/services/SortService';
import toStroke from './toStroke';

export const toPinPointParticipant = (
	slPinPointChallengeParticipant: SlPinPointChallengeParticipant
) =>
	new PinPointParticipant(
		slPinPointChallengeParticipant.id,
		slPinPointChallengeParticipant.groupId,
		slPinPointChallengeParticipant.firstName,
		slPinPointChallengeParticipant.lastName,
		slPinPointChallengeParticipant.age,
		slPinPointChallengeParticipant.division,
		toStroke(slPinPointChallengeParticipant.stroke)
	);

export const toFullRoundParticipant = (
	slFullRoundChallengeParticipant: SlFullRoundChallengeParticipant
) =>
	new FullRoundParticipant(
		slFullRoundChallengeParticipant.id,
		slFullRoundChallengeParticipant.groupId,
		slFullRoundChallengeParticipant.firstName,
		slFullRoundChallengeParticipant.lastName,
		slFullRoundChallengeParticipant.age,
		slFullRoundChallengeParticipant.division,
		slFullRoundChallengeParticipant.strokes.map(x => toStroke(x))
	);

export const toLeaderboardParticipant = (
	slLeaderboardParticipant: SlLeaderboardParticipant
) =>
	new LeaderboardParticipant(
		slLeaderboardParticipant.id,
		slLeaderboardParticipant.groupId,
		slLeaderboardParticipant.firstName,
		slLeaderboardParticipant.lastName,
		slLeaderboardParticipant.city,
		slLeaderboardParticipant.state,
		slLeaderboardParticipant.description,
		slLeaderboardParticipant.gender,
		slLeaderboardParticipant.age,
		slLeaderboardParticipant.division,
		slLeaderboardParticipant.distanceToHole
			? SortService.updateHoleInOneDistance(
					slLeaderboardParticipant.courseLocation,
					slLeaderboardParticipant.distanceToHole
			  )
			: slLeaderboardParticipant.distanceToHole,
		slLeaderboardParticipant.courseLocation
	);
