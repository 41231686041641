import Cluster from './cluster';

export default class Team {
	constructor(
		public id: number,
		public cluster: Cluster,
		public name: string
	) {}

	get playerStrokes() {
		return this.cluster.playerStrokes;
	}
}
