import { useMemo } from 'react';

import analyticsApi from '../analytics-api';
import toGameHighlights from '../transforms/toGameHighlights';

interface Props {
	eventId: number;
	roundId: number;
	holeNumber: number;
}

const useGetGameHighlightsQuery = (props: Props) => {
	const { eventId, roundId, holeNumber } = props;

	const { data, refetch, ...rest } = analyticsApi.useGetGameHighlightsQuery({
		eventId,
		roundId,
		holeNumber,
	});

	return {
		gameHighlights: useMemo(
			() => (data ? toGameHighlights(data) : undefined),
			[data]
		),
		refetchRound: refetch,
		...rest,
	};
};

export default useGetGameHighlightsQuery;
