/* eslint-disable no-shadow */
export enum SortOption {
	distanceAscending = 'Highest Rank',
	distanceDescending = 'Lowest Rank',
	alphaAscending = 'Name (A-Z)',
	alphaDescending = 'Name (Z-A)',
}

export enum ViewOption {
	playerView = 'Player View',
	teamView = 'Team View',
}

export enum OrgLinkType {
	about = 'about',
	contact = 'contact',
	linkedin = 'linkedin',
	twitter = 'twitter',
	youtube = 'youtube',
	other = 'other',
}

export enum IconType {
	flag = 'flag',
	tee = 'tee',
	cluster = 'cluster',
}

export enum MapType {
	bing = 'bing',
	google = 'google',
}

export enum TeeType {
	long = 'long',
	default = 'default',
	short = 'short',
}

export enum StrokeLocation {
	green = 'green',
	fairway = 'fairway',
	rough = 'rough',
	sand = 'sand',
	hole = 'hole',
	water = 'water',
	lost = 'lost',
	out = 'out of bounds',
	drop = 'drop',
}

export enum DivisionType {
	junior = 'junior',
	amateur = 'amateur',
	professional = 'professional',
}

export enum WebSocketType {
	add_stroke = 'add_stroke',
	update_stroke = 'update_stroke',
	delete_stroke = 'delete_stroke',
	participant_check_in = 'participant_check_in',
}
