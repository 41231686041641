import React, { SVGProps } from 'react';

const RetryIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={14}
		height={14}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M7 14C5.06042 14 3.40885 13.3182 2.04531 11.9547C0.681771 10.5911 0 8.93958 0 7C0 5.06042 0.681771 3.40885 2.04531 2.04531C3.40885 0.681771 5.06042 0 7 0C8.23958 0 9.32604 0.251563 10.2594 0.754688C11.1927 1.25781 12.0021 1.94687 12.6875 2.82187V0H14V5.55625H8.44375V4.24375H12.1188C11.5646 3.36875 10.8573 2.66146 9.99687 2.12188C9.13646 1.58229 8.1375 1.3125 7 1.3125C5.41042 1.3125 4.0651 1.86302 2.96406 2.96406C1.86302 4.0651 1.3125 5.41042 1.3125 7C1.3125 8.58958 1.86302 9.9349 2.96406 11.0359C4.0651 12.137 5.41042 12.6875 7 12.6875C8.21042 12.6875 9.31875 12.3411 10.325 11.6484C11.3313 10.9557 12.0313 10.0406 12.425 8.90312H13.7812C13.3583 10.4344 12.5198 11.6667 11.2656 12.6C10.0115 13.5333 8.58958 14 7 14Z'
			fill='#003865'
		/>
	</svg>
);

export default RetryIcon;
