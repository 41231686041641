import Distance from '../types/distance';
import { TeeType } from '../types/enum-types';
import LatLong from '../types/lat-long';

export default class TeeLocation {
	constructor(
		public id: number,
		public holeConfigId: number,
		public location: LatLong,
		public type: TeeType,
		public holeTeeHeading: number,
		public holeTeeDistance: Distance
	) {}
}
