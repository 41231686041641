import { useMemo } from 'react';

import FullRoundHighlights from '../../../../domain/models/full-round-highlights';
import ParticipantHighlights from '../../../../domain/models/participant-highlights';
import Distance from '../../../../domain/types/distance';
import { useAppSelector } from '../../../../redux/store';

const useGetFullRoundHighlights = (
	roundHighlights: FullRoundHighlights | undefined
) => {
	const { searchValue } = useAppSelector(state => state.ctp);

	const sortAndRankList = (
		list: ParticipantHighlights[] | undefined,
		indexStr: string,
		secondIndexStr?: string,
		reverseOrder = false
	) => {
		let rank = 1;
		return (
			list
				?.sort((a, b) => {
					let firstVal = a[indexStr as keyof ParticipantHighlights];
					let secondVal = b[indexStr as keyof ParticipantHighlights];

					if (firstVal === null) {
						return 1;
					}
					if (secondVal === null) {
						return -1;
					}

					// if value is provided, it means there's a second object level we need to parse into to grab the correct sorting value
					if (secondIndexStr) {
						firstVal =
							firstVal[secondIndexStr as keyof (string | number | Distance)];
						secondVal =
							secondVal[secondIndexStr as keyof (string | number | Distance)];
					}
					return reverseOrder ? +firstVal - +secondVal : +secondVal - +firstVal;
				})
				.map((x, idx, arr) => {
					// update rank only if previous value is different than new value
					if (idx > 0) {
						let currValue = x[indexStr as keyof (string | number | Distance)];
						let prevValue = arr[idx - 1];
						prevValue =
							prevValue[indexStr as keyof (string | number | Distance)];

						if (secondIndexStr && currValue !== null && prevValue !== null) {
							currValue =
								currValue[secondIndexStr as keyof (string | number | Distance)];
							prevValue =
								prevValue[secondIndexStr as keyof (string | number | Distance)];
						}

						if (currValue !== prevValue) {
							rank = idx + 1;
						}
					}

					return {
						...x,
						fullName: x.fullName,
						rank,
					};
				})
				// need to filter last because we want to keep player rank even if everybody else is filtered out
				.filter(x => x.fullName.toLocaleLowerCase().includes(searchValue))
		);
	};

	return useMemo(() => {
		const greensInRegulationSorted = sortAndRankList(
			roundHighlights?.participantHighlights,
			'greensInRegulation'
		);

		const averageDriveSorted = sortAndRankList(
			roundHighlights?.participantHighlights,
			'averageDriveDistance',
			'yards'
		);

		const averageDistanceToPinSorted = sortAndRankList(
			roundHighlights?.participantHighlights,
			'averageDistanceToPin',
			'feet',
			true
		);

		return {
			greensInRegulationSorted,
			averageDriveSorted,
			averageDistanceToPinSorted,
		};
	}, [roundHighlights, searchValue]);
};

export default useGetFullRoundHighlights;
