import { useCallback, useEffect, useState } from 'react';

import ChallengeType from '../../../../../domain/types/challenge-type';
import { MapType } from '../../../../../domain/types/enum-types';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
	clearPanTo,
	setInitialZoomLevel,
	setManualMapScale,
} from '../../../../challenges/challenge-reducer';
import useWindowResize from '../../../hooks/useWindowResize';
import {
	getCircleColors,
	mapColors,
} from '../../Markers/marker-helper-functions';
import { useMapProps } from '../types';

const useBingMap = ({
	mapRef,
	challengeType,
	center,
	radius,
	teeLocations,
	panTo,
	fitBounds,
	shotLines,
	updateMapElements,
	onMapIdle,
	onMapClick,
	useDefaultMapZoom,
}: useMapProps) => {
	const { white, grey, greenOverlay } = mapColors();

	const [map, setMap] = useState<Microsoft.Maps.Map>();
	const [isMapLoading, setIsMapLoading] = useState<boolean>(true);
	const { isMobileScreen } = useWindowResize();

	const [avgDistanceCircle, setAvgDistanceCircle] =
		useState<Microsoft.Maps.Polygon>();

	const [lineCoordinates, setLineCoordinates] = useState<
		Microsoft.Maps.Polyline[]
	>([]);

	const [overlay, setOverlay] = useState<object>();

	const { preventPanTo, manualMapScale, zoomLevel, selectedStrokeIds } =
		useAppSelector(state => state.ctp);
	const dispatch = useAppDispatch();

	// TODO : try to get map to rotate in future story card
	// const heading = useMemo(() => {
	// 	const defaultTee = teeLocations?.find(
	// 		x => x.type === teeType
	// 	)?.location;

	// 	if (defaultTee && defaultTee.latitude !== 0 && defaultTee.longitude !== 0) {
	// 		const teePoint = new google.maps.LatLng(
	// 			defaultTee.latitude,
	// 			defaultTee.longitude
	// 		);
	// 		const pinPoint = new google.maps.LatLng(
	// 			center.latitude,
	// 			center.longitude
	// 		);

	// 		return google.maps.geometry.spherical.computeHeading(teePoint, pinPoint);
	// 	}

	// 	return 0;
	// }, [teeLocations, center, teeType]);

	const makeMap = useCallback(() => {
		if (mapRef?.current && !map) {
			const newMap = new Microsoft.Maps.Map(mapRef.current, {
				credentials: process.env.REACT_APP_BING_MAP_API_KEY || '',
				center: new Microsoft.Maps.Location(center.latitude, center.longitude),
				mapTypeId: Microsoft.Maps.MapTypeId.aerial,
				zoom: 20,
				minZoom: 14,
				labelOverlay: Microsoft.Maps.LabelOverlay.hidden,
				showMapTypeSelector: false,
				showLocateMeButton: false,
				showZoomButtons: false,
				disableStreetside: true,
				disableZooming: true,
				liteMode: true,
			});
			setMap(newMap);

			const initialScale = isMobileScreen ? 3 : 1;

			// set initial zoom and scale level for map
			dispatch(setInitialZoomLevel(newMap?.getZoom()));
			dispatch(setManualMapScale(initialScale));

			Microsoft.Maps.loadModule('Microsoft.Maps.SpatialMath', () => {
				setIsMapLoading(false);

				setTimeout(() => {
					updateMapElements?.('center', initialScale);
				}, 500);
			});
		}
	}, []);

	// need to show a green overlay when shots are selected for full round challenge type
	useEffect(() => {
		if (map) {
			// have to put this class in this useEffect rather than at top of component, because Microsoft Maps class isn't initialized until makeMaps function is ran
			class CustomOverlay extends Microsoft.Maps.CustomOverlay {
				private div?: HTMLElement;

				onAdd(): void {
					this.div = document.createElement('div');
					this.div.style.position = 'absolute';
					this.div.style.top = '0';
					this.div.style.left = '-50%';
					this.div.style.width = '100vw';
					this.div.style.height = '100vh';
					this.div.style.background = greenOverlay;
					this.setHtmlElement(this.div);
				}
			}

			if (overlay) {
				map.layers.remove(overlay);
			}
			if (
				challengeType === ChallengeType.FullRound &&
				selectedStrokeIds.length > 0
			) {
				const newOverlay: CustomOverlay = new CustomOverlay();
				map.layers.insert(newOverlay);
				setOverlay(newOverlay);
			}
		}
	}, [map, selectedStrokeIds]);

	// switch between manual and default map zoom
	useEffect(() => {
		map?.setOptions({ disableZooming: !useDefaultMapZoom });
	}, [useDefaultMapZoom]);

	// make sure clustering algo has up to date zoom level
	useEffect(() => {
		if (map) {
			onMapIdle(0, map.getZoom());
		}
	}, [map?.getZoom()]);

	// after zooming in/out of map, re-center it around the hole
	useEffect(() => {
		if (!fitBounds) {
			map?.setView({
				center: new Microsoft.Maps.Location(center.latitude, center.longitude),
			});
		}
	}, [map, zoomLevel, center]);

	useEffect(() => {
		if (lineCoordinates.length > 0) {
			lineCoordinates.forEach(coord => {
				map?.entities.remove(coord);
			});
		}
		if (map && shotLines) {
			const newCoordinates: Microsoft.Maps.Polyline[] = [];
			shotLines.forEach(x => {
				const coordinates = x.locations.map(
					coord => new Microsoft.Maps.Location(coord.latitude, coord.longitude)
				);

				const linePath = new Microsoft.Maps.Polyline(coordinates, {
					strokeColor: x.isSelected ? white : grey,
					strokeThickness: (x.isSelected ? 2 : 1) / manualMapScale,
				});

				// only show shot lines if a player is selected or if nothing is selected
				if (x.isSelected || (!x.isSelected && selectedStrokeIds.length === 0)) {
					newCoordinates.push(linePath);
					map.entities.push(linePath);
				}
			});
			setLineCoordinates(newCoordinates);
		}
	}, [map, shotLines, manualMapScale, selectedStrokeIds]);

	useEffect(() => {
		if (map && avgDistanceCircle) {
			map.entities.remove(avgDistanceCircle);
		}
		if (map && !isMapLoading && radius) {
			const locs = Microsoft.Maps.SpatialMath.getRegularPolygon(
				new Microsoft.Maps.Location(center.latitude, center.longitude),
				radius,
				36
			);

			const {
				fill,
				stroke,
				strokeColor: { r, g, b, a },
			} = getCircleColors(MapType.bing, manualMapScale);

			// Draw average distance from pin circle
			const newCircle = new Microsoft.Maps.Polygon(locs, {
				fillColor: fill,
				strokeThickness: stroke,
				strokeColor: new Microsoft.Maps.Color(a, r, g, b),
			});

			map.entities.push(newCircle);
			setAvgDistanceCircle(newCircle);
		}
	}, [map, isMapLoading, radius, manualMapScale]);

	useEffect(() => {
		if (
			map &&
			panTo &&
			panTo.latitude !== 0 &&
			panTo.longitude !== 0 &&
			!preventPanTo
		) {
			map.setView({
				center: new Microsoft.Maps.Location(panTo.latitude, panTo.longitude),
			});

			// clear pan after panning to spot
			dispatch(clearPanTo());
		}
	}, [panTo, preventPanTo]);

	useEffect(() => {
		if (map && fitBounds) {
			const bounds = Microsoft.Maps.LocationRect.fromLocations(
				fitBounds.map(x => new Microsoft.Maps.Location(x.latitude, x.longitude))
			);
			map.setView({ bounds });
		}
	}, [map, fitBounds, preventPanTo]);

	useEffect(() => {
		let zoomId: Microsoft.Maps.IHandlerId;
		let clickId: Microsoft.Maps.IHandlerId;

		if (map) {
			// kick off re-cluster after map has been idle for 500ms
			let timeoutId: number;
			zoomId = Microsoft.Maps.Events.addHandler(map, 'viewchangeend', () => {
				timeoutId = onMapIdle(timeoutId, map.getZoom());
			});

			clickId = Microsoft.Maps.Events.addHandler(map, 'click', onMapClick);
		}
		return () => {
			if (map) {
				mapRef = null;
				Microsoft.Maps.Events.removeHandler(zoomId);
				Microsoft.Maps.Events.removeHandler(clickId);
			}
		};
	}, [map, manualMapScale]);

	useEffect(() => {
		const scriptTag = document.createElement('script');
		scriptTag.setAttribute('type', 'text/javascript');
		scriptTag.setAttribute(
			'src',
			'https://www.bing.com/api/maps/mapcontrol?callback=makeMap'
		);
		scriptTag.async = true;
		scriptTag.defer = true;
		document.head.appendChild(scriptTag);
		scriptTag.onload = () => {
			// need to give it slight delay to load the scripts
			setTimeout(() => {
				makeMap();
			}, 500);
		};
	}, [makeMap]);

	return map;
};

export default useBingMap;
