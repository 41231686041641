import Event from '../../../domain/models/event';
import { SlEvent } from '../schema';
import { toSocialLink } from './toOrgDetails';

const toEvent = (slEvent: SlEvent) =>
	new Event(
		slEvent.id,
		slEvent.name,
		slEvent.venueName,
		slEvent.orgId,
		slEvent.address,
		slEvent.city,
		slEvent.state,
		slEvent.zipCode,
		slEvent.description,
		slEvent.startDate,
		slEvent.endDate,
		slEvent.links.map(x => toSocialLink(x)),
	);

export default toEvent;
