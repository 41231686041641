import React, { MouseEventHandler } from 'react';

import RetryIcon from '../../../../icons/RetryIcon';
import './ErrorBanner.scss';

interface ErrorBannerProps {
	// turn off type checking
	click?: MouseEventHandler;
	message: string;
	classes?: string;
}

const ErrorBanner = ({ click, message, classes }: ErrorBannerProps) => (
	<div className={`error-banner ${classes}`}>
		{message}
		{click && (
			<button
				className='error-banner__refresh-button'
				type='button'
				onClick={click}
			>
				<RetryIcon />
				Retry
			</button>
		)}
	</div>
);

export default ErrorBanner;
