import Distance from '../types/distance';
import { StrokeLocation } from '../types/enum-types';

export default class SortService {
	static sortStrokesByScore(
		distance1: number | null,
		distance2: number | null,
		isAscending = true
	) {
		if (distance1 === distance2) return 0;

		// nulls sort after anything else
		if (distance1 === null) return 1;
		if (distance2 === null) return -1;

		// if ascending, lowest sorts first
		if (isAscending) return distance1 < distance2 ? -1 : 1;

		// if descending, highest sorts first
		return distance1 < distance2 ? 1 : -1;
	}

	static updateHoleInOneDistance(
		courseLocation: StrokeLocation,
		distance: Distance
	) {
		// if player hits hole in one, then change distance to hole to be 0
		return courseLocation === StrokeLocation.hole
			? {
					yards: 0,
					meters: 0,
					feet: 0,
			  }
			: distance;
	}
}
