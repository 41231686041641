import React, { useEffect, useRef } from 'react';

import { useFullRoundChallengeDetailsQuery } from '../../../../../api/challenges/queries/useGetChallengeDetailsQuery';
import WarningCircleIcon from '../../../../../icons/WarningCircleIcon';
import { useAppDispatch } from '../../../../../redux/store';
import useGetMapParams from '../../../../shared/hooks/useGetMapParams';
import { clearSelectedStrokes, runSearch } from '../../../challenge-reducer';
import SearchInput from '../../../shared/components/SearchInput/SearchInput';
import useGetPlayers from '../../selectors/useGetPlayers';
import useGetSelectedPlayer from '../../selectors/useGetSelectedPlayer';
import LeaderCard from '../LeaderCard/LeaderCard';

const Leaderboard = () => {
	const dispatch = useAppDispatch();

	const { eventId, roundId, holeNumber } = useGetMapParams();

	const { challengeDetails } = useFullRoundChallengeDetailsQuery(
		eventId,
		roundId,
		holeNumber
	);

	const players = useGetPlayers(challengeDetails);
	const selectedPlayer = useGetSelectedPlayer(players);

	const refContent = useRef<HTMLElement>(null);

	useEffect(() => {
		// The cluster id is a comma separated list of stroke IDs, so take the first
		const elements = document.getElementsByClassName(`leader-card selected`);

		const scrollElement = elements[0];

		if (scrollElement) {
			setTimeout(
				() => scrollElement.scrollIntoView({ behavior: 'smooth' }),
				250
			);
		}
	}, [selectedPlayer?.id]);

	return (
		<section
			className='bg-white leaderboard__overlay-section pt-4 border-t border-[#e0dfdf] xl:rounded-b-lg'
			ref={refContent}
		>
			{challengeDetails?.participantsSorted?.length > 0 && (
				<>
					<SearchInput
						onChange={e => {
							dispatch(runSearch(e.target.value));
							dispatch(clearSelectedStrokes());
						}}
					/>
					<div className='leaderboard__container mt-2.5 mb-[1rem]'>
						{players?.map(player => (
							<LeaderCard
								key={`scatterplot-marker-${player.id}`}
								player={player}
								boxShadow
							/>
						))}
					</div>
				</>
			)}
			{challengeDetails?.participantsSorted?.length === 0 && (
				<div
					className='flex flex-col gap-4 items-center justify-center rounded-[6px] p-[1rem]'
					style={{
						boxShadow: '4px 4px 20px rgba(0,0,0,0.07)',
						border: '1px solid #EFEFEF',
					}}
				>
					<WarningCircleIcon />
					No shots recorded for this hole.
				</div>
			)}
		</section>
	);
};

export default Leaderboard;
