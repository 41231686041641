import React, { useRef } from 'react';

import { MapType } from '../../../../domain/types/enum-types';
import GoogleMap from './GoogleMap';
import Map from './Map';
import MapErrorSection from './MapErrorSection';
import useMapError from './hooks/useMapError';
import useMapWrapper from './hooks/useMapWrapper';
import { MapWrapperProps } from './types';

const MapWrapper = ({ mapMarkers, mapType, ...mapProps }: MapWrapperProps) => {
	const mapContainer = useRef<HTMLElement>(null);

	const {
		updateMapElements,
		onMapIdle,
		onMapClick,
		useDefaultMapZoom,
		children,
	} = useMapWrapper(
		mapContainer,
		mapMarkers,
		mapType,
		mapProps.challengeType,
		mapProps.fitBounds
	);

	// Catches weird map errors, such as WebGL for google and script errors for Bing
	const { mapLoadErrorMessage } = useMapError();

	if (mapLoadErrorMessage) {
		return (
			<MapErrorSection errorMessage={mapLoadErrorMessage} mapType={mapType} />
		);
	}

	// google needs to go through an extra step than the other maps to work
	if (mapType === MapType.google) {
		return (
			<GoogleMap
				{...mapProps}
				mapRef={mapContainer}
				onMapIdle={onMapIdle}
				onMapClick={onMapClick}
				useDefaultMapZoom={useDefaultMapZoom}
			>
				{children}
			</GoogleMap>
		);
	}

	return (
		<Map
			{...mapProps}
			mapType={MapType.bing}
			mapRef={mapContainer}
			updateMapElements={updateMapElements}
			onMapIdle={onMapIdle}
			onMapClick={onMapClick}
			useDefaultMapZoom={useDefaultMapZoom}
		>
			{children}
		</Map>
	);
};

export default MapWrapper;
