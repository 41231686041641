import React, { useEffect, useState } from 'react';

import ChevronRightIcon from '../../../../icons/ChevronRight';
import useSwipe from '../../hooks/useSwipe';
import useWindowResize from '../../hooks/useWindowResize';
import './Carousel.scss';

interface Props {
	cards: JSX.Element[];
	height: string;
}

const Carousel = ({ cards, height }: Props) => {
	const { width } = useWindowResize();
	const [isMobile, setIsMobile] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [pageSize, setPageSize] = useState(1);

	useEffect(() => {
		const isMobileScreen = width < 650;
		setIsMobile(isMobileScreen);
		setPageSize(isMobileScreen ? 1 : 3);
	}, [width]);

	const totalPageCount = Math.ceil(cards.length / pageSize);

	const next = () => {
		if (pageIndex < totalPageCount - 1) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const prev = () => {
		if (pageIndex > 0) {
			setPageIndex(prevState => prevState - 1);
		}
	};

	const swipeHandlers = useSwipe(next, prev);

	return (
		<section className='md:mx-[11.5vh] mx-[4vh] mt-[50px] pb-[50px] space-y-5'>
			<div {...swipeHandlers} className='carousel-wrapper' style={{ height }}>
				<div
					className='carousel-wrapper__content'
					style={{ transform: `translateX(-${pageIndex * 100}%)` }}
				>
					{cards}
				</div>
			</div>
			{totalPageCount > 1 && (
				<div className='flex justify-between'>
					<button
						type='button'
						onClick={() => prev()}
						disabled={pageIndex === 0}
					>
						<ChevronRightIcon
							style={{
								transform: `rotate(180deg) ${!isMobile ? 'scale(2.0)' : ''}`,
							}}
							fill='#BDBCBC'
						/>
					</button>
					<div>
						{Array.from(Array(totalPageCount).keys()).map((_, idx) => (
							<div
								role='presentation'
								className={`carousel-dot${idx === pageIndex ? ' active' : ''}`}
								key={`${idx.toString()}`}
								onClick={() => {
									setPageIndex(idx);
								}}
								onKeyDown={() => {
									setPageIndex(idx);
								}}
							/>
						))}
					</div>
					<button
						type='button'
						onClick={() => next()}
						disabled={pageIndex === cards.length - 1}
					>
						<ChevronRightIcon
							fill='#BDBCBC'
							style={{ transform: `${!isMobile ? 'scale(2.0)' : ''}` }}
						/>
					</button>
				</div>
			)}
		</section>
	);
};

export default Carousel;
