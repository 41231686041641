const apiKeyError = 'Error retrieving key';

const ENVS: {
	[key: string]: {
		HOST: string;
		KEY: string;
	};
} = {
	'dev-localhost': {
		HOST: 'shotlandtst.golf',
		KEY: process.env.REACT_APP_CONSUME_API_KEY_LOCAL,
	},
	dev: {
		HOST: 'shotlandtst.golf',
		KEY: apiKeyError,
	},
	staging: {
		HOST: 'shotlandint.golf',
		KEY: apiKeyError,
	},
	prod: {
		HOST: 'shotlander.io',
		KEY: apiKeyError,
	},
};

const PROTOCOL = 'https';

let stage = 'dev-localhost';

if (
	process.env.REACT_APP_STAGE &&
	Object.keys(ENVS).includes(process.env.REACT_APP_STAGE)
) {
	stage = process.env.REACT_APP_STAGE;
}

const CONSUME_API_KEY = ENVS[stage].KEY;

const thisEnv = ENVS[stage];

const HOST = `api.${thisEnv.HOST}`;

const MAPS_HOST = `maps.${thisEnv.HOST}`;

export const BASE_URL = `${PROTOCOL}://${HOST}`;

export const OVERLAY_MAPS_URL = `${PROTOCOL}://${MAPS_HOST}`;

const SHELL_APP_LOCAL_URL = 'localhost:3001';
const SHELL_APP_URL = ENVS[stage].HOST;

export const WEB_SOCKET_URL = HOST;

export default {
	BASE_URL,
	CONSUME_API_KEY,
	OVERLAY_MAPS_URL,
	SHELL_APP_LOCAL_URL,
	SHELL_APP_URL,
	WEB_SOCKET_URL,
};
