import EventDetails from '../../../domain/models/event-details';
import { SlEventDetails } from '../schema';
import toChallenge from './toChallenge';

const toEventDetails = (slEventChallenges: SlEventDetails) =>
	new EventDetails(
		slEventChallenges.eventId,
		slEventChallenges.eventName,
		slEventChallenges.eventDescription,
		slEventChallenges.venueName,
		slEventChallenges.eventLocation,
		slEventChallenges.eventStartDate,
		slEventChallenges.eventEndDate,
		slEventChallenges.challenges.map(challenge => toChallenge(challenge))
	);

export default toEventDetails;
