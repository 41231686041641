import { useMemo } from 'react';

import challengeApi from '../challenges-api';
import toEventDetails from '../transforms/toEventChallenges';

const useGetEventChallengesQuery = (eventId: number) => {
	const { data, refetch, error, ...rest } =
		challengeApi.useGetEventChallengesQuery(eventId);

	return {
		eventChallenges: useMemo(
			() => (data ? toEventDetails(data) : undefined),
			[data]
		),
		error: error as { status: number } | undefined,
		refetchEvents: refetch,
		...rest,
	};
};

export default useGetEventChallengesQuery;
