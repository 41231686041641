import Distance from '../types/distance';

export default class ParticipantHighlights {
	constructor(
		public participantId: number,
		public participantFirstName: string,
		public participantLastName: string,
		public groupName: string,
		public greensInRegulation: number,
		public averageDriveDistance: Distance | null,
		public averageDistanceToPin: Distance | null,
		public holesPlayed: number
	) {}

	get fullName() {
		return `${this.participantFirstName} ${this.participantLastName}`;
	}
}
