import LatLongService from '../../../../domain/services/LatLongService';
import alphaNumericSort from '../../../shared/helper-functions';
import Cluster from '../models/cluster';
import PlayerStroke from '../models/player-stroke';
import Team from '../models/team';

class TeamService {
	static createTeams(teamData: PlayerStroke[][]) {
		return teamData.map((strokes, index) => {
			const teamCenterPoint = LatLongService.findCenterPoint(
				strokes.map(x => x.ballLocation)
			);

			let sumDistance = 0;
			strokes.forEach(stroke => {
				sumDistance += stroke.ballDistance ?? 0;
			});

			const avgDistanceFromPin = sumDistance / strokes.length;

			return new Team(
				strokes[0].teamId,
				new Cluster(
					index,
					{
						latitude: teamCenterPoint[0].latitude,
						longitude: teamCenterPoint[0].longitude,
					},
					strokes,
					avgDistanceFromPin
				),
				strokes[0].teamName
			);
		});
	}

	static findTeams(strokeData: PlayerStroke[]) {
		const newStrokeData = [...strokeData];
		const groupDict = new Map<number, PlayerStroke[]>();
		for (let i = 0; i < newStrokeData.length; i++) {
			if (groupDict.has(newStrokeData[i].teamId)) {
				groupDict.get(newStrokeData[i].teamId)?.push(newStrokeData[i]);
			} else {
				groupDict.set(newStrokeData[i].teamId, [newStrokeData[i]]);
			}
		}
		const teams = Array.from(groupDict.values());
		return TeamService.createTeams(teams);
	}

	static sortTeamDistance(clusterData: Team[], isAscending: boolean) {
		const newData = clusterData;
		newData.sort((a, b) => {
			const firstClusterDistance = a.cluster.clusterDistance;
			const secondClusterDistance = b.cluster.clusterDistance;

			if (firstClusterDistance === secondClusterDistance) return 0;

			if (firstClusterDistance === null) return 1;
			if (secondClusterDistance === null) return -1;

			// if ascending, lowest sorts first
			if (isAscending) {
				return firstClusterDistance < secondClusterDistance ? -1 : 1;
			}

			// if descending, highest sorts first
			return firstClusterDistance < secondClusterDistance ? 1 : -1;
		});

		return newData;
	}

	static sortTeamAlpha(teamData: Team[], isAscending: boolean) {
		const newData = teamData;
		newData.sort((a, b) => {
			if (isAscending) return alphaNumericSort(a.name, b.name);
			return alphaNumericSort(b.name, a.name);
		});
		return newData;
	}
}

export default TeamService;
