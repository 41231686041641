import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '../api-config';
import { createGetQuery } from '../api-util';
import {
	SlChallengeDetails,
	SlEventDetails,
	SlFullRoundHighlights,
	SlLeaderboard,
} from './schema';

const challengeApi = createApi({
	reducerPath: 'ctp/challenge-api',
	baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/challenges` }),
	endpoints: builder => ({
		getChallengeDetails: builder.query<
			SlChallengeDetails,
			{ eventId: number; roundId: number; holeNum: number }
		>({
			query: ({ eventId, roundId, holeNum }) =>
				createGetQuery(`/${eventId}/${roundId}/${holeNum}`),
		}),
		getEventChallenges: builder.query<SlEventDetails, number>({
			query: eventId => createGetQuery(`events/${eventId}?active=true`),
		}),
		getFullRoundHighlights: builder.query<
			SlFullRoundHighlights,
			{ eventId: number; roundId: number }
		>({
			query: ({ eventId, roundId }) =>
				createGetQuery(`/${eventId}/${roundId}/highlights`),
		}),
		getLeaderboardDetails: builder.query<
			SlLeaderboard,
			{ eventId: number; roundId: number; holeNum: number }
		>({
			query: ({ eventId, roundId, holeNum }) =>
				createGetQuery(`/${eventId}/${roundId}/${holeNum}/leaderboard`),
		}),
	}),
});

export default challengeApi;
