/* eslint-disable import/prefer-default-export */
import Hole from '../../../domain/models/hole';
import { SlChallengeHole } from '../schema';
import toTeeLocation from './toTeeLocation';

export const toHole = (slChallengeHole: SlChallengeHole) =>
	new Hole(
		slChallengeHole.id,
		slChallengeHole.holeNumber,
		slChallengeHole.par,
		slChallengeHole.teeLocations?.map(toTeeLocation),
		slChallengeHole.pinLocation
	);
