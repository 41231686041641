import React, { ReactNode } from 'react';

import './Modal.scss';

interface Props {
	isVisible: boolean;
	toggleModal: () => void;
	handleSubmit: () => void;
	customStyles?: { [key: string]: string };
	showBottomButtons: boolean;
	children: ReactNode;
	onClick?: () => void;
	onKeyDown?: () => void;
}

const Modal = (props: Props) => {
	const {
		isVisible,
		toggleModal,
		handleSubmit,
		customStyles,
		showBottomButtons,
		children,
		onClick,
		onKeyDown,
	} = props;

	return isVisible ? (
		<div
			role='button'
			tabIndex={-1}
			onClick={onClick}
			onKeyDown={onKeyDown}
			style={{ cursor: 'default' }}
		>
			<div aria-label='overlay' className='dark-overlay' />
			<div className='modal' style={customStyles}>
				{children}
				{showBottomButtons && (
					<div className='bottom-drawer-apply__section'>
						<button type='button' onClick={toggleModal}>
							CANCEL
						</button>
						<button
							type='button'
							style={{ color: 'white', backgroundColor: '#003865' }}
							onClick={handleSubmit}
						>
							Apply
						</button>
					</div>
				)}
			</div>
		</div>
	) : null;
};

export default Modal;
