import { useMemo } from 'react';

import commonApi from '../common-api';
import toEvent from '../transforms/toEvent';

const useGetEventsQuery = () => {
	const { data, refetch, ...rest } = commonApi.useGetEventsQuery();
	return {
		events: useMemo(
			() => (data ? data.content.map(x => toEvent(x)) : undefined),
			[data]
		),
		refetchEvents: refetch,
		...rest,
	};
};

export default useGetEventsQuery;
