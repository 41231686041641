const states = [
	{
		value: 'State',
		displayText: 'State',
		abbreviation: 'State',
		disabled: true,
		key: 0,
	},
	{
		value: 'Alabama',
		displayText: 'Alabama',
		abbreviation: 'AL',
		disabled: false,
		key: 1,
	},
	{
		value: 'Alaska',
		displayText: 'Alaska',
		abbreviation: 'AK',
		disabled: false,
		key: 2,
	},
	{
		value: 'Arizona',
		displayText: 'Arizona',
		abbreviation: 'AZ',
		disabled: false,
		key: 3,
	},
	{
		value: 'Arkansas',
		displayText: 'Arkansas',
		abbreviation: 'AR',
		disabled: false,
		key: 4,
	},
	{
		value: 'California',
		displayText: 'California',
		abbreviation: 'CA',
		disabled: false,
		key: 5,
	},
	{
		value: 'Colorado',
		displayText: 'Colorado',
		abbreviation: 'CO',
		disabled: false,
		key: 6,
	},
	{
		value: 'Connecticut',
		displayText: 'Connecticut',
		abbreviation: 'CT',
		disabled: false,
		key: 7,
	},
	{
		value: 'Delaware',
		displayText: 'Delaware',
		abbreviation: 'DE',
		disabled: false,
		key: 8,
	},
	{
		value: 'Florida',
		displayText: 'Florida',
		abbreviation: 'FL',
		disabled: false,
		key: 9,
	},
	{
		value: 'Georgia',
		displayText: 'Georgia',
		abbreviation: 'GA',
		disabled: false,
		key: 10,
	},
	{
		value: 'Hawaii',
		displayText: 'Hawaii',
		abbreviation: 'HI',
		disabled: false,
		key: 11,
	},
	{
		value: 'Idaho',
		displayText: 'Idaho',
		abbreviation: 'ID',
		disabled: false,
		key: 12,
	},
	{
		value: 'Illinois',
		displayText: 'Illinois',
		abbreviation: 'IL',
		disabled: false,
		key: 13,
	},
	{
		value: 'Indiana',
		displayText: 'Indiana',
		abbreviation: 'IN',
		disabled: false,
		key: 14,
	},
	{
		value: 'Iowa',
		displayText: 'Iowa',
		abbreviation: 'IA',
		disabled: false,
		key: 15,
	},
	{
		value: 'Kansas',
		displayText: 'Kansas',
		abbreviation: 'KS',
		disabled: false,
		key: 16,
	},
	{
		value: 'Kentucky',
		displayText: 'Kentucky',
		abbreviation: 'KY',
		disabled: false,
		key: 17,
	},
	{
		value: 'Louisiana',
		displayText: 'Louisiana',
		abbreviation: 'LA',
		disabled: false,
		key: 18,
	},
	{
		value: 'Maine',
		displayText: 'Maine',
		abbreviation: 'ME',
		disabled: false,
		key: 19,
	},
	{
		value: 'Maryland',
		displayText: 'Maryland',
		abbreviation: 'MD',
		disabled: false,
		key: 20,
	},
	{
		value: 'Massachusetts',
		displayText: 'Massachusetts',
		abbreviation: 'MA',
		disabled: false,
		key: 21,
	},
	{
		value: 'Michigan',
		displayText: 'Michigan',
		abbreviation: 'MI',
		disabled: false,
		key: 22,
	},
	{
		value: 'Minnesota',
		displayText: 'Minnesota',
		abbreviation: 'MN',
		disabled: false,
		key: 23,
	},
	{
		value: 'Mississippi',
		displayText: 'Mississippi',
		abbreviation: 'MS',
		disabled: false,
		key: 24,
	},
	{
		value: 'Missouri',
		displayText: 'Missouri',
		abbreviation: 'MO',
		disabled: false,
		key: 25,
	},
	{
		value: 'Montana',
		displayText: 'Montana',
		abbreviation: 'MT',
		disabled: false,
		key: 26,
	},
	{
		value: 'Nebraska',
		displayText: 'Nebraska',
		abbreviation: 'NE',
		disabled: false,
		key: 27,
	},
	{
		value: 'Nevada',
		displayText: 'Nevada',
		abbreviation: 'NV',
		disabled: false,
		key: 28,
	},
	{
		value: 'New Hampshire',
		displayText: 'New Hampshire',
		abbreviation: 'NH',
		disabled: false,
		key: 29,
	},
	{
		value: 'New Jersey',
		displayText: 'New Jersey',
		abbreviation: 'NJ',
		disabled: false,
		key: 30,
	},
	{
		value: 'New Mexico',
		displayText: 'New Mexico',
		abbreviation: 'NM',
		disabled: false,
		key: 31,
	},
	{
		value: 'New York',
		displayText: 'New York',
		abbreviation: 'NY',
		disabled: false,
		key: 32,
	},
	{
		value: 'North Carolina',
		displayText: 'North Carolina',
		abbreviation: 'NC',
		disabled: false,
		key: 33,
	},
	{
		value: 'North Dakota',
		displayText: 'North Dakota',
		abbreviation: 'ND',
		disabled: false,
		key: 34,
	},
	{
		value: 'Ohio',
		displayText: 'Ohio',
		abbreviation: 'OH',
		disabled: false,
		key: 35,
	},
	{
		value: 'Oklahoma',
		displayText: 'Oklahoma',
		abbreviation: 'OK',
		disabled: false,
		key: 36,
	},
	{
		value: 'Oregon',
		displayText: 'Oregon',
		abbreviation: 'OR',
		disabled: false,
		key: 37,
	},
	{
		value: 'Pennsylvania',
		displayText: 'Pennsylvania',
		abbreviation: 'PA',
		disabled: false,
		key: 38,
	},
	{
		value: 'Rhode Island',
		displayText: 'Rhode Island',
		abbreviation: 'RI',
		disabled: false,
		key: 39,
	},
	{
		value: 'South Carolina',
		displayText: 'South Carolina',
		abbreviation: 'SC',
		disabled: false,
		key: 40,
	},
	{
		value: 'South Dakota',
		displayText: 'South Dakota',
		abbreviation: 'SD',
		disabled: false,
		key: 41,
	},
	{
		value: 'Tennessee',
		displayText: 'Tennessee',
		abbreviation: 'TN',
		disabled: false,
		key: 42,
	},
	{
		value: 'Texas',
		displayText: 'Texas',
		abbreviation: 'TX',
		disabled: false,
		key: 43,
	},
	{
		value: 'Utah',
		displayText: 'Utah',
		abbreviation: 'UT',
		disabled: false,
		key: 44,
	},
	{
		value: 'Vermont',
		displayText: 'Vermont',
		abbreviation: 'VT',
		disabled: false,
		key: 45,
	},
	{
		value: 'Virginia',
		displayText: 'Virginia',
		abbreviation: 'VA',
		disabled: false,
		key: 46,
	},
	{
		value: 'Washington',
		displayText: 'Washington',
		abbreviation: 'WA',
		disabled: false,
		key: 47,
	},
	{
		value: 'Washington DC',
		displayText: 'Washington DC',
		abbreviation: 'DC',
		disabled: false,
		key: 48,
	},
	{
		value: 'West Virginia',
		displayText: 'West Virginia',
		abbreviation: 'WV',
		disabled: false,
		key: 49,
	},
	{
		value: 'Wisconsin',
		displayText: 'Wisconsin',
		abbreviation: 'WI',
		disabled: false,
		key: 50,
	},
	{
		value: 'Wyoming',
		displayText: 'Wyoming',
		abbreviation: 'WY',
		disabled: false,
		key: 51,
	},
];

export default states;
