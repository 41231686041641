import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SortOption, TeeType, ViewOption } from '../../domain/types/enum-types';
import Filters from '../../domain/types/filters';
import ClusteringService from './shared/services/ClusteringService';

interface SliceState {
	sortOption: SortOption;
	shotViewOption: ViewOption;
	selectedStrokeIds: number[];
	// Cluster id is a comma separated string of all stroke ids contained within it
	selectedClusterIds: string[];
	filters: Filters;
	searchValue: string;
	preventPanTo: boolean;
	zoomLevel: number;
	initialZoomLevel: number | null;
	manualMapScale: number;
	teeType: TeeType;
}

export const initialState: SliceState = {
	sortOption: SortOption.distanceAscending,
	shotViewOption: ViewOption.playerView,
	selectedStrokeIds: [],
	filters: {
		selectedGroups: [],
	},
	selectedClusterIds: [],
	searchValue: '',
	preventPanTo: true,
	zoomLevel: ClusteringService.maxZoomLevel,
	initialZoomLevel: null,
	manualMapScale: 1,
	teeType: TeeType.default,
};

export const appSlice = createSlice({
	name: 'challenge-reducer',
	initialState,
	reducers: {
		clearPanTo: state => {
			state.preventPanTo = true;
		},
		setSort: (state, action: PayloadAction<SortOption>) => {
			state.sortOption = action.payload;
		},
		setShowViewOption: (state, action: PayloadAction<ViewOption>) => {
			if (state.selectedStrokeIds.length > 0) state.selectedStrokeIds = [];
			if (state.selectedClusterIds.length > 0) state.selectedClusterIds = [];
			state.shotViewOption = action.payload;
		},
		selectStrokes: (state, action: PayloadAction<number[]>) => {
			state.selectedStrokeIds = action.payload;
			state.preventPanTo = false;
		},
		selectStrokesNoPanTo: (state, action: PayloadAction<number[]>) => {
			state.selectedStrokeIds = action.payload;
			state.preventPanTo = true;
		},
		clearSelectedStrokes: state => {
			if (state.selectedStrokeIds.length > 0) state.selectedStrokeIds = [];
		},
		runSearch: (state, action: PayloadAction<string>) => {
			state.searchValue = action.payload.toLocaleLowerCase();
		},
		setGroupFilter: (state, action: PayloadAction<string[]>) => {
			state.filters.selectedGroups = action.payload;
		},
		clearAllSelected: state => {
			if (state.selectedStrokeIds.length > 0) state.selectedStrokeIds = [];
			if (state.selectedClusterIds.length > 0) state.selectedClusterIds = [];
		},
		addSelectedCluster: (state, action: PayloadAction<string>) => {
			state.selectedClusterIds = [...state.selectedClusterIds, action.payload];
		},
		removeSelectedCluster: (state, action: PayloadAction<string>) => {
			state.selectedClusterIds = state.selectedClusterIds.filter(
				x => x.toString() !== action.payload.toString()
			);
		},
		setZoomLevel: (state, action: PayloadAction<number>) => {
			state.zoomLevel = action.payload;
		},
		setInitialZoomLevel: (state, action: PayloadAction<number | null>) => {
			state.initialZoomLevel = action.payload;
		},
		setManualMapScale: (state, action: PayloadAction<number>) => {
			state.manualMapScale = action.payload;
		},
		setTeeType: (state, action: PayloadAction<TeeType>) => {
			state.teeType = action.payload;
		},
	},
});

export const {
	clearPanTo,
	setSort,
	setShowViewOption,
	setZoomLevel,
	selectStrokes,
	selectStrokesNoPanTo,
	addSelectedCluster,
	removeSelectedCluster,
	clearSelectedStrokes,
	runSearch,
	setGroupFilter,
	clearAllSelected,
	setInitialZoomLevel,
	setManualMapScale,
	setTeeType,
} = appSlice.actions;

export default appSlice.reducer;
