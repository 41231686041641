import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import ChallengeType from '../../../../../domain/types/challenge-type';
import { IconType, MapType } from '../../../../../domain/types/enum-types';
import LatLong from '../../../../../domain/types/lat-long';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
	clearAllSelected,
	clearSelectedStrokes,
	selectStrokesNoPanTo,
	setManualMapScale,
	setZoomLevel,
} from '../../../../challenges/challenge-reducer';
import useWindowResize from '../../../hooks/useWindowResize';
import MapMarker from '../../../models/map-marker';
import BingMarker from '../../Markers/BingMarker';
import GoogleMarker from '../../Markers/GoogleMarker';

const useMapWrapper = (
	mapRef: React.RefObject<HTMLElement>,
	mapMarkers: MapMarker[],
	mapType: MapType,
	challengeType: ChallengeType,
	fitBounds?: LatLong[]
) => {
	const { selectedStrokeIds, zoomLevel, manualMapScale, initialZoomLevel } =
		useAppSelector(({ ctp }) => ctp);
	const dispatch = useAppDispatch();

	const zoom = useRef<number>(0);
	zoom.current = zoomLevel;

	const [useDefaultMapZoom, setUseDefaultMapZoom] = useState<boolean>(false);

	const { isMobileScreen } = useWindowResize();

	const allowMapManualZoom = mapType === MapType.bing;

	const setNewZoomLevel = useCallback(
		(newZoom: number) => {
			dispatch(setZoomLevel(newZoom));
		},
		[zoomLevel]
	);

	useEffect(() => {
		if (initialZoomLevel) {
			setNewZoomLevel(initialZoomLevel + manualMapScale - 1);
			dispatch(clearSelectedStrokes());
		}
	}, [initialZoomLevel]);

	// Click events
	const onMapIdle = useCallback(
		(timeoutId: number, newZoom: number | undefined) => {
			clearTimeout(timeoutId);

			return window.setTimeout(() => {
				if (newZoom && initialZoomLevel) {
					if (newZoom < initialZoomLevel || !allowMapManualZoom) {
						setNewZoomLevel(newZoom);
					} else if (newZoom === initialZoomLevel && manualMapScale === 1) {
						setNewZoomLevel(newZoom);
						setUseDefaultMapZoom(false);
					}
				}
			}, 500);
		},
		[manualMapScale, initialZoomLevel]
	);

	const onMapClick = () => {
		dispatch(clearAllSelected());
	};

	const updateMapElements = (transformOrigin: string, scaleLevel: number) => {
		if (isMobileScreen) {
			const elementList = document.querySelector('.ms-composite')?.children;
			if (elementList) {
				Array.from(elementList).forEach(element => {
					const htmlElement = element as HTMLElement;
					htmlElement.style.transformOrigin = transformOrigin;
					htmlElement.style.scale = `${scaleLevel}`;
				});
			}
		} else if (mapRef?.current) {
			mapRef.current.style.transform = `scale(${scaleLevel})`;
		}
	};

	const onManualZoom = (
		deltaY: number,
		newScaleLevel: number,
		wheelTimeoutId: number
	) => {
		if (mapRef.current && initialZoomLevel) {
			if (
				(newScaleLevel === 1 && deltaY < 0) ||
				zoom.current < initialZoomLevel
			) {
				setUseDefaultMapZoom(true);
				updateMapElements('left top', 1);
			} else if (
				(newScaleLevel > 1 || (newScaleLevel === 1 && deltaY > 0)) &&
				zoom.current >= initialZoomLevel
			) {
				setUseDefaultMapZoom(false);

				newScaleLevel += deltaY;

				// keep the scale level within a certain zone so it doesn't get out of control
				const maxScaleLevel = isMobileScreen ? 3 : 2;
				if (newScaleLevel <= 1) newScaleLevel = 1;
				else if (newScaleLevel >= maxScaleLevel) newScaleLevel = maxScaleLevel;

				updateMapElements('center', newScaleLevel);

				clearTimeout(wheelTimeoutId);
				wheelTimeoutId = window.setTimeout(() => {
					dispatch(setManualMapScale(newScaleLevel));
					setNewZoomLevel(initialZoomLevel + newScaleLevel - 1);
				}, 500);
			}
		}
		return { wheelTimeoutId, newScaleLevel };
	};

	// reset to normal map zoom whenever the fit bounds object has changed
	useEffect(() => {
		if (mapRef?.current && fitBounds) {
			dispatch(setManualMapScale(1));
			updateMapElements('center', 1);
		}
	}, [mapRef?.current, fitBounds]);

	useEffect(() => {
		let wheelTimeoutId: number;
		let newScaleLevel = manualMapScale;
		let touchStart = 0;
		let touchEnd = 0;

		const onWheelChange = (e: WheelEvent) => {
			const deltaY = -e.deltaY / 250;

			const newZoom = onManualZoom(deltaY, newScaleLevel, wheelTimeoutId);
			wheelTimeoutId = newZoom.wheelTimeoutId;
			newScaleLevel = newZoom.newScaleLevel;
		};

		const onTouchStart = (e: TouchEvent) => {
			if (e.touches.length === 2) {
				const distance = Math.hypot(
					e.touches[0].pageX - e.touches[1].pageX,
					e.touches[0].pageY - e.touches[1].pageY
				);
				touchStart = distance;
			}
		};

		const onTouchMove = (e: TouchEvent) => {
			if (e.touches.length === 2) {
				const distance = Math.hypot(
					e.touches[0].pageX - e.touches[1].pageX,
					e.touches[0].pageY - e.touches[1].pageY
				);
				touchEnd = distance;

				const deltaY = (touchEnd - touchStart) / 1000;

				const newZoom = onManualZoom(deltaY, newScaleLevel, wheelTimeoutId);
				wheelTimeoutId = newZoom.wheelTimeoutId;
				newScaleLevel = newZoom.newScaleLevel;
			}
		};

		// only add this event listener for bing map type. google maps already zooms in pretty far so these extra steps aren't needed
		if (allowMapManualZoom && mapRef?.current && initialZoomLevel) {
			mapRef.current?.addEventListener('wheel', e => onWheelChange(e));
			mapRef.current?.addEventListener('touchstart', e => onTouchStart(e));
			mapRef.current?.addEventListener('touchmove', e => onTouchMove(e));
		}

		return () => {
			mapRef.current?.removeEventListener('wheel', e => onWheelChange(e));
			mapRef.current?.removeEventListener('touchstart', e => onTouchStart(e));
			mapRef.current?.removeEventListener('touchmove', e => onTouchMove(e));
		};
	}, [mapRef?.current, initialZoomLevel, manualMapScale]);

	const onMarkerClick = (marker: MapMarker | undefined) => {
		dispatch(selectStrokesNoPanTo(marker?.ids ?? []));
	};

	const children = useMemo(
		() =>
			mapMarkers?.map(marker => {
				const isMarkerSelected = marker.includesId(selectedStrokeIds);

				const key = `google-marker-${marker.location.latitude}-${marker.location.longitude}`;

				if (mapType === MapType.google) {
					return (
						<GoogleMarker
							iconType={IconType.cluster}
							position={marker.location}
							label={marker.label}
							onClick={() => onMarkerClick(marker)}
							isSelected={isMarkerSelected}
							popupContent={marker.popupContent}
							challengeType={challengeType}
							key={key}
						/>
					);
				}
				return (
					<BingMarker
						iconType={IconType.cluster}
						position={marker.location}
						label={marker.label}
						onClick={() => onMarkerClick(marker)}
						isSelected={isMarkerSelected}
						popupContent={marker.popupContent}
						challengeType={challengeType}
						key={key}
					/>
				);
			}),
		[
			JSON.stringify(mapMarkers?.map(x => x.ids)),
			JSON.stringify(selectedStrokeIds),
		]
	);

	return {
		updateMapElements,
		onMapIdle,
		onMapClick,
		useDefaultMapZoom,
		children,
	};
};

export default useMapWrapper;
