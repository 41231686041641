import React from 'react';
import { Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import ErrorBanner from '../../layout/ErrorBanner/ErrorBanner';
import Modal from '../../layout/Modal/Modal';
import DataCard from './DataCard';
import './GameHighlightsModal.scss';
import useGameHighlightsModal from './useGameHighlightsModal';

interface Props {
	isOpen: boolean;
	toggleModal: () => void;
	eventId: number;
	roundId: number;
	holeNumber: number;
}

const GameHighlightsModal = ({
	toggleModal,
	isOpen,
	eventId,
	roundId,
	holeNumber,
}: Props) => {
	const {
		data: {
			activeIndex,
			shotLocationData,
			trendingRegion,
			squares,
			gameHighlights,
			error,
		},
		handlers: {
			setActiveIndex,
			renderActiveShape,
			renderCustomizedLegend,
			refetchRound,
		},
	} = useGameHighlightsModal({ eventId, roundId, holeNumber });

	return (
		<Modal
			isVisible={isOpen}
			toggleModal={toggleModal}
			handleSubmit={toggleModal}
			onClick={() => setActiveIndex(undefined)}
			onKeyDown={() => setActiveIndex(undefined)}
			customStyles={{
				height: '75%',
				borderRadius: '16px',
			}}
			showBottomButtons={false}
		>
			<div className='header'>
				<h2>Game Highlights</h2>
			</div>
			<div className='content'>
				{!error && (
					<>
						<DataCard
							title='Player Activity'
							infoSection={`${gameHighlights?.activePlayers} active players`}
						>
							<div className='content__quick-info'>
								<h1>{gameHighlights?.shotsTaken}</h1>
								<h4 className='sub-title'>Shots taken*</h4>
							</div>
						</DataCard>
						<DataCard
							title='Average Distance'
							infoSection={`${gameHighlights?.shotsAboveAverage} shots above average*`}
						>
							<div className='content__quick-info'>
								<h1>
									{gameHighlights &&
										(typeof gameHighlights.averageDistance.feet !== 'number'
											? '-'
											: Math.round(gameHighlights.averageDistance.feet ?? 0))}
								</h1>
								<h4 className='sub-title'>Feet from Pin</h4>
							</div>
						</DataCard>
						<DataCard
							title='Shots by Location'
							infoSection={
								gameHighlights?.parLevel
									? `Par ${gameHighlights?.parLevel}`
									: null
							}
						>
							<div className='content__pie-chart'>
								<ResponsiveContainer width='100%' height={425}>
									<PieChart>
										<Pie
											dataKey='value'
											data={shotLocationData}
											innerRadius={95}
											outerRadius={125}
											fill='#82ca9d'
											paddingAngle={4}
											onClick={(_, index, e) => {
												e.stopPropagation();
												setActiveIndex(index);
											}}
											activeIndex={activeIndex}
											activeShape={renderActiveShape}
											style={{ cursor: 'pointer' }}
										/>
										<Legend
											wrapperStyle={{
												display: 'grid',
												gridColumn: '3',
											}}
											content={renderCustomizedLegend}
										/>
									</PieChart>
								</ResponsiveContainer>
							</div>
						</DataCard>
						<DataCard
							title='Trending Region'
							infoSection={`${trendingRegion?.totalShotsInRegion} out of ${gameHighlights?.shotsTaken} shots total`}
						>
							<div className='content__trending-region'>
								<div>
									<h1>{trendingRegion?.name}</h1>
									<h4 className='sub-title'>
										{`${trendingRegion?.trendingShots} shots since ${new Date(
											trendingRegion?.trendingTime ?? ''
										)
											.toLocaleTimeString('en-US', {
												hour: '2-digit',
												minute: '2-digit',
											})
											.toLowerCase()}`}
									</h4>
								</div>
								<div className='square-regions'>{squares}</div>
							</div>
						</DataCard>
						<DataCard title='* Number includes mulligans' infoSection={null} />
					</>
				)}
				{error && (
					<ErrorBanner message='Failed to fetch data' click={refetchRound} />
				)}
			</div>
			<div className='footer'>
				<button
					type='button'
					onClick={e => {
						e.stopPropagation();
						toggleModal();
					}}
				>
					CLOSE
				</button>
			</div>
		</Modal>
	);
};

export default GameHighlightsModal;
