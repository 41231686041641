import GameHighlights from '../../../domain/models/game-highlights';
import { SlGameHighlights } from '../schema';
import toRegion from './toRegion';
import toShotLocations from './toShotLocations';

const toGameHighlights = (slGameHighlights: SlGameHighlights) =>
	new GameHighlights(
		slGameHighlights.averageDistance,
		slGameHighlights.shotsAboveAverage,
		slGameHighlights.shotsTaken,
		slGameHighlights.activePlayers,
		slGameHighlights.parLevel,
		toShotLocations(
			slGameHighlights.shotLocations,
			slGameHighlights.shotsTaken
		),
		Object.values(slGameHighlights.regions ?? []).map(toRegion)
	);

export default toGameHighlights;
