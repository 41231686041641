import { useEffect, useState } from 'react';

const useMapError = () => {
	const [mapLoadErrorMessage, setMapLoadErrorMessage] = useState<string | null>(
		null
	);

	// catch weird script errors from maps API
	useEffect(() => {
		const catchErrors = (event: ErrorEvent) => {
			// if bing returns a script error message, don't error the map out
			if (!event.message.includes('Script error')) {
				setMapLoadErrorMessage(event.message);
			}
		};

		window.addEventListener('error', catchErrors);

		return () => window.removeEventListener('error', catchErrors);
	}, []);

	return { mapLoadErrorMessage };
};

export default useMapError;
