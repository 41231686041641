import { useMemo } from 'react';

import challengeApi from '../challenges-api';
import toFullRoundHighlights from '../transforms/toFullRoundHighlights';

const useGetFullRoundHighlightsQuery = (eventId: number, roundId: number) => {
	const { data, refetch, error, ...rest } =
		challengeApi.useGetFullRoundHighlightsQuery({ eventId, roundId });

	return {
		fullRoundHighlights: useMemo(
			() => (data ? toFullRoundHighlights(data) : undefined),
			[data]
		),
		error: error as { status: number } | undefined,
		refetchEvents: refetch,
		...rest,
	};
};

export default useGetFullRoundHighlightsQuery;
