import SortService from '../services/SortService';
import Distance from '../types/distance';
import { StrokeLocation } from '../types/enum-types';
import PinPointGroup from './group/pin-point-group';
import TeeLocation from './tee-location';

export default class LongestDriveTeeLocation {
	constructor(
		public averageDriveDistance: Distance,
		public groups: PinPointGroup[],
		public teeLocation: TeeLocation
	) {}

	get participantsWithGroupDetails() {
		return this.groups.flatMap(group =>
			group.participants.flatMap(participant => ({
				groupName: group.name,
				...participant,
			}))
		);
	}

	get participantsSortedByLongestDrive() {
		return [
			{
				section: 'On the Fairway/Green',
				infoText:
					'Shots on the fairway and green are calculated and prioritized before shots off the fairway and green.',
				players: this.participantsWithGroupDetails
					.filter(
						x =>
							x.stroke.courseLocation === StrokeLocation.green ||
							x.stroke.courseLocation === StrokeLocation.fairway ||
							x.stroke.courseLocation === StrokeLocation.hole
					)
					.sort((p1, p2) =>
						SortService.sortStrokesByScore(
							p1.stroke.driveDistance.yards,
							p2.stroke.driveDistance.yards,
							false // need to do reverse sort because we want longest drive to be highest rank
						)
					),
			},
			{
				section: 'Off the Fairway/Green',
				infoText:
					'Shots off the fairway and green are calculated and prioritized after shots on the fairway and green.',
				players: this.participantsWithGroupDetails
					.filter(
						x =>
							x.stroke.courseLocation !== StrokeLocation.green &&
							x.stroke.courseLocation !== StrokeLocation.fairway &&
							x.stroke.courseLocation !== StrokeLocation.hole
					)
					.sort((p1, p2) =>
						SortService.sortStrokesByScore(
							p1.stroke.driveDistance.yards,
							p2.stroke.driveDistance.yards,
							false // need to do reverse sort because we want longest drive to be highest rank
						)
					),
			},
		];
	}
}
