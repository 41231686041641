import { DivisionType } from '../../types/enum-types';
import Stroke from '../stroke';
import Participant from './participant';

export default class PinPointParticipant implements Participant {
	constructor(
		public id: number,
		public groupId: number,
		public firstName: string,
		public lastName: string,
		public age: number,
		public division: DivisionType | null,
		public stroke: Stroke
	) {}

	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}
}
