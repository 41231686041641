import React from 'react';
import { Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import ChallengeType from '../../../../../domain/types/challenge-type';
import DataCard from '../../../../shared/components/GameHightlightsModal/DataCard';
import useGetMapParams from '../../../../shared/hooks/useGetMapParams';
import ErrorBanner from '../../../../shared/layout/ErrorBanner/ErrorBanner';
import './GameHighlights.scss';
import useGameHighlights from './useGameHighlights';

interface Props {
	type: ChallengeType;
}

const GameHighlights = ({ type }: Props) => {
	const { eventId, roundId, holeNumber } = useGetMapParams();

	const {
		data: {
			activeIndex,
			shotLocationData,
			trendingRegion,
			squares,
			gameHighlights,
			error,
		},
		handlers: {
			setActiveIndex,
			renderActiveShape,
			renderCustomizedLegend,
			refetchRound,
		},
	} = useGameHighlights({ eventId, roundId, holeNumber });

	const resetActiveIndex = () => setActiveIndex(undefined);

	return (
		<section
			className='game-highlights bg-white leaderboard__overlay-section p-4 border-t border-[#e0dfdf] xl:rounded-b-lg flex flex-col gap-4'
			role='button'
			tabIndex={-1}
			onClick={resetActiveIndex}
			onKeyDown={resetActiveIndex}
		>
			{!error && (
				<>
					<DataCard
						title='Player Activity'
						infoSection={`${gameHighlights?.activePlayers} active players`}
					>
						<div className='game-highlights__quick-info'>
							<h1>{gameHighlights?.shotsTaken}</h1>
							<h4 className='sub-title'>Shots taken*</h4>
						</div>
					</DataCard>
					<DataCard
						title={`Average ${
							type === ChallengeType.LongestDrive ? 'Drive' : ''
						} Distance`}
						infoSection={`${gameHighlights?.shotsAboveAverage} shots above average*`}
					>
						<div className='game-highlights__quick-info'>
							{gameHighlights && type !== ChallengeType.LongestDrive && (
								<>
									<h1>
										{gameHighlights &&
											(typeof gameHighlights.averageDistance.feet !== 'number'
												? '-'
												: Math.round(gameHighlights.averageDistance.feet ?? 0))}
									</h1>
									<h4 className='sub-title'>Feet from Pin</h4>
								</>
							)}
							{gameHighlights && type === ChallengeType.LongestDrive && (
								<>
									<h1>
										{gameHighlights &&
											(typeof gameHighlights.averageDistance.yards !== 'number'
												? '-'
												: Math.round(
														gameHighlights.averageDistance.yards ?? 0
												  ))}
									</h1>
									<h4 className='sub-title'>Yards from Tee</h4>
								</>
							)}
						</div>
					</DataCard>
					<DataCard
						title='Shots by Location'
						infoSection={
							gameHighlights?.parLevel
								? `Par ${gameHighlights?.parLevel}`
								: null
						}
					>
						<div className='game-highlights__pie-chart'>
							<ResponsiveContainer width='100%' height={425}>
								<PieChart>
									<Pie
										dataKey='value'
										data={shotLocationData}
										innerRadius={95}
										outerRadius={125}
										fill='#82ca9d'
										paddingAngle={4}
										onClick={(_, index, e) => {
											e.stopPropagation();
											setActiveIndex(index);
										}}
										activeIndex={activeIndex}
										activeShape={renderActiveShape}
										style={{ cursor: 'pointer' }}
									/>
									<Legend
										wrapperStyle={{
											display: 'grid',
											gridColumn: '3',
										}}
										content={renderCustomizedLegend}
									/>
								</PieChart>
							</ResponsiveContainer>
						</div>
					</DataCard>
					{trendingRegion && (
						<DataCard
							title='Trending Region'
							infoSection={`${trendingRegion.totalShotsInRegion} out of ${gameHighlights?.shotsTaken} shots total`}
						>
							<div className='game-highlights__trending-region'>
								<div>
									<h1>{trendingRegion.name}</h1>
									<h4 className='sub-title'>
										{`${trendingRegion.trendingShots} shots since ${new Date(
											trendingRegion.trendingTime ?? ''
										)
											.toLocaleTimeString('en-US', {
												hour: '2-digit',
												minute: '2-digit',
											})
											.toLowerCase()}`}
									</h4>
								</div>
								<div className='square-regions'>{squares}</div>
							</div>
						</DataCard>
					)}
					<DataCard title='* Number includes mulligans' infoSection={null} />
				</>
			)}
			{error && (
				<ErrorBanner message='Failed to fetch data' click={refetchRound} />
			)}
		</section>
	);
};

export default GameHighlights;
