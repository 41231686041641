import React from 'react';

import LinkedInIcon from '../../../../icons/LinkedInIcon';
import TwitterIcon from '../../../../icons/TwitterIcon';
import YouTubeIcon from '../../../../icons/YouTubeIcon';
import useGetMapParams from '../../hooks/useGetMapParams';
import useGetSocialLinks from '../../hooks/useGetSocialLinks';
import './AppFooter.scss';

const AppFooter = () => {
	const { eventId } = useGetMapParams();

	const {
		footerTitle,
		aboutUrl,
		contactUrl,
		linkedinUrl,
		twitterUrl,
		youtubeUrl,
		isOrg,
	} = useGetSocialLinks(eventId);

	if (!isOrg) return null;

	return (
		<div className='app-footer'>
			<div className='app-footer__title'>{footerTitle}</div>
			{aboutUrl && (
				<div>
					<a
						aria-label='About Url'
						target='_blank'
						rel='noreferrer'
						href={aboutUrl}
					>
						About {footerTitle}
					</a>
				</div>
			)}
			{contactUrl && (
				<div>
					<a
						aria-label='Contact Url'
						target='_blank'
						rel='noreferrer'
						href={contactUrl}
					>
						Contact
					</a>
				</div>
			)}
			<div className='social-media-container'>
				{linkedinUrl && (
					<a
						aria-label='LinkedIn Url'
						target='_blank'
						rel='noreferrer'
						href={linkedinUrl}
					>
						<LinkedInIcon width='32' height='32' fill='white' />
					</a>
				)}
				{twitterUrl && (
					<a
						aria-label='Twitter Url'
						target='_blank'
						rel='noreferrer'
						href={twitterUrl}
					>
						<TwitterIcon width='32' height='32' fill='white' />
					</a>
				)}
				{youtubeUrl && (
					<a
						aria-label='Youtube Url'
						target='_blank'
						rel='noreferrer'
						href={youtubeUrl}
					>
						<YouTubeIcon width='32' height='32' fill='white' />
					</a>
				)}
			</div>
		</div>
	);
};

export default AppFooter;
