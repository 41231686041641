import React, { SVGProps } from 'react';

interface IGolfTeeIconProps {
	width: string;
	height: string;
	fill: string;
}

const GolfTeeIcon = ({
	width,
	height,
	fill,
	...props
}: SVGProps<SVGSVGElement> & IGolfTeeIconProps) => (
	<svg
		width={width}
		height={height}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.8637 2.01405C9.17832 -0.671348 4.82444 -0.671348 2.13905 2.01405C-0.546348 4.69944 -0.546348 9.05332 2.13905 11.7387C4.82444 14.4241 9.17832 14.4241 11.8637 11.7387C14.5491 9.05332 14.5491 4.69944 11.8637 2.01405ZM2.82768 2.70268C5.13275 0.397607 8.87001 0.397607 11.1751 2.70268C13.4802 5.00775 13.4802 8.74501 11.1751 11.0501C8.87001 13.3552 5.13275 13.3552 2.82768 11.0501C0.522607 8.74501 0.522607 5.00775 2.82768 2.70268ZM9.37283 5.24826C9.16046 5.24826 8.98349 5.17747 8.84191 5.03589C8.70033 4.89431 8.62954 4.71734 8.62954 4.50497C8.62954 4.29261 8.70033 4.11563 8.84191 3.97405C8.98349 3.83248 9.16046 3.76169 9.37283 3.76169C9.5852 3.76169 9.76217 3.83248 9.90375 3.97405C10.0453 4.11563 10.1161 4.29261 10.1161 4.50497C10.1161 4.71734 10.0453 4.89431 9.90375 5.03589C9.76217 5.17747 9.5852 5.24826 9.37283 5.24826ZM5.76258 5.14208C5.90416 5.28365 6.08113 5.35445 6.2935 5.35445C6.50586 5.35445 6.68284 5.28366 6.82442 5.14208C6.96599 5.0005 7.03678 4.82353 7.03678 4.61116C7.03678 4.39879 6.96599 4.22182 6.82442 4.08024C6.68284 3.93866 6.50586 3.86787 6.2935 3.86787C6.08113 3.86787 5.90416 3.93866 5.76258 4.08024C5.621 4.22182 5.55021 4.39879 5.55021 4.61116C5.55021 4.82353 5.621 5.0005 5.76258 5.14208ZM8.73573 8.11522C8.8773 8.2568 9.05428 8.32759 9.26664 8.32759C9.47901 8.32759 9.65598 8.2568 9.79756 8.11522C9.93914 7.97365 10.0099 7.79667 10.0099 7.5843C10.0099 7.37194 9.93914 7.19496 9.79756 7.05339C9.65598 6.91181 9.47901 6.84102 9.26665 6.84102C9.05428 6.84102 8.8773 6.91181 8.73573 7.05339C8.59415 7.19496 8.52336 7.37194 8.52336 7.58431C8.52336 7.79667 8.59415 7.97364 8.73573 8.11522ZM6.23686 20V18.7768C6.23686 18.1652 6.04149 17.6641 5.65076 17.2733C5.26002 16.8826 4.75886 16.6872 4.14727 16.6872H1.90479V15.9818C1.90479 15.527 2.27349 15.1583 2.72831 15.1583H11.2744C11.7292 15.1583 12.0979 15.527 12.0979 15.9818V16.6872H9.85542C9.24383 16.6872 8.74267 16.8826 8.35193 17.2733C7.9612 17.6641 7.76583 18.1652 7.76583 18.7768V20H6.23686Z'
			fill='current'
		/>
	</svg>
);

export default GolfTeeIcon;
