import { useMemo } from 'react';

import { useLongestDriveChallengeDetailsQuery } from '../../../../../api/challenges/queries/useGetChallengeDetailsQuery';
import TeeLocation from '../../../../../domain/models/tee-location';
import { useAppSelector } from '../../../../../redux/store';
import useModal from '../../../../shared/hooks/useModal';
import MapMarker from '../../../../shared/models/map-marker';
import useGetClusters from '../../../shared/selectors/useGetClusters';
import useGetFirstSelectedCluster from '../../../shared/selectors/useGetFirstSelectedCluster';
import useGetPlayerStrokes from '../../../shared/selectors/useGetPlayerStrokes';

const useScatterplot = (eventId: number, roundId: number, holeNum: number) => {
	const { teeType } = useAppSelector(state => state.ctp);

	const { challengeDetails, isLoading, error, refetch } =
		useLongestDriveChallengeDetailsQuery(eventId, roundId, holeNum);

	const selectedTee = challengeDetails?.teeLocations?.find(
		x => x.teeLocation.type === teeType
	);

	const { allPlayers } = useGetPlayerStrokes(challengeDetails);

	const clusteredPlayerStrokes = useGetClusters(
		allPlayers,
		selectedTee?.teeLocation.location
	);

	const marketingModal = useModal('marketingModal');

	const selectedCluster = useGetFirstSelectedCluster(clusteredPlayerStrokes);

	const teeLocations: TeeLocation[] = [];
	if (selectedTee) teeLocations.push(selectedTee.teeLocation);

	return {
		modal: {
			marketingModal,
		},
		data: {
			mapMarkers: useMemo(
				() =>
					clusteredPlayerStrokes.map(
						x =>
							new MapMarker(
								x.strokeIds,
								x.ballLocation,
								x.playerStrokes.length === 1
									? x.playerStrokes[0].fullName
									: 'Player Cluster',
								x.numberOfShots > 1 ? x.numberOfShots.toString() : undefined
							)
					),
				[clusteredPlayerStrokes]
			),
			pinLocation: challengeDetails?.hole.pinLocation || {
				latitude: -1,
				longitude: -1,
			},
			error,
			isLoading,
			refreshData: () => refetch(),
		},
		mapProps: {
			center: useMemo(
				() => ({
					latitude: challengeDetails?.hole.pinLocation.latitude || -1,
					longitude: challengeDetails?.hole.pinLocation.longitude || -1,
				}),
				[challengeDetails?.hole]
			),
			teeLocations,
			panTo: {
				latitude: selectedCluster?.ballLocation.latitude || 0,
				longitude: selectedCluster?.ballLocation.longitude || 0,
			},
			fitBounds: useMemo(() => {
				if (selectedTee && challengeDetails)
					return [
						selectedTee.teeLocation.location,
						challengeDetails.hole.pinLocation,
					];
				return undefined;
			}, [selectedTee]),
		},
	};
};

export default useScatterplot;
