import Leaderboard from '../../../domain/models/leaderboard';
import { SlLeaderboard } from '../schema';
import { toLeaderboardParticipant } from './toParticipant';

// Even if someone hits a hole in one, the calculation won't be exactly 0 because the hole and ball coordinates won't match perfectly. So, we are manually updating the distance to hole
// to 0 on the frontend (after the backend already sorted the values), which means we need to double check hole in one values are first in list
const moveHoleInOneToFirst = (
	distance1: number | undefined,
	distance2: number | undefined
) =>
	// eslint-disable-next-line no-nested-ternary
	distance1 === 0 ? -1 : distance2 === 0 ? 1 : 0;

const toLeaderboard = (slLeaderboard: SlLeaderboard) =>
	new Leaderboard(
		slLeaderboard.currentGroup
			.map(x => toLeaderboardParticipant(x))
			.sort((p1, p2) =>
				moveHoleInOneToFirst(p1.distanceToHole?.feet, p2.distanceToHole?.feet)
			),
		slLeaderboard.overallStandings
			.map(x => toLeaderboardParticipant(x))
			.sort((p1, p2) =>
				moveHoleInOneToFirst(p1.distanceToHole?.feet, p2.distanceToHole?.feet)
			),
		slLeaderboard.amateurMen
			.map(x => toLeaderboardParticipant(x))
			.sort((p1, p2) =>
				moveHoleInOneToFirst(p1.distanceToHole?.feet, p2.distanceToHole?.feet)
			),
		slLeaderboard.amateurWomen
			.map(x => toLeaderboardParticipant(x))
			.sort((p1, p2) =>
				moveHoleInOneToFirst(p1.distanceToHole?.feet, p2.distanceToHole?.feet)
			),
		slLeaderboard.juniorMen
			.map(x => toLeaderboardParticipant(x))
			.sort((p1, p2) =>
				moveHoleInOneToFirst(p1.distanceToHole?.feet, p2.distanceToHole?.feet)
			),
		slLeaderboard.juniorWomen
			.map(x => toLeaderboardParticipant(x))
			.sort((p1, p2) =>
				moveHoleInOneToFirst(p1.distanceToHole?.feet, p2.distanceToHole?.feet)
			)
	);

export default toLeaderboard;
