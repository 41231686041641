import Stroke from '../../../../domain/models/stroke';
import { DivisionType } from '../../../../domain/types/enum-types';

export default class Player {
	constructor(
		public id: number,
		public groupId: number,
		public groupName: string,
		public firstName: string,
		public lastName: string,
		public age: number,
		public division: DivisionType | null,
		public strokes: Stroke[]
	) {}

	static clone(player: Player) {
		return new Player(
			player.id,
			player.groupId,
			player.groupName,
			player.firstName,
			player.lastName,
			player.age,
			player.division,
			player.strokes
		);
	}

	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	get initials() {
		return `${this.firstName.charAt(0).toUpperCase()}${this.lastName
			.charAt(0)
			.toUpperCase()}`;
	}

	get strokeIds() {
		return this.strokes.map(x => x.id);
	}

	includesStroke(ids: number[]) {
		return this.strokeIds.some(x => ids.includes(x));
	}
}
