import SortService from '../../services/SortService';
import ChallengeType from '../../types/challenge-type';
import Distance from '../../types/distance';
import { StrokeLocation } from '../../types/enum-types';
import PinPointGroup from '../group/pin-point-group';
import Hole from '../hole';

export default class PinPointChallengeDetails {
	constructor(
		public challengeType: ChallengeType,
		public groups: PinPointGroup[],
		public hole: Hole,
		public averageDistance: Distance
	) {}

	get participantStrokes() {
		return this.groups.flatMap(x => x.participants.map(y => y.stroke));
	}

	get participantsWithGroupDetails() {
		return this.groups.flatMap(group =>
			group.participants.flatMap(participant => ({
				groupName: group.name,
				...participant,
			}))
		);
	}

	get participantsSortedByDistanceToHole() {
		return [
			{
				section: 'On the Green',
				infoText:
					'Shots on the green are calculated and prioritized before shots off the green.',
				players: this.participantsWithGroupDetails
					.filter(
						x =>
							x.stroke.courseLocation === StrokeLocation.green ||
							x.stroke.courseLocation === StrokeLocation.hole
					)
					.sort((p1, p2) =>
						SortService.sortStrokesByScore(
							p1.stroke.holeDistance.feet,
							p2.stroke.holeDistance.feet
						)
					),
			},
			{
				section: 'Off the Green',
				infoText:
					'Shots off the green are calculated and prioritized after shots on the green.',
				players: this.participantsWithGroupDetails
					.filter(
						x =>
							x.stroke.courseLocation !== StrokeLocation.green &&
							x.stroke.courseLocation !== StrokeLocation.hole
					)
					.sort((p1, p2) =>
						SortService.sortStrokesByScore(
							p1.stroke.holeDistance.feet,
							p2.stroke.holeDistance.feet
						)
					),
			},
		];
	}
}
