import { TeeType } from '../types/enum-types';
import LatLong from '../types/lat-long';
import TeeLocation from './tee-location';

export default class Hole {
	constructor(
		public id: number,
		public holeNumber: number,
		public par: number,
		public teeLocations: TeeLocation[],
		public pinLocation: LatLong
	) {}

	get defaultTeeLocation() {
		return this.teeLocations.find(x => x.type === TeeType.default)?.location;
	}
}
