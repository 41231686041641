import React from 'react';

const WarningCircleIcon = () => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.7972 14.1717L13.0472 6.29667H10.7112L10.9769 14.1717H12.7972ZM11.0081 15.7576C10.7894 15.9868 10.68 16.268 10.68 16.6014C10.68 16.9399 10.7894 17.2264 11.0081 17.4607C11.2269 17.6899 11.5159 17.8045 11.8753 17.8045C12.2451 17.8045 12.5368 17.6899 12.7503 17.4607C12.9691 17.2264 13.0784 16.9399 13.0784 16.6014C13.0784 16.268 12.9691 15.9868 12.7503 15.7576C12.5368 15.5232 12.2451 15.406 11.8753 15.406C11.5159 15.406 11.2269 15.5232 11.0081 15.7576Z'
			fill='#0062AF'
		/>
	</svg>
);

export default WarningCircleIcon;
