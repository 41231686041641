import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual } from 'fast-equals';
import React, { useEffect, useMemo, useState } from 'react';

import { useLazyGetOverlayConfigQuery } from '../../../../../api/maps-api/maps-api';
import ChallengeType from '../../../../../domain/types/challenge-type';
import { MapType } from '../../../../../domain/types/enum-types';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
	clearPanTo,
	setInitialZoomLevel,
	setManualMapScale,
} from '../../../../challenges/challenge-reducer';
import useGetMapParams from '../../../hooks/useGetMapParams';
import useWindowResize from '../../../hooks/useWindowResize';
import {
	getCircleColors,
	mapColors,
} from '../../Markers/marker-helper-functions';
import { useMapProps } from '../types';

const deepCompareEqualsForMaps = createCustomEqual(
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deepEqual => (a: any, b: any) => {
		if (
			isLatLngLiteral(a) ||
			a instanceof google.maps.LatLng ||
			isLatLngLiteral(b) ||
			b instanceof google.maps.LatLng
		) {
			return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
		}

		// TODO extend to other types

		// use fast-equals for other objects
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return deepEqual(a, b);
	}
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDeepCompareMemoize = (value: any) => {
	const ref = React.useRef();

	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value;
	}

	return ref.current;
};

function useDeepCompareEffectForMaps(
	cb: React.EffectCallback,
	dependencies: unknown[]
) {
	useEffect(cb, dependencies.map(useDeepCompareMemoize));
}

const useGoogleMap = ({
	mapRef,
	challengeType,
	center,
	radius,
	teeLocations,
	panTo,
	fitBounds,
	shotLines,
	onMapIdle,
	onMapClick,
}: useMapProps) => {
	const { white, grey, greenOverlay } = mapColors();

	const { isMobileScreen } = useWindowResize();

	class CustomOverlay extends google.maps.OverlayView {
		private div?: HTMLElement;

		onAdd(): void {
			this.div = document.createElement('div');
			this.div.style.position = 'absolute';
			this.div.style.top = '-50vh';
			this.div.style.left = '-50%';
			this.div.style.width = '100vw';
			this.div.style.height = '100vh';
			this.div.style.background = greenOverlay;

			const panes = this.getPanes();
			panes?.overlayLayer.appendChild(this.div);
		}

		onRemove() {
			if (this.div) {
				(this.div.parentNode as HTMLElement).removeChild(this.div);
				delete this.div;
			}
		}
	}

	const [map, setMap] = useState<google.maps.Map>();
	const { preventPanTo, selectedStrokeIds, teeType } = useAppSelector(
		state => state.ctp
	);
	const dispatch = useAppDispatch();

	const { eventId, holeNumber } = useGetMapParams();

	const [getOverlayConfig, { isLoading, data: overlayResponse }] =
		useLazyGetOverlayConfigQuery();

	const [avgDistanceCircle, setAvgDistanceCircle] =
		useState<google.maps.Circle>();

	const [lineCoordinates, setLineCoordinates] = useState<
		google.maps.Polyline[]
	>([]);

	const [overlay, setOverlay] = useState<CustomOverlay>();

	const heading = useMemo(() => {
		const defaultTee = teeLocations?.find(x => x.type === teeType)?.location;

		if (defaultTee && defaultTee.latitude !== 0 && defaultTee.longitude !== 0) {
			const teePoint = new google.maps.LatLng(
				defaultTee.latitude,
				defaultTee.longitude
			);
			const pinPoint = new google.maps.LatLng(
				center.latitude,
				center.longitude
			);

			return google.maps.geometry.spherical.computeHeading(teePoint, pinPoint);
		}

		return 0;
	}, [teeLocations, center, teeType]);

	const mapCenter = { lat: center.latitude, lng: center.longitude };

	// need to show a green overlay when shots are selected for full round challenge type
	useEffect(() => {
		if (overlay) {
			overlay.setMap(null);
		}
		if (
			map &&
			challengeType === ChallengeType.FullRound &&
			selectedStrokeIds.length > 0
		) {
			const newOverlay: CustomOverlay = new CustomOverlay();
			newOverlay.setMap(map);
			setOverlay(newOverlay);
		}
	}, [map, selectedStrokeIds]);

	useEffect(() => {
		if (lineCoordinates.length > 0) {
			lineCoordinates.forEach(coord => {
				coord.setMap(null);
			});
		}
		if (map && shotLines) {
			const newCoordinates: google.maps.Polyline[] = [];
			shotLines.forEach(x => {
				const coordinates = x.locations.map(coord => ({
					lat: coord.latitude,
					lng: coord.longitude,
				}));

				const linePath = new google.maps.Polyline({
					path: coordinates,
					geodesic: true,
					strokeColor: x.isSelected ? white : grey,
					strokeWeight: x.isSelected ? 2 : 1,
				});

				// only show shot lines if a player is selected or if nothing is selected
				if (x.isSelected || (!x.isSelected && selectedStrokeIds.length === 0)) {
					newCoordinates.push(linePath);
					linePath.setMap(map);
				}
			});
			setLineCoordinates(newCoordinates);
		}
	}, [map, shotLines, selectedStrokeIds]);

	useEffect(() => {
		if (map) map.setHeading(heading);
	}, [heading]);

	useEffect(() => {
		if (avgDistanceCircle) {
			avgDistanceCircle.setMap(null);
		}
		if (map && radius) {
			const {
				fill,
				stroke,
				strokeColor: { r, g, b, a },
			} = getCircleColors(MapType.google);

			// Draw average distance from pin circle
			setAvgDistanceCircle(
				new google.maps.Circle({
					strokeColor: `rgba(${r}, ${g}, ${b}, ${a})`,
					strokeWeight: stroke,
					fillColor: fill,
					map,
					center: mapCenter,
					radius,
				})
			);
		}
	}, [map, radius]);

	useEffect(() => {
		if (avgDistanceCircle) avgDistanceCircle.addListener('click', onMapClick);

		return () => {
			if (avgDistanceCircle) {
				google.maps.event.clearListeners(avgDistanceCircle, 'idle');
			}
		};
	}, [avgDistanceCircle]);

	useEffect(() => {
		if (
			map &&
			panTo &&
			panTo.latitude !== 0 &&
			panTo.longitude !== 0 &&
			!preventPanTo
		) {
			const latLng = new google.maps.LatLng(panTo.latitude, panTo.longitude);
			map.panTo(latLng);

			// clear pan after panning to spot
			dispatch(clearPanTo());
		}
	}, [panTo, preventPanTo]);

	useEffect(() => {
		if (map && fitBounds) {
			const bounds = new google.maps.LatLngBounds();
			fitBounds.forEach(x => {
				bounds.extend(new google.maps.LatLng(x.latitude, x.longitude));
			});

			// only give it padding for desktop view
			map.fitBounds(bounds, isMobileScreen ? 0 : 150);
			map.setHeading(heading);
		}
	}, [map, fitBounds]);

	useEffect(() => {
		if (mapRef?.current && !map) {
			const newMap = new google.maps.Map(mapRef.current, {
				// Us this ID to get specific instance of a vector map, which is needed to set a heading and tilt value
				mapId: '57bd86395172bbb',
				center: mapCenter,
				minZoom: 14,
				heading,
				tilt: 0,
				gestureHandling: 'greedy',
				zoom: 20,
				mapTypeId: 'satellite',
				disableDefaultUI: true,
				rotateControl: true,
				disableDoubleClickZoom: true,
			});
			setMap(newMap);

			// set initial zoom and scale level for map
			dispatch(setInitialZoomLevel(newMap?.getZoom() ?? null));
			dispatch(setManualMapScale(1));
		}
	}, [mapRef, map]);

	// Attempts to fetch an overlay JSON for the particular hole, and if present,
	// Draws the overlay polygons based on the JSON.
	const fetchOverlayJSON = async () => {
		getOverlayConfig({ eventId, holeNumber });
		if (map && overlayResponse && !isLoading) {
			// Construct the polygons.
			const { shapes } = overlayResponse;
			shapes.forEach(shape => {
				const polygon = new google.maps.Polygon({
					paths: shape.coords,
					strokeColor: shape.stroke.color,
					strokeOpacity: shape.stroke.opacity,
					strokeWeight: shape.stroke.weight,
					fillColor: shape.fill.color,
					fillOpacity: shape.fill.opacity,
				});
				polygon.setMap(map);
			});
		}
	};
	// because React does not do deep comparisons, a custom hook is used
	// see discussion in https://github.com/googlemaps/js-samples/issues/946
	useDeepCompareEffectForMaps(() => {
		if (map) {
			// for longest drive or full round, we want to pan out to show entire hole, not zoom in on map center
			if (
				challengeType !== ChallengeType.LongestDrive &&
				challengeType !== ChallengeType.FullRound
			) {
				map.setOptions({ center: mapCenter });
			}

			map.addListener('click', onMapClick);

			fetchOverlayJSON();
		}
	}, [map, center, challengeType]);

	useEffect(() => {
		if (map) {
			google.maps.event.clearListeners(map, 'idle');

			// kick off re-cluster after map has been idle for 500ms
			let timeoutId: number;
			map.addListener('idle', () => {
				timeoutId = onMapIdle(timeoutId, map?.getZoom());
			});
		}

		return () => {
			if (map) {
				mapRef = null;
				google.maps.event.clearListeners(map, 'idle');
				google.maps.event.clearListeners(map, 'click');
			}
		};
	}, [map]);

	return map;
};

export default useGoogleMap;
