import React from 'react';

import './ScrollableTable.scss';

export interface TableColumn {
	id: string;
	label: string;
	styles?: { [key: string]: string | number | boolean };
}

interface Props {
	columns: TableColumn[];
	data: {
		[key: string]: string | number;
	}[];
	classes?: string;
	tableStyles?: { [key: string]: string | number | boolean };
}

const ScrollableTable = ({ columns, data, classes, tableStyles }: Props) => (
	<section
		className={`scrollable-table ${classes ?? ''}`}
		style={tableStyles ?? {}}
	>
		<div>
			<table cellSpacing={0}>
				<thead>
					<tr>
						{columns.map(column => (
							<th key={column.id} align='left' style={column.styles ?? {}}>
								<div>{column.label}</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data?.map((row, index) => (
						<tr key={`${index.toString()}`}>
							{columns.map((column, idx) => {
								const value = row[column.id as keyof typeof row];
								if (idx === 0) {
									return (
										<td
											key={column.id}
											align='left'
											style={column.styles ?? {}}
										>
											<div>{value}</div>
										</td>
									);
								}
								return (
									<td key={column.id} align='left' style={column.styles ?? {}}>
										{value}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	</section>
);

export default ScrollableTable;
