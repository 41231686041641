import React, { useMemo } from 'react';

import useGetMarketingDetailsQuery from '../../../api/common/queries/useGetMarketingDetailsQuery';
import HeroDefaultBackground from '../../../assets/marketing/DefaultBackground.png';
import ArrowRightIcon from '../../../icons/ArrowRight';
import Carousel from '../../shared/components/Carousel/Carousel';
import { getImageFromBucket } from '../../shared/helper-functions';
import useGetMapParams from '../../shared/hooks/useGetMapParams';

const useMarketingLandingPage = () => {
	const { eventId } = useGetMapParams();
	const { marketingDetails, isLoading } = useGetMarketingDetailsQuery(eventId);

	const heightOfCarousel = marketingDetails?.showImages ? '275px' : '200px';

	const heroBackgroundImage = useMemo(() => {
		let image: string | null = null;
		if (marketingDetails?.heroImagePath) {
			// FYI : if testing locally and not connected to shell app, won't return an image path even if there is one since we don't know the S3 bucket name
			image = getImageFromBucket(marketingDetails.heroImagePath);
		}
		return image ?? HeroDefaultBackground;
	}, [marketingDetails?.heroImagePath]);

	const howToPlaySection = useMemo(() => {
		const itemList = [...(marketingDetails?.rules ?? [])].map(item => (
			<li className='text-[16px]' key={item}>
				{item}
			</li>
		));
		return (
			<div>
				<ol className='custom-counter space-y-10'>
					{itemList}
					{(marketingDetails?.linkUrl.length ?? 0) > 0 && (
						<li className='text-[16px]'>
							<div className='pt-[6px] flex flex-row items-center space-x-2'>
								<a
									aria-label='Marketing External Link'
									target='_blank'
									rel='noreferrer'
									href={marketingDetails?.linkUrl}
									className='text-[16px] align-middle font-bold text-ct-blue'
								>
									Register to Play
								</a>
								<ArrowRightIcon fill='#003865' />
							</div>
						</li>
					)}
				</ol>
			</div>
		);
	}, [marketingDetails]);

	const promotionSection = useMemo(() => {
		const prizeCards = marketingDetails?.prizes.map(prize => {
			const prizeImg = getImageFromBucket(prize.imagePath);
			return (
				<div className='carousel-card' key={prize.id}>
					<div className='text-left space-y-5 md:max-w-xs'>
						{marketingDetails?.showImages && (
							<div className='h-[88px] w-[88px] flex flex-col justify-center items-center'>
								{prizeImg && (
									<img
										src={prizeImg}
										alt={`Prize img for ${prize.id}`}
										width='88'
										height='88'
									/>
								)}
								{!prizeImg && <p>No image in localhost</p>}
							</div>
						)}
						<p className='text-[18px] font-medium'>{prize.title}</p>
						<p className='text-[16px] font-normal text-[#737373]'>
							{prize.description}
						</p>
					</div>
				</div>
			);
		});

		return <Carousel cards={prizeCards ?? []} height={heightOfCarousel} />;
	}, [marketingDetails]);

	return {
		marketingDetails,
		isLoading,
		heroBackgroundImage,
		howToPlaySection,
		promotionSection,
	};
};

export default useMarketingLandingPage;
