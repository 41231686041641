import { useMemo } from 'react';

import commonApi from '../common-api';
import toEvent from '../transforms/toEvent';

const useGetEventQuery = (eventId: number) => {
	const { data, refetch, ...rest } = commonApi.useGetEventQuery(eventId);

	return {
		event: useMemo(() => (data ? toEvent(data) : undefined), [data]),
		refetchEvent: refetch,
		...rest,
	};
};

export default useGetEventQuery;
