import SessionStorageService from "./SessionStorageService";

export function createGetQuery(url: string) {
	return {
		url,
		headers: {
			Authorization: `Bearer ${SessionStorageService.apiKey}`,
		},
	};
}

export function createPostQuery<T>(url: string, body: T) {
	return {
		url,
		method: 'POST',
		body,
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
			Authorization: `Bearer ${SessionStorageService.apiKey}`,
		},
	};
}

export interface SlResponseArray<T> {
	args: {
		orgId: number;
		page: number;
		size: number;
	};
	content: T[];
	pageRequest: {
		offset: number;
		page: number;
		size: number;
	};
	totalElements: number;
}
