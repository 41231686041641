import { OrgLinkType } from '../types/enum-types';

export default class OrgLink {
	constructor(
		public id: number,
		public orgId: number,
		public type: OrgLinkType,
		public label: OrgLinkType,
		public link: string | null,
		public organizationLinkType: OrgLinkType
	) {}
}
