import Distance from '../../types/distance';
import { DivisionType, StrokeLocation } from '../../types/enum-types';
import Participant from './participant';

export default class LeaderboardParticipant implements Participant {
	constructor(
		public id: number,
		public groupId: number,
		public firstName: string,
		public lastName: string,
		public city: string,
		public state: string,
		public description: string,
		public gender: string,
		public age: number,
		public division: DivisionType | null,
		public distanceToHole: Distance | null,
		public courseLocation: StrokeLocation
	) {}

	get abbreviatedName() {
		return `${this.firstName[0]}. ${this.lastName}`;
	}
}
