import React, { SVGProps } from 'react';

const HoleIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='14'
		height='16'
		viewBox='0 0 14 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.75 0.536254L3.74997 0.541667V13H4.83331L4.83333 8.01074L12.8025 4.82545C13.3475 4.60761 13.3474 3.83614 12.8024 3.6183L4.82141 0.428248C4.76928 0.183543 4.55189 0 4.29164 0C4.17828 0 4.07304 0.0348306 3.98606 0.0943645C3.84489 0.190988 3.7518 0.352698 3.75 0.536254ZM4.83333 1.59968L11.3937 4.22188L4.83333 6.84407L4.83333 1.59968Z'
			fill='#003865'
		/>
		<path
			d='M0.5 12.8195C0.5 11.947 1.49014 11.1708 3.02777 10.6755V11.8223C2.9567 11.849 2.88758 11.8764 2.82047 11.9044C2.31069 12.1168 1.96888 12.3421 1.77107 12.537C1.67525 12.6314 1.6272 12.7049 1.60445 12.7512C1.58362 12.7936 1.58333 12.8137 1.58333 12.8195C1.58333 12.8252 1.58362 12.8453 1.60445 12.8877C1.6272 12.934 1.67525 13.0075 1.77107 13.1019C1.96888 13.2968 2.31069 13.5221 2.82047 13.7345C3.8353 14.1574 5.31236 14.4445 7 14.4445C8.68764 14.4445 10.1647 14.1574 11.1795 13.7345C11.6893 13.5221 12.0311 13.2968 12.2289 13.1019C12.3248 13.0075 12.3728 12.934 12.3955 12.8877C12.4164 12.8453 12.4167 12.8252 12.4167 12.8195C12.4167 12.8137 12.4164 12.7936 12.3955 12.7512C12.3728 12.7049 12.3248 12.6314 12.2289 12.537C12.0311 12.3421 11.6893 12.1168 11.1795 11.9044C10.1647 11.4815 8.68764 11.1945 7 11.1945C6.49764 11.1945 6.01395 11.2199 5.55555 11.2668V10.1782C6.0201 10.1343 6.50355 10.1111 7 10.1111C10.5899 10.1111 13.5 11.3237 13.5 12.8195C13.5 14.3152 10.5899 15.5278 7 15.5278C3.41015 15.5278 0.5 14.3152 0.5 12.8195Z'
			fill='#003865'
		/>
	</svg>
);

export default HoleIcon;
