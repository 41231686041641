import React, { useMemo, useState } from 'react';

import Challenge from '../../../../domain/models/challenge';
import ChallengeType from '../../../../domain/types/challenge-type';
import ChevronRightIcon from '../../../../icons/ChevronRight';
import HoleIcon from '../../../../icons/HoleIcon';
import GameHighlightsModal from '../../../shared/components/GameHightlightsModal/GameHighlightsModal';
import { lowerCaseFirstLetter } from '../../../shared/helper-functions';
import useModal from '../../../shared/hooks/useModal';
import useNavigateToPage from '../../../shared/hooks/useNavigateToPage';
import Modal from '../../../shared/layout/Modal/Modal';
import './ChallengeDetailsCard.scss';
import challengeTypeRules from './ChallengeTypeRules.json';

interface Props {
	eventId: number;
	challenge: Challenge;
}

enum ModalType {
	ViewRules,
	Highlights,
}

const CommonDetailsCard = ({
	eventId,
	challenge: {
		id: challengeId,
		name,
		type,
		description,
		mapType,
		activePlayers,
		maxDistanceFromTeeToPin,
		minDistanceFromTeeToPin,
		averageDistanceToPin,
		averageDriveDistance,
		holes,
	},
}: Props) => {
	const navigateToPage = useNavigateToPage();
	const { isModalOpen, toggleModal } = useModal();

	const [modalType, setModalType] = useState<ModalType>();

	const challengeRules =
		challengeTypeRules[
			lowerCaseFirstLetter(type.toString()) as keyof typeof challengeTypeRules
		];

	const [holeNumber, par] = useMemo(() => {
		if (holes.length > 0) {
			return [holes[0].holeNumber, holes[0].par];
		}
		return [1, 3];
	}, [holes]);

	const holeInfo = useMemo(() => {
		let info = '';
		if (holeNumber) {
			info += `Hole ${holeNumber}`;
		}
		if (par) {
			info += `${info !== '' ? ', ' : ''}Par ${par}`;
		}
		if (
			maxDistanceFromTeeToPin &&
			minDistanceFromTeeToPin &&
			maxDistanceFromTeeToPin.yards !== 0 &&
			minDistanceFromTeeToPin.yards !== 0
		) {
			const maxDistance = Math.round(maxDistanceFromTeeToPin.yards);
			const minDistance = Math.round(minDistanceFromTeeToPin.yards);

			let yards = '';
			if (maxDistanceFromTeeToPin.yards === minDistanceFromTeeToPin.yards) {
				yards = maxDistance.toString();
			} else {
				yards = `${minDistance} - ${maxDistance}`;
			}
			info += `${info !== '' ? ' | ' : ''}${yards} yards`;
		}
		return info;
	}, [par, maxDistanceFromTeeToPin?.yards, holeNumber]);

	const avgDistance = useMemo(() => {
		let distance = null;
		if (
			(type === ChallengeType.PinPoint ||
				type === ChallengeType.PinPointLeaderboard) &&
			averageDistanceToPin &&
			!Number.isNaN(Number(averageDistanceToPin.feet))
		) {
			distance = `${Math.round(averageDistanceToPin.feet)} ft. Avg Distance`;
		} else if (
			type === ChallengeType.LongestDrive &&
			averageDriveDistance &&
			!Number.isNaN(Number(averageDriveDistance.yards))
		) {
			distance = `${Math.round(
				averageDriveDistance.yards
			)}yds Avg Drive Distance`;
		}

		return distance;
	}, [averageDistanceToPin, averageDriveDistance, type]);

	return (
		<section className='challenge-card'>
			<div className='text-[12px] md:text-[14px] text-[#737373] tracking-normal'>
				{type === ChallengeType.PinPoint && <span>PinPoint Challenge</span>}
				{type === ChallengeType.PinPointLeaderboard && (
					<span>PinPoint Leaderboard Challenge</span>
				)}
				{type === ChallengeType.LongestDrive && (
					<span>Longest Drive Challenge</span>
				)}
				<span className='text-[8px] md:text-[10px] relative bottom-1'>TM</span>
			</div>
			<div className='challenge-card__title'>
				<h4>{name}</h4>
			</div>
			{holeInfo && (
				<div className='challenge-card__hole-info flex items-center'>
					<HoleIcon />
					<p className='pl-2'>{holeInfo}</p>
				</div>
			)}
			<hr style={{ borderTop: '1px solid #DADADA' }} />
			<div className='challenge-card__body'>
				<p>
					<span className={`${description ? 'mr-[5px]' : ''}`}>
						{description}
					</span>
					<button
						type='button'
						onClick={() => {
							toggleModal();
							setModalType(ModalType.ViewRules);
						}}
					>
						View Rules
					</button>
				</p>
			</div>
			<div className='text-[12px] md:text-[14px] text-[#737373] tracking-normal mt-[15px]'>
				Current Results
			</div>
			<div className='challenge-card__round-info'>
				<div className='challenge-card__round-detail'>
					<span>{`${activePlayers} Active Players`}</span>
				</div>
				{avgDistance && (
					<div className='challenge-card__round-detail'>
						<span>{avgDistance}</span>
					</div>
				)}
			</div>
			<div className='challenge-card__action-buttons'>
				<button
					type='button'
					className='results'
					onClick={() => {
						const path = `${challengeId}/holes/${holeNumber}`;
						switch (type) {
							case ChallengeType.PinPoint: {
								navigateToPage(`${path}/pinpoint?mapType=${mapType}`);
								break;
							}
							case ChallengeType.PinPointLeaderboard: {
								navigateToPage(`${path}/pinpointleaderboard`);
								break;
							}
							case ChallengeType.LongestDrive: {
								navigateToPage(`${path}/longestdrive?mapType=${mapType}`);
								break;
							}
							default:
								break;
						}
					}}
				>
					<span>FULL RESULTS</span>
				</button>
				<button
					type='button'
					className='highlights'
					onClick={() => {
						toggleModal();
						setModalType(ModalType.Highlights);
					}}
				>
					<span style={{ marginRight: '5px' }}>Highlights</span>
					<ChevronRightIcon />
				</button>
			</div>
			<GameHighlightsModal
				eventId={eventId}
				roundId={challengeId}
				holeNumber={holeNumber}
				isOpen={isModalOpen && modalType === ModalType.Highlights}
				toggleModal={toggleModal}
			/>
			<Modal
				isVisible={isModalOpen && modalType === ModalType.ViewRules}
				toggleModal={toggleModal}
				handleSubmit={toggleModal}
				showBottomButtons={false}
			>
				<div className='challenge-card__rules-modal'>
					<div className='header'>
						<h2>Welcome to {challengeRules?.title} Challenge</h2>
					</div>
					<div className='content'>
						{challengeRules?.rules.map(rule => (
							<p key={rule}>{rule}</p>
						))}
					</div>
					<div className='footer'>
						<button
							type='button'
							onClick={e => {
								e.stopPropagation();
								toggleModal();
							}}
						>
							CLOSE
						</button>
					</div>
				</div>
			</Modal>
		</section>
	);
};

export default CommonDetailsCard;
