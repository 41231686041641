import LatLong from '../types/lat-long';

export default class LatLongService {
	static toRadians(degrees: number) {
		return degrees * (Math.PI / 180);
	}

	static getDistanceFromLatLong(
		lat1: number,
		long1: number,
		lat2: number,
		long2: number
	) {
		const R = 6371;
		const dLat = LatLongService.toRadians(lat2 - lat1);
		const dLon = LatLongService.toRadians(long2 - long1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(LatLongService.toRadians(lat1)) *
				Math.cos(LatLongService.toRadians(lat2)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;
		return d * 1000;
	}

	static findCenterPoint(coordData: LatLong[]) {
		const result = [] as LatLong[];
		let latXTotal = 0;
		let latYTotal = 0;
		let lonDegreesTotal = 0;
		let totalCount = 0;
		for (let i = 0; i < coordData.length; i++) {
			if (coordData[i].latitude !== 0 && coordData[i].longitude !== 0) {
				const latDegrees = coordData[i].latitude;
				const lonDegrees = coordData[i].longitude;
				const latRadians = LatLongService.toRadians(latDegrees);
				latXTotal += Math.cos(latRadians);
				latYTotal += Math.sin(latRadians);
				lonDegreesTotal += lonDegrees;
				totalCount++;
			}
		}
		const finalLatRadians = Math.atan2(latYTotal, latXTotal);
		const finalLatDegrees = (finalLatRadians * 180) / Math.PI;
		const finalLonDegrees = lonDegreesTotal / totalCount;
		result.push({ latitude: finalLatDegrees, longitude: finalLonDegrees });
		return result;
	}
}
