import React, { SVGProps } from 'react';

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='56'
		height='50'
		viewBox='0 0 56 50'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className='warning-icon'
	>
		<path
			d='M27.998 0C29.5512 0 30.9841 0.837053 31.7717 2.20982L55.3984 43.2812C56.1969 44.6652 56.1969 46.3728 55.4202 47.7567C54.6436 49.1406 53.1888 50 51.6247 50H4.37128C2.8071 50 1.35231 49.1406 0.575692 47.7567C-0.200926 46.3728 -0.189988 44.654 0.597568 43.2812L24.2243 2.20982C25.0118 0.837053 26.4447 0 27.998 0ZM27.998 14.2857C26.5432 14.2857 25.3728 15.4799 25.3728 16.9643V29.4643C25.3728 30.9487 26.5432 32.1429 27.998 32.1429C29.4528 32.1429 30.6232 30.9487 30.6232 29.4643V16.9643C30.6232 15.4799 29.4528 14.2857 27.998 14.2857ZM31.4982 39.2857C31.4982 37.3103 29.934 35.7143 27.998 35.7143C26.0619 35.7143 24.4977 37.3103 24.4977 39.2857C24.4977 41.2612 26.0619 42.8571 27.998 42.8571C29.934 42.8571 31.4982 41.2612 31.4982 39.2857Z'
			fill='#BDBCBC'
		/>
	</svg>
);

export default WarningIcon;
