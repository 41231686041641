import Region from '../../../domain/models/region';
import { SlRegion } from '../schema';

const toRegion = (slRegion: SlRegion) =>
	new Region(
		slRegion.region,
		slRegion.trendingShots,
		slRegion.trendingTime,
		slRegion.totalShotsInRegion
	);

export default toRegion;
