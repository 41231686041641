import LatLongService from '../../../../domain/services/LatLongService';
import { SortOption } from '../../../../domain/types/enum-types';
import LatLong from '../../../../domain/types/lat-long';
import Cluster from '../models/cluster';
import PlayerStroke from '../models/player-stroke';

class ClusteringService {
	static get maxZoomLevel() {
		return 20;
	}

	static get minClusterLevel() {
		return 1.5;
	}

	static calculateClusterLevel(zoomLevel: number) {
		const zoomLevelInt = Math.round(zoomLevel);
		const changeInZoomLevel = ClusteringService.maxZoomLevel - zoomLevelInt;
		const farMultiplier =
			ClusteringService.minClusterLevel * 2 ** (changeInZoomLevel + 1);
		const mediumMultiplier =
			ClusteringService.minClusterLevel * 4 * (changeInZoomLevel * 1);
		const closeMultiplier =
			ClusteringService.minClusterLevel * (changeInZoomLevel + 1);
		// eslint-disable-next-line no-nested-ternary
		return zoomLevelInt > 17
			? closeMultiplier
			: zoomLevelInt > 15
			? mediumMultiplier
			: farMultiplier;
	}

	static createClusters(
		clusterData: PlayerStroke[][],
		originCoordinates: LatLong
	) {
		return clusterData.map((cluster, index) => {
			const clusteredPoint = LatLongService.findCenterPoint(
				cluster.map(x => x.ballLocation)
			);
			const clusterPinDistance =
				clusteredPoint[0].latitude !== 0 && clusteredPoint[0].longitude !== 0
					? LatLongService.getDistanceFromLatLong(
							clusteredPoint[0].latitude,
							clusteredPoint[0].longitude,
							originCoordinates.latitude,
							originCoordinates.longitude
					  )
					: null;

			return new Cluster(
				index,
				{
					latitude: clusteredPoint[0].latitude,
					longitude: clusteredPoint[0].longitude,
				},
				cluster,
				clusterPinDistance
			);
		});
	}

	static findClusters(
		strokeData: PlayerStroke[],
		// clusterDistanceInMeters: number,
		zoomLevel: number,
		originCoordinates: LatLong
	) {
		const newStrokeData = [...strokeData];
		const clusters: PlayerStroke[][] = newStrokeData.map(x => [x]);

		const clusterDistanceInMeters =
			ClusteringService.calculateClusterLevel(zoomLevel);

		// How it works: use a while loop to loop through each stroke and compare it against the other strokes. Find the two strokes with the smallest distance between them.
		// Cluster them together and then delete one of the strokes from the list (so we don't keep adding it). Then go through the while loop again to find the next smallest
		// distance between a pair of strokes and cluster them together. Repeat ClusteringService process until no more strokes are able to be clustered together (since the remaining strokes
		// are too far apart from eachother).

		let keepGoing = true;
		do {
			let minimumDistance = Number.MAX_VALUE;
			let firstClusterValue = -1;
			let secondClusterValue = -1;
			for (let i = 0; i < newStrokeData.length; i++) {
				for (let j = i; j < newStrokeData.length; j++) {
					if (i !== j) {
						const distanceBetweenPoints = LatLongService.getDistanceFromLatLong(
							newStrokeData[i].ballLocation.latitude,
							newStrokeData[i].ballLocation.longitude,
							newStrokeData[j].ballLocation.latitude,
							newStrokeData[j].ballLocation.longitude
						);

						if (
							distanceBetweenPoints < clusterDistanceInMeters &&
							distanceBetweenPoints < minimumDistance
						) {
							minimumDistance = distanceBetweenPoints;
							firstClusterValue = i;
							secondClusterValue = j;
						}
					}
				}
			}
			if (firstClusterValue !== -1 && secondClusterValue !== -1) {
				const oldCluster = clusters[secondClusterValue];
				clusters[firstClusterValue].push(...oldCluster);

				clusters.splice(secondClusterValue, 1);
				newStrokeData.splice(secondClusterValue, 1);
			} else {
				keepGoing = false;
			}
		} while (keepGoing);

		return ClusteringService.createClusters(clusters, originCoordinates);
	}

	static sortClusters(clusters: Cluster[], sortOption: SortOption) {
		if (
			sortOption === SortOption.distanceAscending ||
			sortOption === SortOption.distanceDescending
		) {
			const newClusters = [...clusters];

			const isAscending = sortOption === SortOption.distanceAscending;

			newClusters.forEach((x, index) =>
				x.sortPlayerStrokesDistance(isAscending)
			);

			const sorted = ClusteringService.sortClusterDistance(
				newClusters,
				isAscending
			);

			// Give new ids for sorting
			return sorted.map((x, index) => {
				const newCluster = Cluster.clone(x);
				newCluster.orderId = index;
				return newCluster;
			});
		}

		return clusters;
	}

	static sortClusterDistance(clusterData: Cluster[], isAscending: boolean) {
		const newData = clusterData;
		newData.sort((a, b) => {
			const firstClusterDistance = a.clusterDistance;
			const secondClusterDistance = b.clusterDistance;

			if (firstClusterDistance === secondClusterDistance) return 0;

			if (firstClusterDistance === null) return 1;
			if (secondClusterDistance === null) return -1;

			// if ascending, lowest sorts first
			if (isAscending) {
				return firstClusterDistance < secondClusterDistance ? -1 : 1;
			}

			// if descending, highest sorts first
			return firstClusterDistance < secondClusterDistance ? 1 : -1;
		});

		return newData;
	}

	static isStrokeClustered(clusters: Cluster[], strokeId: number) {
		for (let i = 0; i < clusters.length; i++) {
			if (clusters[i].playerStrokes.length > 1) {
				for (let j = 0; j < clusters[i].playerStrokes.length; j++) {
					if (clusters[i].playerStrokes[j].strokeId === strokeId) return true;
				}
			}
		}

		return false;
	}
}

export default ClusteringService;
