import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { clearAllSelected, selectStrokes } from '../../../challenge-reducer';
import PlayerStroke from '../../models/player-stroke';

const useLeaderCard = (strokeData: PlayerStroke) => {
	const dispatch = useAppDispatch();

	const { selectedStrokeIds, selectedClusterIds } = useAppSelector(
		state => state.ctp
	);

	const selectStroke = () => {
		dispatch(clearAllSelected());
		dispatch(selectStrokes([strokeData.strokeId]));
	};

	const isSectionSelected = useMemo(() => {
		const isIncluded = selectedStrokeIds.includes(strokeData.strokeId);
		const notInSelectedCluster = !selectedClusterIds.some(x =>
			x.split(',').includes(strokeData.strokeId.toString())
		);

		return isIncluded && notInSelectedCluster;
	}, [selectedStrokeIds, selectedClusterIds, strokeData.strokeId]);

	return {
		isSectionSelected,
		selectStroke,
	};
};

export default useLeaderCard;
