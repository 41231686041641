import { useMemo } from 'react';

import { usePinPointChallengeDetailsQuery } from '../../../../../api/challenges/queries/useGetChallengeDetailsQuery';
import useModal from '../../../../shared/hooks/useModal';
import MapMarker from '../../../../shared/models/map-marker';
import useGetClusters from '../../../shared/selectors/useGetClusters';
import useGetFirstSelectedCluster from '../../../shared/selectors/useGetFirstSelectedCluster';
import { useGetPlayerStrokes } from '../../../shared/selectors/useGetPlayerStrokes';
import DistanceToPinService from '../../services/DistanceToPinService';

const useScatterPlot = (eventId: number, roundId: number, holeNum: number) => {
	const { challengeDetails, isLoading, error, refetch } =
		usePinPointChallengeDetailsQuery(eventId, roundId, holeNum);

	const { allPlayers } = useGetPlayerStrokes(challengeDetails);

	const clusteredPlayerStrokes = useGetClusters(
		allPlayers,
		challengeDetails?.hole.pinLocation
	);

	const marketingModal = useModal('marketingModal');

	const selectedCluster = useGetFirstSelectedCluster(clusteredPlayerStrokes);

	return {
		modal: {
			marketingModal,
		},
		data: {
			mapMarkers: useMemo(
				() =>
					clusteredPlayerStrokes.map(
						x =>
							new MapMarker(
								x.strokeIds,
								x.ballLocation,
								x.playerStrokes.length === 1
									? x.playerStrokes[0].fullName
									: 'Player Cluster',
								x.numberOfShots > 1 ? x.numberOfShots.toString() : undefined
							)
					),
				[clusteredPlayerStrokes]
			),
			pinLocation: challengeDetails?.hole.pinLocation || {
				latitude: -1,
				longitude: -1,
			},
			error,
			isLoading,
			refreshData: () => refetch(),
		},
		mapProps: {
			center: useMemo(
				() => ({
					latitude: challengeDetails?.hole.pinLocation.latitude || -1,
					longitude: challengeDetails?.hole.pinLocation.longitude || -1,
				}),
				[challengeDetails?.hole]
			),
			radius:
				challengeDetails &&
				(challengeDetails.participantStrokes?.length ?? 0) > 0
					? DistanceToPinService.calculateAvgDistanceToPin(
							challengeDetails?.hole.pinLocation,
							challengeDetails.participantStrokes
					  )
					: 0,
			teeLocations: challengeDetails?.hole.teeLocations,
			panTo: {
				latitude: selectedCluster?.ballLocation.latitude || 0,
				longitude: selectedCluster?.ballLocation.longitude || 0,
			},
		},
	};
};

export default useScatterPlot;
