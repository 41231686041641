import React from 'react';

import useGetEventChallengesQuery from '../../../../api/challenges/queries/useGetEventChallengesQuery';
import useGetMapParams from '../../../shared/hooks/useGetMapParams';
import WarningSection from '../../../shared/layout/WarningSection/WarningSection';
import EventBanner from '../EventBanner/EventBanner';
import EventInfoBanner from '../EventInfoBanner/EventInfoBanner';
import ChallengeDetailsCard from './ChallengeDetailsCard';
import './EventDetails.scss';

const EventDetails = () => {
	const { eventId } = useGetMapParams();

	const { eventChallenges, error } = useGetEventChallengesQuery(eventId);

	return (
		<section className='manage-events'>
			<div className='event-welcome__content'>
				<EventBanner className='event-info'>
					{error && <h3 className='error-header'>Sorry, event not found</h3>}
					{!error && eventChallenges && (
						<div className='event-info__text flex flex-col gap-2'>
							<h3>{eventChallenges.eventName}</h3>
							<h5>{eventChallenges.eventDescription}</h5>
							<EventInfoBanner />
						</div>
					)}
				</EventBanner>
				{error && (
					<WarningSection>
						<p>
							The page you are looking for may have been moved, or the link is
							incorrect or out of date.
						</p>
						<br />
						<p>
							Please try going back to the homepage or contacting the site admin
							for support at{' '}
							<a href='mailto:shotlander@captechconsulting.com'>
								<u>shotlander@captechconsulting.com</u>
							</a>
						</p>
					</WarningSection>
				)}
				{eventChallenges && (
					<>
						{eventChallenges.challengesSorted.map(challenge => (
							<ChallengeDetailsCard
								key={challenge.id}
								eventId={eventChallenges.eventId}
								challenge={challenge}
							/>
						))}
					</>
				)}
			</div>
		</section>
	);
};

export default EventDetails;
